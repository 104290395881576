import moment from 'moment';
import accessTokenService from './access-token.service';
import { Currency, CurrencyInfo } from '../@types/currency';
import { IInvoiceState, getInitialState } from '../state/state';
import { throwHttpError } from './errors';
import { NSProject } from '../@types/project';
import { Item } from '../@types/ListItem';
import { APIResponse } from '../@types/responses';
import { settings } from "../settings";
import { NSInvoice } from '../@types/invoice';

class InvoiceService {
  API: string;

  constructor() {
    this.API = settings().INVOICE_API;
  }

  private getOptions(method = 'GET', body?: any, formData?: FormData): RequestInit {
    const accessToken = accessTokenService.get();
    const headers = {
      ...(!formData && { 'Content-Type': 'application/json' }),
      Authorization: `Bearer ${accessToken}`
    };

    const options: RequestInit = { method, headers };

    if (body || formData)
      options.body = formData ?? JSON.stringify(body);

    return options;
  }

  private async fetchAPI(endpoint: string, options?: RequestInit) {
    return fetch(`${this.API}${endpoint}`, options || this.getOptions())
      .then(throwHttpError)
      .then((response) => response.json());
  }

  async getInvoice(invoiceId: string): Promise<NSInvoice.CreateAndUpdateInvoiceDto> {
    return this.fetchAPI(`/invoices/invoice/${invoiceId}`);
  }

  async getAllInvoices(params: NSInvoice.InvoiceFetchRequest): Promise<APIResponse.ListDataResponse<NSInvoice.CreateAndUpdateInvoiceDto>> {
    const options = this.getOptions('GET');
    const transformedParams = { ...params, dates: (params.dates as any)?.join('&dates=') };

    const urlParams = new URLSearchParams(
      Object.entries(transformedParams).filter(([k, v]) => v !== undefined)
    );

    const response = await this.fetchAPI(`/invoices/list?${urlParams}`, options);
    return response || { dataList: [], total: 0 };
  }

  async deleteInvoices(invoiceIds: string[]): Promise<void> {
    const options = this.getOptions('DELETE', { invoiceIds });
    await this.fetchAPI('/invoices/invoice', options);
  }

  async deleteInvoicesByProjectIds(projectIds: string[]): Promise<void> {
    const options = this.getOptions('DELETE', { projectIds });
    await this.fetchAPI('/invoices/project', options);
  }

  async getProjectInvoicesCount(projectIds: string[]): Promise<NSProject.InvoicesCountResponse[]> {
    const options = this.getOptions('GET');
    if (!projectIds.length) {
      return [];
    }

    return this.fetchAPI(`/invoices/count?projectIds=${projectIds}`, options)
      .catch(() => ([]));
  }

  getLocalData(): (IInvoiceState | null) {
    const localInvoiceData = localStorage.getItem('invoice');
    if (!localInvoiceData) return null;

    try {
      const invoiceStateData: IInvoiceState = JSON.parse(localInvoiceData);

      return {
        ...getInitialState(),
        ...invoiceStateData,
        InvoiceHeader: {
          ...invoiceStateData.InvoiceHeader,
          date: moment(invoiceStateData.InvoiceHeader.date),
          dueDate: moment(invoiceStateData.InvoiceHeader.dueDate),
        },
        skipAutoSave: true,
      };
    } catch (e) {
      console.error('Error parsing local invoice data:', e);
      return null;
    }
  }

  saveLocalData(invoiceData: IInvoiceState) {
    try {
      localStorage.setItem('invoice', JSON.stringify(invoiceData));
    } catch (e) {
      console.error('Error storing local invoice data:', e);
    }
  }

  resetLocalImage() {
    try {
      const localData = this.getLocalData() as IInvoiceState;
      this.saveLocalData({
        ...localData,
        InvoiceHeader: { ...localData?.InvoiceHeader, logoURL: null, logoId: null, logoMimetype: null }
      });
    } catch (e) {
      console.error('Error resetting local image:', e);
    }
  }

  /**
   * Creates a new invoice under a project.
   * 
   * * If no project ID is specified then the default projects ID will be used (on the backend).
   * * If no invoice data is given, then the backend will initialize the invoice using current project defaults.
   *   * If the data *is* given, then the default settings of the current project may be overridden by the backend
   *   * The backend stores the project defaults and prioritizes the body of the request over them.
   * 
   * @param object Project ID and invoice data
   * @returns The API response from creating an invoice under a project
   */
  async createNewInvoice({ invoiceData, projectId }: { invoiceData?: IInvoiceState, projectId?: string; }): Promise<string | null> {
    const options = this.getOptions('POST', invoiceData ? this.convertStateToInvoiceDto(invoiceData) : null);

    return this.fetchAPI(`/invoices/project/${projectId ?? ''}`, options)
      .then((data) => data._id);
  }

  async saveInvoiceData(invoiceId: string | undefined, invoiceData: IInvoiceState): Promise<NSInvoice.CreateAndUpdateInvoiceDto | null> {
    const options = this.getOptions('PUT', this.convertStateToInvoiceDto(invoiceData));

    return this.fetchAPI(`/invoices/invoice/${invoiceId}`, options);
  }

  async mailShare(pdfFormData: FormData, recipients: string[]) {
    const options = this.getOptions('POST', undefined, pdfFormData);

    return this.fetchAPI(`/invoices/mail?recipients=${encodeURIComponent(recipients.toString())}`, options);
  }

  async linkShare(pdfFormData: FormData) {
    const options = this.getOptions('POST', undefined, pdfFormData);

    return this.fetchAPI('/invoices', options);
  }

  convertStateToInvoiceDto(state: IInvoiceState): NSInvoice.CreateAndUpdateInvoiceDto {
    const { InvoiceHeader, InvoiceBilling, InvoiceItemList, InvoiceTotals, InvoiceRightRail, InvoiceFooter } = state;

    const identityData = (email: string, address: string): NSInvoice.IdentityData => ({
      email,
      address,
      name: '', // TODO: No data for this yet in the frontend
      phone: '' // TODO: No data for this yet in the frontend
    });

    const invoiceDto: NSInvoice.CreateAndUpdateInvoiceDto = {
      date: InvoiceHeader.date ? InvoiceHeader.date.toDate() : undefined,
      dueDate: InvoiceHeader.dueDate ? InvoiceHeader.dueDate.toDate() : undefined,
      currency: { code: CurrencyInfo[InvoiceRightRail.currency].code, symbol: CurrencyInfo[InvoiceRightRail.currency].symbol },
      customerData: identityData(InvoiceBilling.email2, InvoiceBilling.billTo),
      vendorData: identityData(InvoiceBilling.email1, InvoiceBilling.billFrom),
      status: NSInvoice.InvoiceStatus.DEFAULT,
      project: InvoiceRightRail.project ?? null,
      rows: InvoiceItemList.items.map((item) => ({
        itemData: item?.name ?? '',
        rate: item?.price ?? 0,
        qty: item?.quantity ?? 1,
        details: item?.description ?? ''
      })),
      comments: InvoiceFooter.notes ? [{ text: InvoiceFooter.notes }] : [],
      discounts: [
        {
          label: InvoiceTotals.discountLabel,
          type: InvoiceTotals.discountMode,
          value: +(InvoiceTotals.discount ?? 0)
        }],
      taxes: [
        {
          label: InvoiceTotals.taxLabel,
          type: InvoiceTotals.taxMode,
          value: +(InvoiceTotals.tax ?? 0)
        }],
      labels: { subTotalLabel: InvoiceTotals.subTotalLabel, totalsLabel: InvoiceTotals.totalLabel },
      logoId: InvoiceHeader.logoId ?? null,
      logoURL: InvoiceHeader.logoURL ?? null,
      invoiceNumber: InvoiceHeader.invoiceNumber
    };

    return invoiceDto;
  }

  convertInvoiceDtoToState(invoiceDTO: NSInvoice.CreateAndUpdateInvoiceDto): IInvoiceState {
    const { invoiceNumber, logoURL, logoId, date, dueDate, vendorData, customerData, currency, rows, discounts, taxes, labels, comments, project } = invoiceDTO;
    const translatedInitialState: Readonly<IInvoiceState> = getInitialState();

    const items = rows?.map((row) => new Item(row.itemData, row.details, row.rate, row.qty));
    const [firstDiscount] = discounts || [];
    const [firstTax] = taxes || [];
    const [firstComment] = comments || [];

    return {
      ...translatedInitialState,
      InvoiceHeader: {
        ...translatedInitialState.InvoiceHeader,
        logoURL: logoURL || translatedInitialState.InvoiceHeader.logoURL,
        logoId: logoId || translatedInitialState.InvoiceHeader.logoId,
        date: date ? moment(date) : translatedInitialState.InvoiceHeader.date,
        dueDate: dueDate ? moment(dueDate) : translatedInitialState.InvoiceHeader.dueDate,
        invoiceNumber: invoiceNumber || translatedInitialState.InvoiceHeader.invoiceNumber
      },
      InvoiceBilling: {
        ...translatedInitialState.InvoiceBilling,
        email1: vendorData.email ?? '',
        billFrom: vendorData.address ?? '',
        email2: customerData.email ?? '',
        billTo: customerData.address ?? '',
      },
      InvoiceRightRail: {
        ...translatedInitialState.InvoiceRightRail,
        currency: currency?.symbol ? Currency[currency.code as keyof typeof Currency] : translatedInitialState.InvoiceRightRail.currency,
        project: project ?? undefined
      },
      InvoiceItemList: {
        ...translatedInitialState.InvoiceItemList,
        items: items?.length ? items : translatedInitialState.InvoiceItemList.items,
      },
      InvoiceTotals: {
        ...translatedInitialState.InvoiceTotals,
        enableDiscount: firstDiscount?.value !== 0,
        discount: firstDiscount?.value || translatedInitialState.InvoiceTotals.discount,
        discountLabel: firstDiscount?.label || translatedInitialState.InvoiceTotals.discountLabel,
        discountMode: firstDiscount?.type || translatedInitialState.InvoiceTotals.discountMode,
        enableTax: firstTax?.value !== 0,
        tax: firstTax?.value || translatedInitialState.InvoiceTotals.tax,
        taxLabel: firstTax?.label || translatedInitialState.InvoiceTotals.taxLabel,
        taxMode: firstTax?.type || translatedInitialState.InvoiceTotals.taxMode,
        subTotalLabel: labels?.subTotalLabel || translatedInitialState.InvoiceTotals.subTotalLabel,
        totalLabel: labels?.totalsLabel || translatedInitialState.InvoiceTotals.totalLabel
      },
      InvoiceFooter: {
        ...translatedInitialState.InvoiceFooter,
        notes: firstComment?.text || translatedInitialState.InvoiceFooter.notes,
      }
    };
  }

  getAppliedInvoiceDefaults(invoice: IInvoiceState, project: NSProject.Dto) {
    const projectDefaults = project.defaults;
    const tax = projectDefaults.taxes[0];
    const discount = projectDefaults.discounts[0];

    return {
      ...invoice,
      InvoiceBilling: {
        ...invoice.InvoiceBilling,
        email1: invoice.InvoiceBilling.email1 || projectDefaults.form.email || '',
        billFrom: invoice.InvoiceBilling.billFrom || projectDefaults.form.address
      },
      InvoiceTotals: {
        ...invoice.InvoiceTotals,
        tax: invoice.InvoiceTotals.tax || tax.value,
        taxLabel: invoice.InvoiceTotals.taxLabel || tax.label,
        enableTax: invoice.InvoiceTotals.enableTax || !!(invoice.InvoiceTotals.tax || tax.value),

        discount: invoice.InvoiceTotals.discount || discount.value,
        discountLabel: invoice.InvoiceTotals.discountLabel || discount.label,
        enableDiscount: invoice.InvoiceTotals.enableDiscount || !!(invoice.InvoiceTotals.discount || discount.value)
      }
    };
  }
}

export default new InvoiceService();
