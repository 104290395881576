class MissingResponseProperties extends Error {
  readonly name: string;

  /**
   * Thrown when expected properties are missing from an API response.
   * @param name Name of the function or endpoint that returned the response
   * @param message Custom message.
   */
  constructor(name: string, message?: string) {
    super(message || `${name} properties must be provided from parent component`);
    this.name = name;
  }
}

export default MissingResponseProperties;