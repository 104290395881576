import './invoice-cards-wrapper.less';
import { NSInvoiceList } from '../../../@types/invoice';
import { useTranslation } from 'react-i18next';

import InvoiceLoadingCards from '../invoice-loading-cards/invoice-loading-cards.component';
import InvoiceCard from '../invoice-card/invoice-card.component';
import { Button } from 'antd';
import { Plus } from 'phosphor-react';
import { Link } from 'react-router-dom';

interface IProps {
  states: NSInvoiceList.States;
  functions: NSInvoiceList.Functions;
}

const InvoiceCardWrapper = ({ states, functions }: IProps) => {
  const { t } = useTranslation();
  const { data } = states;
  const { data: actualData, loading } = data.value;

  return (
    <div className="cards-wrapper">
      {(loading && actualData.length === 0) ? <InvoiceLoadingCards /> : (actualData.length
        ? actualData.map((invoice) => (
          <Link onClick={(e) => { e.preventDefault(); functions.handleInvoiceClick(invoice._id!); }} to={`/${invoice._id}`} key={invoice._id!}>
            <InvoiceCard key={invoice._id} invoice={invoice} functions={functions} />
          </Link>
        ))
        : <div className="no-items">
          <p>{t('invoice_list.no_invoices.message')}</p>
          <Button
            loading={states.loading.value}
            onClick={functions.createNewInvoiceHandler}
            icon={<Plus className="phosphor-icon" size={18} />}
          >
            {t('invoice_list.no_invoices.button')}
          </Button>
        </div>)}
    </div>
  );
};

export default InvoiceCardWrapper;
