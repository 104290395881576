import { Outlet, Navigate } from 'react-router-dom';
import { useAuthContext } from '../../store/auth/auth.context';

export const LandingRedirectRoute = () => {
  const authContext = useAuthContext();

  return (authContext.isAuthenticated)
    ? <Navigate to="/projects" />
    : <Outlet />;
};
