import { useState } from "react";
import { message } from "antd";
import { InvoiceRightRail } from "../../state/actions";
import { RightRailFormProps } from "../../Components/invoice/right-rail-form.component";
import useControlledForm from "../controlled-form.hook";
import { useAuthContext } from "../../store/auth/auth.context";
import { useTranslation } from "react-i18next";
import { useValidationContext } from "../../store/validation/validation.context";
import { useUserContext } from "../../store/user/user.context";


const useRightRailForm = (props: RightRailFormProps) => {
  const { form } = useControlledForm<InvoiceRightRail>(props.state.value.InvoiceRightRail);
  const { t } = useTranslation();
  const authCtx = useAuthContext();
  const userCtx = useUserContext();
  const validationCtx = useValidationContext();
  const [previewLink, setPreviewLink] = useState<string | null>(null);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [switchProjectModal, setSwitchProjectModal] = useState<string>();
  const [clearModalTrigger, setClearModalTrigger] = useState<string>();

  const saveInvoice = async () => {
    props.states.isLoading.set(true);
    props.invoiceHandlers.saveInvoice();
  };

  const showSendInvoice = async () => {
    const isFieldsValid = await validationCtx.executeAllValidations();

    if (!isFieldsValid) {
      message.error(t('messages.empty_fields'));
      return;
    }

    setShareModalOpen(true);
  };

  const clearInvoice = () => {
    setClearModalTrigger(authCtx.isAuthenticated ? props.state.value.InvoiceRightRail.project : '');
  };

  return {
    states: {
      shareModalOpen: { value: shareModalOpen, set: setShareModalOpen },
      switchProjectModal: { value: switchProjectModal, set: setSwitchProjectModal },
      previewLink: { value: previewLink, set: setPreviewLink },
      clearModalTrigger: { value: clearModalTrigger, set: setClearModalTrigger }
    },
    invoiceHandlers: {
      saveInvoice,
      showSendInvoice,
      clearInvoice
    },
    contexts: {
      authCtx,
      userCtx
    },
    form,
    t
  };
};

export default useRightRailForm;