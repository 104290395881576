import { createContext, useContext, useState } from "react";

type ValidationFunction<T> = (nameList?: (string | number)[]) => Promise<T>;

interface IValidationContext {
  executeAllValidations: () => Promise<boolean>;
  registerValidationFunction: (validateFn: ValidationFunction<any>) => void;
}

const ValidationContext = createContext<IValidationContext>(null as any);

export const useValidationContext = () => {
  const validationContext = useContext(ValidationContext);
  
  return validationContext;
};

export const ValidationContextProvider: React.FC = ({ children }) => {
  const [validationFunctions, setValidationFunctions] = useState<ValidationFunction<any>[]>([]);

  const registerValidationFunction = (validateFn: ValidationFunction<any>) => {
    setValidationFunctions(prev => [...prev, validateFn]);
  };

  const executeAllValidations = async (): Promise<boolean> => {
    const validationResults = await Promise.all(
      validationFunctions.map(fn => fn().then(result => Boolean(result)).catch(() => false))
    );

    return validationResults.every(result => result);
  };

  return (
    <ValidationContext.Provider value={{ executeAllValidations, registerValidationFunction }}>
      {children}
    </ValidationContext.Provider>
  );
};

export default ValidationContext;
