import { useState, useEffect } from 'react';
import { NSInvoice } from '../../@types/invoice';
import invoiceService from '../../Services/invoice.service';
import localStorageService from '../../Services/local-storage.service';
import { IBreadcrumbItemProps } from '../../Components/shared/breadcrumb/breadcrumb-item/breadcrumb-item.component';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams as useRouterSearchParams } from 'react-router-dom';
import useDebounce from '../debounce.hook';
import usePagination from '../pagination.hook';
import useSearchParams from '../search-params.hook';
import useDocumentTitle from '../document-title.hook';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal, message } from 'antd';
import { useUserContext } from '../../store/user/user.context';

const INVOICE_CARDS_PAGE_SIZE = 8;
const INVOICE_TABLE_PAGE_SIZE = 6;
const DEFAULT_VIEW_PREFERENCE = NSInvoice.ViewPreference.TABLE;

const useInvoiceList = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { projectId } = useParams();
  const userCtx = useUserContext();
  useDocumentTitle(t('invoice_list.page_title'));

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [pageView, setPageView] = useState<NSInvoice.ViewPreference>(
    localStorageService.getItem<NSInvoice.ViewPreference>('invoices_view_preference') ?? DEFAULT_VIEW_PREFERENCE
  );
  const [loading, setLoading] = useState(false);

  const searchParams = useRouterSearchParams();
  const useSearchParamsHelper = <T,>(param: string, isArray?: boolean) => useSearchParams<T>(param, searchParams, isArray);
  const [search, setSearch] = useSearchParamsHelper<string>('search');
  const [status, setStatus] = useSearchParamsHelper<NSInvoice.InvoiceStatus>('status');
  const [dates, setDates] = useSearchParamsHelper<string[]>('dates', true);
  const [sortedBy, setSortedBy] = useSearchParamsHelper<NSInvoice.InvoiceSortedBy>('sortedBy');
  const [direction, setDirection] = useSearchParamsHelper<NSInvoice.InvoiceSortingDirection>('direction');

  const {
    currentPage, data, totalItems, functions, constants
  } = usePagination<NSInvoice.CreateAndUpdateInvoiceDto>(pageView === NSInvoice.ViewPreference.TABLE
    ? INVOICE_TABLE_PAGE_SIZE
    : INVOICE_CARDS_PAGE_SIZE, fetchInvoiceData);
  const debouncedParams = useDebounce(searchParams[0], 400);
  const isTableView = pageView === NSInvoice.ViewPreference.TABLE;

  useEffect(() => {
    setSelectedIds([]);
  }, [searchParams[0]]);

  useEffect(() => {
    functions.fetchData();
  }, [currentPage.value, debouncedParams, pageView]);

  useEffect(() => {
    localStorageService.setItem('invoices_view_preference', pageView);
  }, [pageView]);

  function fetchInvoiceData(page: number, pageSize: number) {
    return invoiceService.getAllInvoices({
      page,
      pageSize,
      projectId,
      searchTerms: search,
      dates, status,
      sortedBy,
      direction: isTableView
        ? (direction ? (direction == 'ascend' ? 'asc' : 'desc') : undefined)
        : undefined
    });
  }

  const showDeleteConfirm = (invoiceIds: string[], invoiceNumber?: string) => {
    const manyStr = invoiceIds.length > 1 ? '_many' : '';

    Modal.confirm({
      style: { direction: i18n.dir() },
      title: t(`invoice_list.confirm_delete${manyStr}.title`),
      icon: <ExclamationCircleFilled />,
      content: <p>
        {`${t(`invoice_list.confirm_delete${manyStr}.content`)}${invoiceNumber ?? ''}`}
        {!invoiceNumber && (
          <ul>
            {invoiceIds.map((invoiceId) => <li key={invoiceId}>{invoiceId}</li>)}
          </ul>
        )}
      </p>,
      okText: t(`invoice_list.confirm_delete${manyStr}.okText`),
      okType: 'danger',
      cancelText: t(`invoice_list.confirm_delete${manyStr}.cancelText`),
      onOk: () => deleteInvoices(invoiceIds),
    });
  };

  const handleSelection = (invoiceId: string) => {
    const foundId = selectedIds.find((id) => id === invoiceId);

    if (foundId)
      setSelectedIds((previousSelectedIds) => previousSelectedIds.filter((id) => id !== invoiceId));
    else
      setSelectedIds((previousSelectedIds) => [...previousSelectedIds, invoiceId]);
  };

  const handleInvoiceClick = (invoiceId: string) => {
    if (selectedIds.length) {
      handleSelection(invoiceId);
    } else {
      navigate(`/${invoiceId}`, { state: { projectId } });
    }
  };

  const isChecked = (invoiceId: string) => (
    !!selectedIds.find((id) => id === invoiceId)
  );

  const deleteInvoices = async (invoiceIds: string[]) => {
    await invoiceService.deleteInvoices(invoiceIds);
    await functions.fetchData();
    setSelectedIds([]);
  };

  const createNewInvoiceHandler = async () => {
    try {
      setLoading(true);
      const invoiceId = await invoiceService.createNewInvoice({ projectId });

      navigate(`/${invoiceId}`, { state: { projectId } });
    } catch {
      message.error(t('messages.invoice_create_error'));
    } finally {
      setLoading(false);
    }
  };

  const getInvoiceListBreadcrumbItems = (): IBreadcrumbItemProps[] => {
    let project = (
      projectId
        ? userCtx.projects.value.find(project => project._id === projectId)
        : userCtx.projects?.defaultProjectRef
    );

    const breadcrumbItems: IBreadcrumbItemProps[] = [
      {
        title: 'Projects',
        route: '/projects'
      },
      {
        title: project?.name || 'Default',
      }
    ];

    return breadcrumbItems;
  };

  return {
    states: {
      selectedIds: { value: selectedIds, set: setSelectedIds, handle: handleSelection },
      pageView: { value: pageView, set: setPageView },
      loading: { value: loading, set: setLoading },
      currentPage,
      data,
      totalItems
    },
    params: {
      search: { value: search ?? '', set: setSearch },
      status: { value: status ?? '', set: setStatus },
      dates: { value: dates ?? [], set: setDates },
      sortedBy: { value: sortedBy ?? 'default', set: setSortedBy },
      direction: { value: direction, set: setDirection }
    },
    functions: {
      handleInvoiceClick,
      isChecked,
      deleteInvoices,
      showDeleteConfirm,
      createNewInvoiceHandler,
      getInvoiceListBreadcrumbItems
    },
    constants: {
      isTableView,
      pageSize: constants.pageSize,
    },
    t,
    navigate
  };
};

export default useInvoiceList;
