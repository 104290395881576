import loadingCardsStyles from './project-loading-cards.styles';

import { Card, Skeleton } from 'antd';

const ProjectLoadingCards = () => {
  const { cardStyle, skeletonStyle } = loadingCardsStyles;

  return <>
    {new Array(10).fill(null).map((_, i) => (
      <Card key={i} style={cardStyle}>
        <Skeleton
          style={skeletonStyle}
          title={false}
          loading
          active
          paragraph={{ rows: 1 }}
        />
      </Card>
    ))}
  </>;
};

export default ProjectLoadingCards;