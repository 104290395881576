import { Dropdown, MenuProps } from 'antd';
import { CheckOutlined, SettingOutlined, DeleteOutlined } from '@ant-design/icons';
import { DotsThree } from 'phosphor-react';
import { NSProject } from '../../../../@types/project';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

interface IProps {
  project: NSProject.ProjectDataWithCount;
}

const CardOptions = ({ project }: IProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSettings = () => navigate(`/projects/settings/${project._id}`);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    e.domEvent.stopPropagation();
    handleSettings();
  };

  const items: MenuProps['items'] = [
    {
      label: t('project_list.card_options.settings'),
      key: '1',
      icon: <SettingOutlined />,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick
  };

  return <Dropdown menu={menuProps} trigger={['click']}>
    <DotsThree className='options-icon' onClick={e => e.stopPropagation()} size={30} />
  </Dropdown>;
};

export default CardOptions;