export namespace ITranslation {
  export enum Language {
    EN = 'en',
    AR = 'ar'
  }

  export const LanguageInfo = {
    [Language.EN]: {
      title: 'English',
      shortcut: 'en'
    },
    [Language.AR]: {
      title: 'العربية',
      shortcut: 'ar'
    }
  } as const;
}