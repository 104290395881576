import React from "react";
import "./optional-input-wrapper.less";

import { Button, Col, Form, Row } from "antd";
import { XCircle } from "phosphor-react";
import EditableInput from "../inplace-controlled-field/editable-input.component";

interface Props {
  label: string;
  name: string;
  toggleButtonText?: string;
  isToggled?: boolean;
  children: React.ReactNode;
  onToggle: (wasSwitchedOn: boolean) => void;
  printValue?: string;
}

const OptionalInputWrapper = (props: Props) => {
  const { isToggled = false } = props;
  const ToggleButton = (
    <Col span={24}>
      <Button
        size="small"
        type="text"
        className="add-field-btn"
        onClick={() => props.onToggle(true)}
      >
        {props?.toggleButtonText ?? props.label}
      </Button>
    </Col>
  );

  return (
    <Row className="optional-input-row" align="middle" justify="space-between">
      <div className="optional-input">
        {isToggled ? (
          <>
            <Col span={12}>
              <Form.Item name={`${props.name}Label`} initialValue={props.label}>
                <EditableInput className="total-label" placeholder={`Add ${props.name}`} />
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item name={props.name} className="toggled-input">
                {props.children}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Button
                danger
                type="link"
                size="small"
                className="remove-field-btn"
                icon={<XCircle size={16} />}
                onClick={() => props.onToggle(false)}
              />
            </Col>
            {props.printValue && (
              <Col span={10} className="text-col">
                <p className="print-helper">{props.printValue}</p>
              </Col>
            )}
          </>
        ) : (
          ToggleButton
        )}
      </div>
    </Row>
  );
};

export default OptionalInputWrapper;
