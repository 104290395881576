import React from 'react';
import './invoice-header.less';
import { Actions, InvoiceHeader } from '../../state/actions';
import { IInvoiceState } from '../../state/state';
import { NSInvoice } from '../../@types/invoice';
import useInvoiceHeaderForm from '../../Hooks/invoice/invoice-header-form.hook';

import { DatePicker, Form } from 'antd';
import InvoiceImageUpload from '../shared/invoice-image-upload/invoice-image-upload.component';
import ResizableEditableInput from '../shared/inplace-controlled-field/resizable-editable-input.component';
import { getInvoiceEndpoints } from '../../util/get-logo-endpoint';

export interface InvoiceHeaderFormProps {
  state: { value: IInvoiceState, dispatch: React.Dispatch<Actions>; };
  states: NSInvoice.States;
};

const InvoiceHeaderForm = (props: InvoiceHeaderFormProps) => {
  const { form, t, formHandlers } = useInvoiceHeaderForm(props);
  const { handleInput, handleSelectLogo, handleBlur } = formHandlers;

  return (
    <Form
      form={form}
      disabled={props.states.isLoading.value}
      name="invoice-header"
      onValuesChange={(_: InvoiceHeader, values: InvoiceHeader) => handleInput(values)}
    >
      <div className="invoice__header">
        <div className="header__top">
          <Form.Item name={'date'} label={t('invoice.header.date')} className="date-container left-date">
            <DatePicker suffixIcon={false} className="input-date" allowClear={false} />
          </Form.Item>
          <div className="logo-wrapper">
            <InvoiceImageUpload
              state={props.state}
              className='invoice-logo'
              handleInput={handleInput}
              onSelect={handleSelectLogo}
              uploadEndpoints={getInvoiceEndpoints(props.state.value)}
            />
          </div>
          <Form.Item name={'dueDate'} label={t('invoice.header.due_date')} className="date-container right-date">
            <DatePicker suffixIcon={false} className="input-date" allowClear={false} />
          </Form.Item>
        </div>
        <div className="header__bottom">
          <h2>
            {t('invoice.header.invoice')}
          </h2>
          <Form.Item
            className='invoice-number'
            name="invoiceNumber"
            initialValue={props.state.value.InvoiceHeader.invoiceNumber}
          >
            <ResizableEditableInput
              formInstance={form}
              name="invoiceNumber"
              prefix={'#'}
              onBlur={handleBlur}
              maxLength={20}
              sanitize
            />
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

export default InvoiceHeaderForm;
