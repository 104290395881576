import './project-list.less';
import { PlusOutlined } from '@ant-design/icons';
import useProjectList from '../../Hooks/project-list/project-list.hook';

import { Pagination } from 'antd';
import ProjectCardsWrapper from '../../Components/projects-list/project-cards-wrapper/project-cards-wrapper.component';
import ProjectControls from '../../Components/projects-list/project-controls/project-controls.component';
import PageHeader from '../../Components/shared/page-header/page-header.component';

const ProjectListPage = () => {
  const { states, params, constants, headerHandlers, t } = useProjectList();

  const { currentPage, totalItems } = states;
  const { pageSize } = constants;
  const { createNewProjectHandler } = headerHandlers;

  return <div className="projects-list-wrapper">
    <PageHeader
      text={t('project_list.page_title')}
      actions={[{
        title: t('project_settings.actions.create_new_project'),
        icon: <PlusOutlined />,
        handler: createNewProjectHandler
      }]}
    />

    <div className="content">
      <ProjectControls params={params} />
      <ProjectCardsWrapper states={states} />

      <Pagination
        current={currentPage.value}
        onChange={currentPage.set}
        total={totalItems.value}
        pageSize={pageSize}
      />
    </div>
  </div>;
};

export default ProjectListPage;
