import { v4 as uuidv4 } from 'uuid';

export class Item {
  id: string;
  name?: string;
  description?: string;
  price?: number | null;
  quantity?: number | null;
  discount?: number | null;

  constructor(
    name?: string,
    description?: string,
    price?: number,
    quantity?: number,
    discount?: number
  ) {
    this.id = uuidv4();
    this.name = name ?? "";
    this.description = description ?? "";
    this.price = price ?? 0;
    this.quantity = quantity ?? 1;
    this.discount = discount ?? 0;
  }
}
export interface ItemFormValues {
  items: Array<Item | null>;
  useDiscounts: boolean;
}
