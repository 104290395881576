import { useEffect } from 'react';
import projectService from '../../Services/project.service';
import { NSProject } from '../../@types/project';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import useDebounce from '../debounce.hook';
import usePagination from '../pagination.hook';
import useSearchParams from '../search-params.hook';
import useDocumentTitle from '../document-title.hook';

const PROJECT_PAGE_SIZE = 24;

const useProjectList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [search, setSearch] = useSearchParams<string>('search');
  const {
    currentPage, data, totalItems, functions, constants
  } = usePagination<NSProject.ProjectDataWithCount>(PROJECT_PAGE_SIZE, fetchProjectData);
  const debouncedParams = useDebounce(search, 400);
  useDocumentTitle(t('project_list.page_title'));

  useEffect(() => {
    functions.fetchData();
  }, [currentPage.value, debouncedParams]);

  function fetchProjectData(page: number, pageSize: number) {
    return projectService.getAllUserProjectsWithCount({ page, pageSize, searchTerms: search });
  }

  const createNewProjectHandler = () => (
    navigate('/projects/settings')
  );

  return {
    states: {
      currentPage,
      data,
      totalItems
    },
    params: {
      search: { value: search ?? '', set: setSearch },
    },
    headerHandlers: {
      createNewProjectHandler
    },
    constants: {
      pageSize: constants.pageSize
    },
    t,
    navigate
  };
};

export default useProjectList;
