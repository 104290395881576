import { useState } from 'react';
import { NSInvoice } from '../../@types/invoice';
import moment from 'moment';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { InvoiceTableViewProps } from '../../Components/invoice-list/invoice-table-view/invoice-table-view.component';
import { ColumnType, ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { toAbsoluteLogoURL } from '../../util/get-logo-endpoint';

import { Trash, ImageSquare } from 'phosphor-react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

export declare type GetComponentProps<DataType> = (data: DataType, index?: number) => React.HTMLAttributes<any> | React.TdHTMLAttributes<any>;

interface DataType {
  invoiceNumber: string;
  key: string;
  id: string;
  logo?: string;
  email?: string;
  address?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

const InvoiceImage = ({ imageURL }: { imageURL: string; }) => {
  const [hasError, setHasError] = useState(false);

  const handleError = () => {
    setHasError(true);
  };

  return (
    <div className="img-container">
      {hasError || !imageURL
        ? <ImageSquare size={35} />
        : <img src={toAbsoluteLogoURL(imageURL)} onError={handleError} alt="Invoice" />}
    </div>
  );
};

type CellProps = {
  content: ReturnType<NonNullable<ColumnType<DataType>['render']>>;
  invoiceId: string;
};

const TableCell = (props: CellProps) => (
  <Link className='table-cell' to={`/${props.invoiceId}`} onClick={e => e.preventDefault()}>
    {props.content}
  </Link>
);

const useInvoiceTableView = ({ states, params, functions }: InvoiceTableViewProps) => {
  const { i18n, t } = useTranslation();
  const { projectId } = useParams();
  const navigate = useNavigate();

  const onSelectChange = (_: React.Key[], selectedRows: DataType[]) => {
    const selectedIds = selectedRows.map((selected) => selected.key);
    states.selectedIds.set(selectedIds);
  };

  const onRowHandler: GetComponentProps<DataType> = (invoice) => ({
    onClick: () => navigate(`/${invoice.key}`, { state: { projectId } })
  });

  const getSortingDirection = (sortedBy: NSInvoice.InvoiceSortedBy) => (
    params.sortedBy.value === sortedBy ? params.direction.value : undefined
  );

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<DataType> | SorterResult<DataType>[]
  ) => {
    const { field, order } = Array.isArray(sorter) ? sorter[0] : sorter;

    params.sortedBy.set(order ? field as NSInvoice.InvoiceSortedBy : undefined);
    params.direction.set(order || undefined);
  };

  const deleteInvoiceHandler = (invoice: DataType) => {
    functions.showDeleteConfirm([invoice.key], invoice.invoiceNumber);
  };

  const rowSelection = {
    onChange: onSelectChange
  };

  const columns: ColumnsType<DataType> = [
    {
      key: 'logo',
      dataIndex: 'logo',
      title: t('invoice_list.tabular_view.logo'),
      width: '8%',
      render: (data, { id }) => <TableCell content={<InvoiceImage imageURL={data} />} invoiceId={id} />
    },
    {
      key: 'invoiceNumber',
      dataIndex: 'invoiceNumber',
      title: t('invoice_list.tabular_view.invoice'),
      width: '17%',
      ellipsis: true,
      sorter: true,
      sortOrder: getSortingDirection('invoiceNumber'),
      render: (data, { id }) => <TableCell content={data} invoiceId={id} />
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: t('invoice_list.tabular_view.email'),
      width: '16%',
      ellipsis: true,
      render: (data) => data || '-'
    },
    {
      key: 'address',
      dataIndex: 'address',
      title: t('invoice_list.tabular_view.address'),
      width: '16%',
      ellipsis: true,
      render: (data) => data || '-'
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: t('invoice_list.tabular_view.creation_date'),
      width: '16%',
      ellipsis: true,
      sorter: true,
      sortOrder: getSortingDirection('createdAt'),
      render: (data) => (moment(data).locale(i18n.language).format('DD MMMM, YYYY'))
    },
    {
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      title: t('invoice_list.tabular_view.updating_date'),
      ellipsis: true,
      sorter: true,
      sortOrder: getSortingDirection('updatedAt'),
      render: (data) => (data ? moment(data).locale(i18n.language).format('DD MMMM, YYYY') : '-')
    },
    {
      key: 'action',
      dataIndex: 'action',
      title: t('invoice_list.tabular_view.action'),
      width: '80px',
      render: (_, invoice) => (
        <div className="actions">
          <Button
            type="text"
            title="Delete Invoice"
            icon={<Trash
              className="trash-icon"
              onClick={(e) => { e.stopPropagation(); deleteInvoiceHandler(invoice); }}
              size={20}
            />}
          />
        </div>
      ),
    },
  ];

  const tableData = states.data.value.data.map((invoice) => {
    return {
      key: invoice._id!,
      invoiceNumber: invoice.invoiceNumber,
      logo: invoice.logoURL ?? undefined,
      email: invoice.customerData.email,
      address: invoice.customerData.name,
      createdAt: invoice.createdAt,
      updatedAt: invoice.updatedAt,
      id: invoice._id!,
    };
  });

  return {
    tableHandlers: {
      onRowHandler,
      handleTableChange
    },
    tableData,
    columns,
    rowSelection,
    navigate
  };
};

export default useInvoiceTableView;
