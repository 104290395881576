import './projects-list-navigator.less';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router';
import { useUserContext } from '../../../store/user/user.context';
import { useTranslation } from 'react-i18next';
import { CaretDoubleDown } from 'phosphor-react';
import { useState } from 'react';

const ProjectsListNavigator = () => {
  const { t } = useTranslation();
  const userCtx = useUserContext();
  const userProjects = userCtx.projects.value;
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpenHandler = () => (setIsOpen((prev) => !prev));

  return (
    <div className={classNames('projects-navigator', { open: isOpen })}>
      <h2>Projects list</h2>
      <ul>
        {userProjects.length
          ? (
            userProjects.map(project => (
              <li
                key={project._id}
                className={classNames({ selected: projectId === project._id })}
                onClick={() => { navigate(`/projects/settings/${project._id}`); }}
              >
                <p>{project.name} <span>(Current)</span></p>
              </li>
            ))
          )
          : <p>{t('project_settings.no_projects')}</p>}
      </ul>
      <div className='footer-actions'>
        <button onClick={toggleOpenHandler}>
          <CaretDoubleDown size={16} />
        </button>
      </div>
    </div>
  );
};

export default ProjectsListNavigator;