import { useState, useEffect } from 'react';
import projectService from "../../Services/project.service";
import { NSProject } from "../../@types/project";
import { NSProjectSettings } from '../../@types/project-settings';
import { message, Modal } from 'antd';
import { Currency, CurrencyInfo } from '../../@types/currency';
import { useUserContext } from '../../store/user/user.context';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { useForm } from "antd/lib/form/Form";
import useDocumentTitle from "../document-title.hook";

import { ExclamationCircleFilled } from '@ant-design/icons';

const useProjectSettings = () => {
  const { t, i18n } = useTranslation();
  const [form] = useForm<NSProject.FormData>();
  const userCtx = useUserContext();
  const { projectId } = useParams<NSProjectSettings.Params>();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  useDocumentTitle(t('project_settings.page_title'));

  useEffect(() => {
    if (!projectId) {
      form.resetFields();
      return;
    }

    const fetchData = async () => {
      try {
        setLoading(true);
        const projectDto = await projectService.getProjectById(projectId);
        const { name: title, logo, defaults } = projectDto;
        const { form: defaultsForm, currency, discounts, taxes } = defaults;
        const discount = { ...discounts[0], mode: discounts[0].type };
        const tax = { ...taxes[0], mode: taxes[0].type };
        const currencyValue = Object.entries(CurrencyInfo).find(([k, v]) => v.code === currency?.code)?.[0];

        form.setFieldsValue({
          ...defaultsForm,
          currency: currencyValue ? parseInt(currencyValue) : undefined,
          title,
          logo,
          logoBase64: undefined,
          discount,
          tax,
        });
      } catch {
        message.error(t('messages.project_fetch_error'));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [projectId]);

  useEffect(() => {
    const projects = userCtx.projects.value;

    form.setFieldValue('title', projects.length ? null : 'default');
  }, [userCtx.projects.value]);

  const handleFinishFailed = () => (
    message.error(t('messages.empty_fields'))
  );

  const submitHandler = async ({ logo, logoBase64, title, address, email, discount, tax, currency }: NSProject.FormData) => {
    const currencyInfo = currency ? CurrencyInfo[currency] : undefined;

    try {
      setLoading(true);
      const payload = {
        name: title,
        logo: !logoBase64 ? logo : undefined,
        defaults: {
          currency: currencyInfo ?? CurrencyInfo[Currency.USD],
          discounts: [{ ...discount, type: discount.mode }],
          taxes: [{ ...tax, type: tax.mode }],
          form: {
            address: address ?? '',
            email: email || undefined
          },
        }
      };

      if (projectId) {
        await projectService.updateProject(projectId ?? '', payload);
        message.success(t('messages.project_updated'));
      } else {
        const projectId = (await projectService.createProject(payload))._id;

        if (logoBase64?.url) {
          projectService.uploadProjectLogo(logoBase64, projectId);
        }

        navigate(projectId);
        message.success(t('messages.project_created'));
      }

      await userCtx.projects.refresh();
    } catch {
      message.error(t('messages.project_save_error'));
    } finally {
      setLoading(false);
    }
  };

  const showDeleteConfirmHandler = () => {
    if (!projectId) return;

    Modal.confirm({
      style: { direction: i18n.dir() },
      title: t('project_list.confirm_delete.title'),
      icon: <ExclamationCircleFilled />,
      content: t('project_list.confirm_delete.content'),
      okText: t(`project_list.confirm_delete.okText`),
      okType: 'danger',
      cancelText: t('project_list.confirm_delete.cancelText'),
      onOk: () => deleteProject(projectId),
    });
  };

  const deleteProject = async (projectIds: string) => {
    try {
      await projectService.deleteProjects([projectIds]);
      await userCtx.projects.refresh();
      navigate('/projects/settings');
      message.success(t('messages.project_deleted'));
    } catch {
      message.error(t('messages.project_delete_error'));
    }
  };

  return {
    states: {
      isLoading: { value: isLoading, set: setLoading }
    },
    params: {
      projectId
    },
    formHandlers: {
      submitHandler,
      showDeleteConfirmHandler,
      handleFinishFailed
    },
    form,
    t
  };
};

export default useProjectSettings;