import "./dropdownMenu.less";
import { settings } from "../../../settings";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../store/auth/auth.context";
import { useUserContext } from "../../../store/user/user.context";

import { Dropdown, MenuProps, Space } from "antd";
import { MdLogout, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Link } from "react-router-dom";
import Avatar from "react-avatar";

export const DropdownMenu = () => {
  const { t } = useTranslation();
  const authContext = useAuthContext();
  const userContext = useUserContext();
  const profileDest = `${settings().SSO}/profile`;

  const menuItemStyle = {
    fontSize: "16px",
    margin: "5px auto",
    padding: "10px 15px",
    color: "#595b5d"
  };

  const userAvatar = (
    <Avatar
      name={`${userContext.userInfo.firstName} ${userContext.userInfo.lastName}`}
      round
      color="#ED6D9C"
      textSizeRatio={2.4}
      size="35"
    />
  );

  const items: MenuProps["items"] = [
    {
      label: (
        <Link reloadDocument={false} to={profileDest}>
          <div>
            <p className="user-full-name">
              {userContext.userInfo.firstName} {userContext.userInfo.lastName}
            </p>
            <p className="user-email">{userContext.userInfo.email}</p>
          </div>
        </Link>
      ),
      key: "0",
      style: menuItemStyle,
      icon: userAvatar
    },
    {
      type: "divider",
      style: { height: "1.2px" }
    },
    {
      label: t('header.actions.logout'),
      key: "1",
      style: menuItemStyle,
      icon: <MdLogout size={16} />,
      onClick: () => authContext?.logout()
    }
  ];

  return (
    <Dropdown
      overlayStyle={{ minWidth: "250px" }}
      menu={{ items }}
      trigger={["click"]}
      className="nav-bar-dropdown"
    >
      <Space>
        {userAvatar}
        <span className="user-display-name">
          {userContext.userInfo.firstName.at(0)}.&nbsp;{userContext.userInfo.lastName}
        </span>
        <MdOutlineKeyboardArrowDown color="#2d313d" />
      </Space>
    </Dropdown>
  );
};
