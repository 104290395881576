import { NSProject } from '../../../@types/project';
import './project-card.less';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import CardOptions from './card-options/card-options.component';
import CardIcon from './card-icon/card-icon.component';
import { Card } from 'antd';

interface IProps {
  project: NSProject.ProjectDataWithCount;
}

const ProjectCard = ({ project }: IProps) => {
  const { i18n, t } = useTranslation();

  return (
    <Card style={{ width: 280, marginTop: 16, borderRadius: 15 }}>
      <CardIcon invoicesCount={project.invoicesCount} />
      <div className="card-content">
        <h1>{project.name}</h1>
        <h2>{`${t('project_list.last_updated')} ${moment(project.updatedAt).locale(i18n.language).format('DD MMMM, YYYY')}`}</h2>
      </div>

      <CardOptions project={project} />
    </Card>
  );
};

export default ProjectCard;