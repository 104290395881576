import { useMemo } from "react";
import { IInvoiceState } from "../state/state";
import { calculateTotal } from "../util/calcTotals";

export type calcTotalsData = Pick<IInvoiceState, "InvoiceTotals" | "InvoiceItemList">;

export const useTotals = (state: calcTotalsData) => {
  const totals = useMemo(() => calculateTotal(state), [state]);
  return totals;
};
