import { TFunction } from "i18next";
import { Item, ItemFormValues } from "../@types/ListItem";
import { NSInvoice } from "../@types/invoice";
import {
  InvoiceBilling,
  InvoiceFooter,
  InvoiceHeader,
  InvoiceRightRail,
  InvoiceTotals
} from "./actions";
import moment from "moment";
import { Currency } from "../@types/currency";
import { getProjectLogoEndpoint } from "../util/get-logo-endpoint";

export interface IInvoiceState {
  InvoiceItemList: ItemFormValues;
  InvoiceHeader: InvoiceHeader;
  InvoiceFooter: InvoiceFooter;
  InvoiceBilling: InvoiceBilling;
  InvoiceRightRail: InvoiceRightRail;
  InvoiceTotals: InvoiceTotals;
  skipAutoSave?: boolean;
}

export const getInitialState = (t?: TFunction<"translation", undefined, "translation">, projectId?: string): IInvoiceState => {
  const invoice: IInvoiceState = {
    InvoiceHeader: {
      date: moment(),
      dueDate: moment(),
      invoiceNumber: '001',
      logoURL: projectId ? getProjectLogoEndpoint(projectId) : null,
    },

    InvoiceRightRail: {
      currency: Currency.USD,
    },

    InvoiceItemList: {
      items: [new Item()],
      useDiscounts: false
    },

    InvoiceTotals: {
      taxMode: NSInvoice.AmountMode.RATIO,
      tax: 0,
      taxLabel: (t ? t('invoice.totals.tax') : 'Tax'),
      enableTax: true,

      discountMode: NSInvoice.AmountMode.RATIO,
      discount: 0,
      discountLabel: (t ? t('invoice.totals.discount') : 'Discount:'),
      enableDiscount: true,

      subTotalLabel: t ? t('invoice.totals.subtotal') : 'Subtotal:',
      totalLabel: t ? t('invoice.totals.total') : 'Total:'
    },

    InvoiceFooter: {
      notes: ""
    },

    InvoiceBilling: {
      email1: "",
      billFrom: "",
      billTo: "",
      email2: ""
    },
  };

  return invoice;
};