import React from "react";
import "./switchable-number-input.less";

import EditableInputNumber from "./editable-input-number.component";
import { Button } from "antd";
import { Percent, PlusMinus } from "phosphor-react";
import { NSInvoice } from "../../../../@types/invoice";
import { useTranslation } from "react-i18next";

interface IProps
  extends Omit<
    React.ComponentProps<typeof EditableInputNumber>,
    "type" | "addonBefore"
  > {
  mode: NSInvoice.AmountMode;
  onToggle?: (mode: NSInvoice.AmountMode) => void;
}

const SwitchableNumberInput = (props: IProps) => {
  const { t } = useTranslation();
  const { mode, onToggle, ...rest } = props;

  const handleClick = () => {
    const newMode = mode === NSInvoice.AmountMode.RATIO ? NSInvoice.AmountMode.FIXED : NSInvoice.AmountMode.RATIO;
    onToggle?.(newMode);
  };

  const addonAfter = (
    <Button
      className="switchable-input-btn"
      type="text"
      title={mode === NSInvoice.AmountMode.FIXED ? t('invoice.totals.ratio_title') : t('invoice.totals.fixed_title')}
      icon={
        mode === NSInvoice.AmountMode.FIXED ? <Percent size={16} /> : <PlusMinus size={16} />
      }
      onClick={handleClick}
    />
  );

  return (
    <>
      <span className="switchable-input-wrapper">
        <EditableInputNumber
          addonAfter={addonAfter}
          percentage={mode === NSInvoice.AmountMode.RATIO}
          className={`switchable-number-input ${props?.className}`}
          {...rest}
        />
      </span>
    </>
  );
};

export default SwitchableNumberInput;
