import { ActionTypes } from "../../state/actions";
import { ItemFormValues } from "../../@types/ListItem";
import "./invoice.less";
import useInvoice from "../../Hooks/invoice/invoice.hook";
import useDocumentTitle from "../../Hooks/document-title.hook";

import ItemsList from "./items-list/items-list.component";
import InvoiceHeaderForm from "./invoice-header-form.component";
import BillingInfoForm from "./billing-info-form.component";
import InvoiceFooterForm from "./invoice-footer-form.component";
import RightRailForm from "./right-rail-form.component";
import Totals from "./totals/totals.component";
import BreadcrumbNavigation from "../shared/breadcrumb/breadcrumb.component";
import { Button, Spin } from "antd";
import { ArrowsClockwise } from "phosphor-react";

export const Invoice = () => {
  const { state, states, states: { isLoading }, contexts, invoiceHandlers, i18n, t } = useInvoice();
  useDocumentTitle(`${t('invoice.header.invoice')} #${state.value.InvoiceHeader.invoiceNumber}`);
  const { authContext } = contexts;
  const { getInvoiceBreadcrumbItems } = invoiceHandlers;

  return (
    <>
      <div className="wrapper">
        <Spin spinning={isLoading.value} size="large">
          <div className="invoice-utils">
            {authContext.isAuthenticated && (
              <BreadcrumbNavigation customItems={getInvoiceBreadcrumbItems()} />
            )}
          </div>
          <div className="invoice" dir={i18n.dir()}>
            <InvoiceHeaderForm state={state} states={states} />
            <BillingInfoForm state={state} states={states} />
            <div className="invoice__list-items">
              <ItemsList
                state={state}
                onChange={(_: ItemFormValues, values: ItemFormValues) =>
                  state.dispatch({
                    type: ActionTypes.UPDATE_ITEMS,
                    payload: values
                  })
                }
                states={states}
              />
              <Totals state={state} states={states} />
              <InvoiceFooterForm state={state} states={states} />
            </div>
          </div>
        </Spin>
        <Spin spinning={isLoading.value}>
          <RightRailForm state={state} states={states} invoiceHandlers={invoiceHandlers} />
        </Spin>
      </div>
    </>
  );
};
