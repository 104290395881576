import { Form, UploadFile, message } from "antd";
import { APIResponse } from "../../@types/responses";
import { ProjectImageUploadProps } from "../../Components/project-settings/project-image-upload/project-image-upload.component";
import { getProjectEndpoints } from "../../util/get-logo-endpoint";
import { useUpload } from "../upload.hook";
import { useAuthContext } from "../../store/auth/auth.context";
import { useTranslation } from "react-i18next";

import { CloudArrowUp, FileX, HourglassMedium } from "phosphor-react";

const useProjectImageUpload = ({ form, params }: ProjectImageUploadProps) => {
  const { t } = useTranslation();
  const { projectId } = params;
  const authCtx = useAuthContext();
  const logo = Form.useWatch('logo', form);
  const logoBase64 = Form.useWatch('logoBase64', form);
  const {
    imageElement,
    loading,
    error,
    changeHandler,
    deleteFile,
    beforeUploadHandler,
    logoURL,
    isImageLoaded
  } = useUpload(
    logo?.path,
    (res?: APIResponse.IPostUploadedFile) => form.setFieldsValue({ logo: res }),
    logoBase64,
    (res?: UploadFile) => form.setFieldsValue({ logoBase64: res }),
    !params.projectId
  );
  const endpoints = getProjectEndpoints(authCtx, projectId);
  const uploadIcon = (
    error !== null ? (
      <FileX size={42} className="logo" />
    ) : loading ? (
      <HourglassMedium size={42} className="logo" />
    ) : (
      <CloudArrowUp size={42} className="logo" />
    )
  );

  const deleteHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (projectId && endpoints.delete) {
      deleteFile(endpoints.delete, endpoints.authHeaders);
    } else {
      message.info(t('messages.deleting_success'));
    }
    form.setFieldValue('logo', undefined);

    e.stopPropagation();
  };

  return {
    logoStates: {
      error,
      loading
    },
    logoConstants: {
      imageElement,
      endpoints,
      isImageLoaded,
      uploadIcon,
      logoURL
    },
    logoHandlers: {
      changeHandler,
      beforeUploadHandler,
      deleteHandler
    },
    t
  };

};

export default useProjectImageUpload;