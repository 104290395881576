import { Dispatch, SetStateAction } from 'react';
import './confirm-project-switch-modal.less';

import { t } from 'i18next';
import { Modal, ModalProps } from 'antd';
import { Actions } from '../../../state/actions';
import { IInvoiceState } from '../../../state/state';
import useConfirmProjectSwitchModal from '../../../Hooks/invoice/confirm-project-switch-modal.hook';

import { Warning } from 'phosphor-react';

export type ConfirmProjectSwitchModalProps = ModalProps & {
  state: { value: IInvoiceState, dispatch: React.Dispatch<Actions>; };
  switchProjectModal: { value: string | undefined; set: Dispatch<SetStateAction<string | undefined>>; };
};

const ConfirmProjectSwitchModal = (props: ConfirmProjectSwitchModalProps) => {
  const { handleOk, handleClose, findAffectedFields } = useConfirmProjectSwitchModal(props);
  const { switchProjectModal, ...modalProps } = props;
  const affectedFields = findAffectedFields();

  return (
    <Modal
      className='modal confirm-project-switch-modal'
      title={
        <>
          <Warning size={40} />
          <h1>{t('invoice.confirm_project_switch_modal.title')}</h1>
        </>
      }
      okText={t('invoice.confirm_project_switch_modal.button_done')}
      okType='danger'
      cancelText={t('invoice.confirm_project_switch_modal.button_cancel')}
      onOk={handleOk}
      onCancel={handleClose}
      open={!!switchProjectModal.value}
      centered
      {...modalProps}
    >
      <p>{t('invoice.confirm_project_switch_modal.description')}</p>
      <div className='affected-fields'>
        {!!affectedFields.length
          ? (
            <p>
              {`${t('invoice.confirm_project_switch_modal.affected_fields_message')} `}
              <span>
                {affectedFields.join(`${t('invoice.confirm_project_switch_modal.affected_fields.separator')} `)}
              </span>
            </p>
          )
          : <p className='empty'>{t('invoice.confirm_project_switch_modal.no_affected_fields_message')}</p>}
      </div>
    </Modal>
  );
};

export default ConfirmProjectSwitchModal;