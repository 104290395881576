import React from "react";
import "../editable-input-number.less";

import { InputNumber } from "antd";

interface IProps
  extends Omit<React.ComponentProps<typeof InputNumber>, "type" | "bordered"> {
  fixedSign?: string;
  percentageSign?: string;
  noFixedSign?: boolean;
  noPercentSign?: boolean;
  percentage?: boolean;
}

const percentageProps: IProps = {
  min: 0,
};

const EditableInputNumber = (props: IProps) => {
  const {
    percentage = false,
    percentageSign = "%",
    fixedSign = "+",
    noFixedSign = false,
    noPercentSign = false,
    className = props.className ?? "",
    ...rest
  } = props;

  return (
    <div className="inplace-editable-wrapper">
      <InputNumber
        className={className}
        placeholder="0"
        prefix={
          percentage
            ? noPercentSign || percentageSign
            : noFixedSign || fixedSign
        }
        addonBefore={props.addonBefore}
        addonAfter={props.addonAfter ?? null}
        {...(percentage ? percentageProps : {})}
        {...rest}
        type="number"
      />
    </div>
  );
};

export default EditableInputNumber;
