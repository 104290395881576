import { useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useAuthContext } from '../../store/auth/auth.context';
import { useUserContext } from '../../store/user/user.context';
import useControlledForm from '../controlled-form.hook';
import { ActionTypes, InvoiceHeader, InvoiceHeaderPayload } from '../../state/actions';
import { APIResponse } from '../../@types/responses';
import { InvoiceHeaderFormProps } from '../../Components/invoice/invoice-header-form.component';


const useInvoiceHeaderForm = (props: InvoiceHeaderFormProps) => {
  const { t } = useTranslation();
  const authCtx = useAuthContext();
  const userCtx = useUserContext();
  const { form } = useControlledForm<InvoiceHeader>(props.state.value.InvoiceHeader);
  const defaultInvoiceNumber = useRef('000');

  useEffect(() => {
    const state = props.state.value;

    if (state.skipAutoSave) {
      defaultInvoiceNumber.current = state.InvoiceHeader.invoiceNumber;
    }
  }, [props.state.value]);

  const handleInput = (payload: InvoiceHeaderPayload) => {
    props.state.dispatch({
      type: ActionTypes.UPDATE_HEADER,
      payload
    });
  };

  const handleSelectLogo = (response?: APIResponse.IPostUploadedFile) =>
    handleInput(response ? {
      logoMimetype: response.mimetype,
      logoURL: response.path,
      logoId: response._id
    } : { logoId: null, logoURL: null, logoMimetype: null });

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const value = e.target.value;

    if (!value.length) {
      props.state.dispatch({ type: ActionTypes.UPDATE_HEADER, payload: { invoiceNumber: `${defaultInvoiceNumber.current}` || '' } });
    }
  };

  return {
    formHandlers: {
      handleInput,
      handleSelectLogo,
      handleBlur
    },
    appStores: {
      authCtx,
      userCtx
    },
    form,
    t
  };
};

export default useInvoiceHeaderForm;