import { ReactNode } from 'react';
import { NSProject } from '../../../@types/project';
import './project-cards-wrapper.less';
import { useTranslation } from "react-i18next";

import ProjectLoadingCards from "../project-loading-cards/project-loading-cards.component";
import ProjectCard from "../project-card/project-card.component";
import { Link } from 'react-router-dom';

interface IProps {
  states: NSProject.States;
}

const ProjectCardsWrapper = ({ states }: IProps) => {
  const { t } = useTranslation();
  const { data } = states;
  const { data: actualData, loading } = data.value;
  let children: ReactNode;
  if (loading) {
    children = <ProjectLoadingCards />;
  } else if (actualData.length) {
    children = actualData.map(project => (
      <Link to={`/projects/invoices/${project._id!}`} key={project._id}>
        <ProjectCard project={project} />
      </Link>
    ));
  } else {
    children = (
      <div className="no-items">
        <p>{t('project_list.no_projects.message')}</p>
      </div>
    );
  }

  return (
    <div className="cards-wrapper">
      {children}
    </div>
  );
};

export default ProjectCardsWrapper;
