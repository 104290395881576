import accessTokenService from './access-token.service';
import invoiceService from './invoice.service';
import { throwHttpError } from './errors';
import { NSProject } from '../@types/project';
import { APIResponse } from '../@types/responses';
import { settings } from "../settings";
import { base64ToBlob } from '../util/base64-to-blob';
import { UploadFile } from 'antd';

class ProjectService {
  API: string;

  constructor() {
    this.API = settings().INVOICE_API;
  }

  private getOptions(method = 'GET', body?: any): RequestInit {
    const accessToken = accessTokenService.get();
    const headers: Record<string, string> = {
      Authorization: `Bearer ${accessToken}`
    };

    const options: RequestInit = { method, headers };

    if (body) {
      options.body = JSON.stringify(body);
      headers['Content-Type'] = 'application/json';
    } else {
      headers['Content-Disposition'] = 'form-data';
    }

    return options;
  }

  private async fetchAPI(endpoint: string, options?: RequestInit) {
    return fetch(`${this.API}${endpoint}`, options || this.getOptions())
      .then(throwHttpError)
      .then((response) => response.json());
  }

  async getProjectById(projectId: string): Promise<NSProject.Dto> {
    return this.fetchAPI(`/projects/project/${projectId}`);
  }

  async getAllUserProjects(params: NSProject.ProjectFetchRequest): Promise<APIResponse.ListDataResponse<NSProject.Dto>> {
    const urlParams = new URLSearchParams(Object.entries(params).filter(([k, v]) => v !== undefined));

    return this.fetchAPI(`/projects?${urlParams}`)
      .catch(() => ({ dataList: [], total: 0 }));
  }

  async getAllUserProjectsWithCount(params: NSProject.ProjectFetchRequest): Promise<APIResponse.ListDataResponse<NSProject.ProjectDataWithCount>> {
    const projectsData = await this.getAllUserProjects(params);
    const projectIds = projectsData.dataList.map(({ _id }) => (_id ?? ''));
    const invoicesCount = await invoiceService.getProjectInvoicesCount(projectIds);
    const projectsCount = projectsData.dataList.map((project) => (
      {
        ...project,
        invoicesCount: invoicesCount.find(({ _id }) => _id == project._id)?.invoicesCount ?? 0,
      }
    ));

    return { dataList: projectsCount, total: projectsData.total };
  }

  async createProject(payload: NSProject.CreateAndUpdateDto): Promise<NSProject.Dto> {
    const options = this.getOptions('POST', payload);
    return this.fetchAPI(`/projects/project`, options);
  }

  async updateProject(projectId: string, payload: NSProject.CreateAndUpdateDto): Promise<NSProject.Dto> {
    const options = this.getOptions('PUT', payload);
    return this.fetchAPI(`/projects/project/${projectId}`, options);
  }

  async deleteProjects(projectIds: string[]): Promise<void> {
    await invoiceService.deleteInvoicesByProjectIds(projectIds);

    const options = this.getOptions('DELETE', { projectIds });
    await this.fetchAPI('/projects/project', options);
  }

  async uploadProjectLogo(imageBase64: UploadFile, projectId: string): Promise<APIResponse.IPostUploadedFile> {
    const blobImage = base64ToBlob(imageBase64.url!, imageBase64.type);
    const formData = new FormData();
    formData.append('logo', blobImage, imageBase64.name);
    const options = this.getOptions('POST');

    const response = await this.fetchAPI(`/projects/project/logo/${projectId ? encodeURIComponent(projectId) : ''}`, {
      ...options,
      body: formData
    });

    return response;
  }
}

export default new ProjectService();
