import { useEffect, useRef, MutableRefObject, Dispatch, SetStateAction } from 'react';
import _ from 'lodash';
import invoiceService from '../../Services/invoice.service';
import { IInvoiceState } from '../../state/state';
import { useAuthContext } from '../../store/auth/auth.context';

interface IProps {
  state: IInvoiceState;
  saveInvoice: () => Promise<void>;
  isStateChanging: MutableRefObject<boolean>;
  setSaving: Dispatch<SetStateAction<boolean>>;
}

export const useAutoSave = ({ state, isStateChanging, saveInvoice, setSaving }: IProps) => {
  const authContext = useAuthContext();
  const prevState = useRef(state);
  const saveInvoiceRef = useRef<null | (() => Promise<void>)>(null);

  const isRelevantChange = (prevState: IInvoiceState, currentState: IInvoiceState): boolean => (
    !_.isEqual(prevState, currentState)
  );

  const isValidInvoiceState = (currentState: IInvoiceState): boolean => (
    !!currentState.InvoiceHeader.invoiceNumber
  );

  const saveIfChanged = async () => {
    if (isStateChanging.current) {
      await saveInvoiceRef.current!();
      isStateChanging.current = false;
    }
  };

  useEffect(() => {
    if (state.skipAutoSave !== false || !isValidInvoiceState(state)) {
      return;
    }

    if (authContext.isGuest) {
      invoiceService.saveLocalData(state);
      return;
    }

    setSaving(true);
    saveInvoiceRef.current = saveInvoice;
    isStateChanging.current ||= isRelevantChange(prevState.current, state);
    prevState.current = state;
  }, [state, authContext]);

  useEffect(() => {
    const intervalId = setInterval(saveIfChanged, 3000);

    return () => {
      clearInterval(intervalId);
      saveIfChanged();
    };
  }, [authContext]);
};
