import { Dispatch, SetStateAction } from 'react';
import { IBreadcrumbItemProps } from '../Components/shared/breadcrumb/breadcrumb-item/breadcrumb-item.component';

export namespace NSInvoice {

  export enum InvoiceStatus {
    DEFAULT = 'DEFAULT'
  }

  export enum AmountMode {
    FIXED = 'FIXED',
    RATIO = 'RATIO'
  }

  export enum ViewPreference {
    TABLE = 'TABLE',
    CARDS = 'CARDS'
  }

  export interface Currency {
    code: string;
    symbol: string;
  }

  export const InvoiceSortedByArray = ['invoiceNumber', 'createdAt', 'updatedAt', 'default'] as const;

  export type InvoiceSortedBy = typeof InvoiceSortedByArray[number];

  export type InvoiceSortingDirection = 'ascend' | 'descend';

  export type InvoiceSortingDirectionMinimized = 'asc' | 'desc';

  export interface IdentityData {
    email: string;
    address: string;
    name: string;
    phone: string;
  }

  export interface Row {
    item?: string;
    itemData: string;
    rate: number;
    qty: number;
    details: string;
  }

  export interface Labels {
    subTotalLabel: string;
    totalsLabel: string;
  }

  interface FeeAdjustment {
    type: AmountMode;
    value: number;
    label: string;
  }

  interface Comment {
    text: string;
  }

  export interface CreateAndUpdateInvoiceDto {
    _id?: string;
    date?: Date;
    dueDate?: Date;
    logoId: string | null;
    project: string | null;
    logoURL: string | null;
    invoiceNumber: string;
    customer?: string; // No data for this yet in the frontend
    currency: Currency;
    customerData: IdentityData;
    vendorData: IdentityData;
    status: InvoiceStatus;
    rows: Row[];
    discounts: FeeAdjustment[];
    taxes: FeeAdjustment[];
    labels: Labels;
    comments: Comment[];
    createdAt?: Date;
    /**
     * This should be automatically updated in the backend side
    */
    updatedAt?: Date;
  }

  export interface InvoiceFetchRequest {
    projectId?: string;
    page: number;
    pageSize: number;
    searchTerms?: string;
    status?: InvoiceStatus;
    dates?: string[];
    sortedBy?: InvoiceSortedBy;
    direction?: InvoiceSortingDirectionMinimized;
  }

  export interface States {
    isLoading: {
      value: boolean;
      set: Dispatch<SetStateAction<boolean>>;
    };
    isSaving: {
      value: boolean;
      set: Dispatch<SetStateAction<boolean>>;
    };
  }

  export interface InvoiceHandlers {
    saveInvoice: () => Promise<void>;
    getInvoiceBreadcrumbItems: () => IBreadcrumbItemProps[];
  }
}

export namespace NSInvoiceList {
  export interface States {
    selectedIds: {
      value: string[];
      set: Dispatch<SetStateAction<string[]>>;
      handle: (invoiceId: string) => void;
    };
    pageView: {
      value: NSInvoice.ViewPreference;
      set: Dispatch<SetStateAction<NSInvoice.ViewPreference>>;
    };
    loading: {
      value: boolean;
      set: Dispatch<SetStateAction<boolean>>;
    };
    currentPage: {
      value: number;
      set: Dispatch<SetStateAction<number>>;
    };
    data: {
      value: {
        loading: boolean;
        data: NSInvoice.CreateAndUpdateInvoiceDto[];
      };
      set: Dispatch<SetStateAction<{
        loading: boolean;
        data: NSInvoice.CreateAndUpdateInvoiceDto[];
      }>>;
    };
    totalItems: {
      value: number;
      set: Dispatch<SetStateAction<number>>;
    };
  }

  export interface Params {
    search: {
      value: string;
      set: (value: string | undefined) => void;
    };
    status: {
      value: string;
      set: (value: NSInvoice.InvoiceStatus | undefined) => void;
    };
    dates: {
      value: string[];
      set: (value: string[] | undefined) => void;
    };
    sortedBy: {
      value: NSInvoice.InvoiceSortedBy;
      set: (value: NSInvoice.InvoiceSortedBy | undefined) => void;
    };
    direction: {
      value: NonNullable<NSInvoice.InvoiceSortingDirection> | undefined;
      set: (value: NSInvoice.InvoiceSortingDirection | undefined) => void;
    };
  }

  export interface Functions {
    handleInvoiceClick: (invoiceId: string) => void
    isChecked: (invoiceId: string) => boolean;
    deleteInvoices: (invoiceIds: string[]) => Promise<void>;
    showDeleteConfirm: (invoiceIds: string[], invoiceNumber?: string) => void;
    createNewInvoiceHandler: () => void;
  }
}