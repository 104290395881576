import { Actions, ActionTypes } from "./actions";
import { IInvoiceState } from "./state";

const reducer = (state: IInvoiceState, actions: Actions): IInvoiceState => {
  const { type, payload, skipAutoSave = false } = actions;

  const updateState = <T extends keyof Omit<IInvoiceState, 'skipAutoSave'>>(key: T, data: Partial<IInvoiceState[T]>) => ({
    ...state,
    [key]: { ...state[key], ...data },
    skipAutoSave
  });

  switch (type) {
    case ActionTypes.UPDATE_ITEMS:
      return updateState('InvoiceItemList', payload);

    case ActionTypes.UPDATE_FOOTER:
      return updateState('InvoiceFooter', payload);

    case ActionTypes.UPDATE_HEADER:
      return updateState('InvoiceHeader', payload);

    case ActionTypes.UPDATE_BILLING:
      return updateState('InvoiceBilling', payload);

    case ActionTypes.UPDATE_RIGHT_RAIL:
      return updateState('InvoiceRightRail', payload);

    case ActionTypes.UPDATE_TOTALS:
      return updateState('InvoiceTotals', payload);

    case ActionTypes.UPDATE_LANGUAGE:
      return updateState('InvoiceTotals', payload);

    case ActionTypes.UPDATE_STATE:
      return {
        ...state,
        InvoiceHeader: { ...state.InvoiceHeader, ...payload.InvoiceHeader },
        InvoiceItemList: { ...state.InvoiceItemList, ...payload.InvoiceItemList },
        InvoiceFooter: { ...state.InvoiceFooter, ...payload.InvoiceFooter },
        InvoiceBilling: { ...state.InvoiceBilling, ...payload.InvoiceBilling },
        InvoiceRightRail: { ...state.InvoiceRightRail, ...payload.InvoiceRightRail },
        InvoiceTotals: { ...state.InvoiceTotals, ...payload.InvoiceTotals },
        skipAutoSave
      };
  }
};

export default reducer;
