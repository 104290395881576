import { ActionTypes } from '../../state/actions';
import { useUserContext } from '../../store/user/user.context';

import { ConfirmProjectSwitchModalProps } from '../../Components/invoice/confirm-project-switch-modal/confirm-project-switch-modal.component';
import { useTranslation } from 'react-i18next';

const useConfirmProjectSwitchModal = (props: ConfirmProjectSwitchModalProps) => {
  const userCtx = useUserContext();
  const { t } = useTranslation();

  const { state, switchProjectModal } = props;

  const findAffectedFields = (): string[] => {
    const project = userCtx.projects.value.find((project) => project._id === switchProjectModal.value);
    if (!project) {
      return [];
    }

    const affectedFields: string[] = [];
    const projectDefaults = project?.defaults;

    if (!state.value.InvoiceBilling.email1 && projectDefaults.form.email) affectedFields.push(t('invoice.confirm_project_switch_modal.affected_fields.billing_email'));
    if (!state.value.InvoiceBilling.billFrom && projectDefaults.form.address) affectedFields.push(t('invoice.confirm_project_switch_modal.affected_fields.billing_address'));

    if (!state.value.InvoiceTotals.discount && projectDefaults.discounts[0]?.value) affectedFields.push(t('invoice.confirm_project_switch_modal.affected_fields.discount_value'));
    if (!state.value.InvoiceTotals.discountLabel && projectDefaults.discounts[0]?.label) affectedFields.push(t('invoice.confirm_project_switch_modal.affected_fields.discount_label'));

    if (!state.value.InvoiceTotals.tax && projectDefaults.taxes[0]?.value) affectedFields.push(t('invoice.confirm_project_switch_modal.affected_fields.tax_value'));
    if (!state.value.InvoiceTotals.taxLabel && projectDefaults.taxes[0]?.label) affectedFields.push(t('invoice.confirm_project_switch_modal.affected_fields.tax_label'));

    return affectedFields;
  };

  const handleOk = () => {
    const project = userCtx.projects.value.find((project) => project._id === switchProjectModal.value);

    state.dispatch({
      type: ActionTypes.UPDATE_RIGHT_RAIL,
      payload: {
        ...state.value.InvoiceRightRail,
        project: project?._id
      },
      skipAutoSave: false
    });

    handleClose();
  };

  const handleClose = () => (
    switchProjectModal.set(undefined)
  );

  return {
    t,
    handleOk,
    handleClose,
    findAffectedFields
  };
};

export default useConfirmProjectSwitchModal;