import React from 'react';
import { useTranslation } from 'react-i18next';
import { settings } from '../../../settings';

import { Button, ButtonProps, Space } from 'antd';
import Tooltip from '../tooltip/tooltip.component';

interface IProps {
  restrict: boolean;
  children: React.ReactElement<ButtonProps>;
}

const RestrictedActionTooltip = ({ restrict, children }: IProps) => {
  const { t } = useTranslation();

  if (!restrict) return children;

  return <Tooltip
    trigger="click"
    restrict={restrict}
    title={
      <Space
        className='restricted-action-container'
        direction="vertical"
        style={{ rowGap: 4 }}
      >
        <span>{t('popup.restricted_action.title')}</span>
        <Button
          className="hint_button"
          type="text"
          href={settings().LOGIN_URL}
        >
          {t('popup.restricted_action.ok')}
        </Button>
      </Space>
    }
  >
    {children}
  </Tooltip>;
};

export default RestrictedActionTooltip;