import code2000 from '../../../assets/fonts/CODE2000_updated_2.ttf';
import IBMPlexMedium from '../../../assets/fonts/IBMPlexSansArabic-Medium.ttf';
import IBMPlexRegular from '../../../assets/fonts/IBMPlexSansArabic-Regular.ttf';
import { StyleSheet, Font } from '@react-pdf/renderer';
import { i18n } from 'i18next';

const getStyles = (locale: i18n) => {
  return StyleSheet.create({
    pageContainer: {
      padding: 30,
      textAlign: locale.dir() === 'ltr' ? 'left' : 'right',
      fontFamily: 'IMB Plex Sans',
      fontWeight: 'normal'
    },
  } as const);
};

Font.register({
  family: 'IMB Plex Sans',
  fonts: [
    {
      src: IBMPlexRegular,
      fontWeight: 'normal'
    },
    {
      src: IBMPlexMedium,
      fontWeight: 'medium'
    }
  ]
});

Font.register({
  family: 'Code 2000',
  format: 'truetype',
  src: code2000
});

export default getStyles;