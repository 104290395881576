import React from 'react';
import './editable-input.less';

import { Input, InputRef } from 'antd';

interface IProps extends React.ComponentProps<typeof Input> {
  type?: 'number' | 'text';
}

const EditableInput = React.forwardRef<InputRef, IProps>((props, ref) => {

  return (
    <Input
      {...props}
      ref={ref}
      className={`inplace-editable ${props?.className ?? ''}`}
    />
  );
});

export default EditableInput;