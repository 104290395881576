import { ActionTypes } from '../../state/actions';
import { useUserContext } from '../../store/user/user.context';

import { useTranslation } from 'react-i18next';
import { ConfirmInvoiceClearModalProps } from '../../Components/invoice/confirm-invoice-clear/confirm-invoice-clear.component';
import invoiceService from '../../Services/invoice.service';
import { getInitialState } from '../../state/state';
import { useAuthContext } from '../../store/auth/auth.context';

const useConfirmInvoiceClearModal = (props: ConfirmInvoiceClearModalProps) => {
  const userCtx = useUserContext();
  const authCtx = useAuthContext();
  const { t } = useTranslation();

  const { state, resetModalTrigger } = props;


  const handleOk = () => {
    const initialState = getInitialState();
    initialState.InvoiceHeader.invoiceNumber = state.value.InvoiceHeader.invoiceNumber;

    if (authCtx.isAuthenticated) {
      const project = userCtx.projects.value.find((project) => project._id === resetModalTrigger.value);

      if (project) {
        const appliedInvoice = invoiceService.getAppliedInvoiceDefaults(initialState, project);

        state.dispatch({
          type: ActionTypes.UPDATE_STATE,
          payload: appliedInvoice,
          skipAutoSave: false
        });
      }
    } else {
      state.dispatch({
        type: ActionTypes.UPDATE_STATE,
        payload: initialState,
        skipAutoSave: false
      });
    }

    handleClose();
  };

  const handleClose = () => (
    resetModalTrigger.set(undefined)
  );

  return {
    t,
    handleOk,
    handleClose
  };
};

export default useConfirmInvoiceClearModal;