import getStyles from "./invoice-template-footer.styles";
import { InvoiceTemplateProps } from "../invoice-template.component";
import { useTranslation } from "react-i18next";

import { Text, View } from "@react-pdf/renderer";

const InvoiceTemplateFooter: React.FC<InvoiceTemplateProps> = ({ state }) => {
  const { t } = useTranslation();
  const styles = getStyles();

  const { notes } = state.InvoiceFooter;

  if (!notes) {
    return null;
  }

  return (
    <View style={styles.mainContainer}>
      <Text style={styles.notesTitleText}>
        {t('invoice.notes.title')}
      </Text>

      {notes && (
        <View style={styles.notesContainer}>
          {notes.split('\n').map(line => (
            <Text key={line}>{line}</Text>
          ))}
        </View>
      )}
    </View>
  );
};

export default InvoiceTemplateFooter;