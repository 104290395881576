import getInvoiceStyles from './invoice-template.styles';
import { IInvoiceState } from '../../../state/state';
import { useTranslation } from 'react-i18next';

import { Document, Page } from '@react-pdf/renderer';
import InvoiceTemplateHeader from './invoice-template-header/invoice-template-header.component';
import InvoiceTemplateBillingInfo from './invoice-template-billing-info/invoice-template-billing-info.component';
import InvoiceTemplateItemsList from './invoice-template-items-list/invoice-template-items-list.component';
import InvoiceTemplateTotals from './invoice-template-totals/invoice-template-totals.component';
import InvoiceTemplateFooter from './invoice-template-footer/invoice-template-footer.component';

export interface InvoiceTemplateProps {
  state: IInvoiceState;
}

const InvoiceTemplate: React.FC<InvoiceTemplateProps> = (props) => {
  const { i18n } = useTranslation();
  const styles = getInvoiceStyles(i18n);

  return (
    <Document>
      <Page style={styles.pageContainer}>
        <InvoiceTemplateHeader {...props} />

        <InvoiceTemplateBillingInfo {...props} />

        <InvoiceTemplateItemsList {...props} />

        <InvoiceTemplateTotals {...props} />

        <InvoiceTemplateFooter {...props} />
      </Page>
    </Document>
  );
};

export default InvoiceTemplate;