import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useJwt } from "react-jwt";
import { settings } from "../../settings";

export type AuthContextType = {
  accessToken: string;
  isAuthenticated: boolean;
  isGuest: boolean,
  logout(): void;
};

const AuthContext = createContext<AuthContextType>(null as any);

export const useAuthContext = () => {
  const authContext = useContext(AuthContext);

  return authContext;
};

export const AuthContextProvider: React.FC = ({ children }) => {
  const [cookies, , removeCookie] = useCookies();
  const initialToken = useRef<string>(cookies?.accessToken ?? "guest");

  const { isExpired } = useJwt(initialToken.current);
  const [accessToken, setAccessToken] = useState<string>(initialToken.current);

  useEffect(() => {
    if (isExpired) {
      initialToken.current = "";
      removeCookie("accessToken", {
        path: "/",
        domain: settings().REACT_APP_SADA_DOMAIN
      });
    } else {
      setAccessToken(initialToken.current);
    }
  }, [removeCookie, isExpired]);

  const userIsGuest = accessToken === 'guest';
  const userIsLoggedIn = !!accessToken && accessToken !== 'guest';

  const logout = () => {
    setAccessToken("guest");
    removeCookie("accessToken", {
      path: "/",
      domain: settings().REACT_APP_SADA_DOMAIN
    });
    window.location.href = settings().LOGIN_URL;
  };

  const contextValue = {
    accessToken,
    isAuthenticated: userIsLoggedIn,
    isGuest: userIsGuest,
    logout,
  };

  return (<AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>);
};

export default AuthContext;
