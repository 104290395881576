import React, { useEffect } from "react";
import {
  Actions,
  ActionTypes,
  InvoiceTotals,
  InvoiceTotalsPayload
} from "../../../state/actions";
import { IInvoiceState } from "../../../state/state";
import { NSInvoice } from "../../../@types/invoice";
import { CurrencyInfo } from "../../../@types/currency";
import "./totals.less";
import useControlledForm from "../../../Hooks/controlled-form.hook";
import { useTotals } from "../../../Hooks/totals.hook";
import { useTranslation } from "react-i18next";
import { useWatch } from "antd/lib/form/Form";

import { Col, Form, Row } from "antd";
import SwitchableNumberInput from "../../shared/inplace-controlled-field/number/switchable-number-input.component";
import EditableInput from "../../shared/inplace-controlled-field/editable-input.component";
import OptionalInputWrapper from "../../shared/optional-input/optional-input-wrapper.component";
import { formatCurrency } from "../../../util/format-currency";
import { useUserContext } from "../../../store/user/user.context";

interface Props {
  state: { value: IInvoiceState, dispatch: React.Dispatch<Actions>; };
  states: NSInvoice.States;
}

const Totals = (props: Props) => {
  const { i18n, t } = useTranslation();
  const { form } = useControlledForm<InvoiceTotals>(props.state.value.InvoiceTotals);
  const { total = 0, subTotal = 0 } = useTotals(props.state.value);
  const discountValue = useWatch('discount', form);
  const taxValue = useWatch('tax', form);
  const userContext = useUserContext();
  const projectDefaults = userContext.projects.defaultProjectRef?.defaults;
  const defaultTaxValue = projectDefaults?.taxes?.[0].value;
  const defaultDiscountValue = projectDefaults?.discounts?.[0].value;

  useEffect(() => {
    props.state.dispatch({
      type: ActionTypes.UPDATE_LANGUAGE,
      payload: {
        totalLabel: t('invoice.totals.total'),
        subTotalLabel: t('invoice.totals.subtotal'),
        discountLabel: t('invoice.totals.discount'),
        taxLabel: t('invoice.totals.tax')
      },
      skipAutoSave: true
    });
  }, [t]);

  const currency = props.state.value.InvoiceRightRail.currency;

  const {
    taxMode = NSInvoice.AmountMode.RATIO,
    discountMode = NSInvoice.AmountMode.RATIO,
    discount = 0,
    tax = 0,
    enableTax = false,
    enableDiscount = false
  } = props.state.value.InvoiceTotals;

  const onUpdate = (payload: InvoiceTotalsPayload) => {
    props.state.dispatch({
      type: ActionTypes.UPDATE_TOTALS,
      payload: {
        ...props.state.value.InvoiceTotals,
        ...payload
      }
    });
  };

  return (
    <Form
      form={form}
      disabled={props.states.isLoading.value}
      name="totals"
      initialValues={props.state.value}
      onValuesChange={(_: InvoiceTotalsPayload, values: InvoiceTotalsPayload) =>
        props.state.dispatch({
          type: ActionTypes.UPDATE_TOTALS,
          payload: values
        })
      }
    >
      <div className="totals">
        <div className="totals__top">
          <Row align="top" justify="space-between">
            <Col span={12}>
              <Form.Item name="subTotalLabel" initialValue={`${t('invoice.totals.subtotal')}:`}>
                <EditableInput className="total-label" />
              </Form.Item>
            </Col>
            <Col span={10} className="text-col">
              <div className="subtotal overflow">
                {formatCurrency(subTotal, currency, i18n)}
              </div>
            </Col>
          </Row>
          <OptionalInputWrapper
            onToggle={(enabled) =>
              onUpdate({ enableDiscount: enabled, discount: enabled ? defaultDiscountValue : 0 })
            }
            name="discount"
            label={t('invoice.totals.add_discount')}
            isToggled={enableDiscount}
            printValue={`${discountValue ? `${discountMode === NSInvoice.AmountMode.FIXED ? '$' : '%'}${discountValue}` : '-'}`}
          >
            <SwitchableNumberInput
              className="number-input"
              fixedSign={CurrencyInfo[currency].symbol}
              mode={discountMode}
              onToggle={(mode) =>
                onUpdate({
                  discountMode: mode,
                  discount: +(discount ?? 0)
                })
              }
              controls={false}
            />
          </OptionalInputWrapper>
          <OptionalInputWrapper
            onToggle={(enabled) => onUpdate({ enableTax: enabled, tax: enabled ? defaultTaxValue : 0 })}
            name="tax"
            label={t('invoice.totals.add_tax')}
            isToggled={enableTax}
            printValue={`${taxValue ? `${taxMode === NSInvoice.AmountMode.FIXED ? '$' : '%'}${taxValue}` : '-'}`}
          >
            <SwitchableNumberInput
              className="number-input"
              fixedSign={CurrencyInfo[currency].symbol}
              mode={taxMode}
              onToggle={(mode) =>
                onUpdate({
                  taxMode: mode,
                  tax: +(tax ?? 0)
                })
              }
              controls={false}
            />
          </OptionalInputWrapper>
        </div>
        <div className="totals__bottom">
          <Row align="top" justify="space-between">
            <Col span={12}>
              <Form.Item name="totalLabel" initialValue={`${t('invoice.totals.total')}:`}>
                <EditableInput />
              </Form.Item>
            </Col>
            <Col span={10} className="text-col">
              <div className="total overflow">
                {formatCurrency(total, currency, i18n)}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Form >
  );
};

export default Totals;
