import getStyles from "./invoice-template-totals.styles";
import { NSInvoice } from "../../../../@types/invoice";
import { InvoiceTemplateProps } from "../invoice-template.component";
import { formatCurrency } from "../../../../util/format-currency";
import { useTotals } from "../../../../Hooks/totals.hook";
import { useTranslation } from "react-i18next";

import { Text, View } from "@react-pdf/renderer";

const InvoiceTemplateTotals: React.FC<InvoiceTemplateProps> = ({ state }) => {
  const { t, i18n } = useTranslation();
  const { total = 0, subTotal = 0 } = useTotals(state);
  const styles = getStyles(i18n);

  const { subTotalLabel, totalLabel, tax, taxLabel, taxMode, discount = 0, discountLabel, discountMode } = state.InvoiceTotals;
  const currency = state.InvoiceRightRail.currency;

  return (
    <View style={styles.mainContainer}>
      <View style={styles.itemContainer}>
        <Text>{subTotalLabel || t('invoice.totals.subtotal')}</Text>
        <Text>
          <Text style={styles.currency}>
            {formatCurrency(subTotal, currency)}
          </Text>
        </Text>
      </View>

      <View style={styles.itemContainer}>
        <Text>{discountLabel || t('invoice.totals.discount')}</Text>
        <Text>
          {discount ? `${discountMode === NSInvoice.AmountMode.FIXED ? '$' : '%'}${formatCurrency(parseInt(discount.toString()))}` : '-'}
        </Text>
      </View>

      <View style={styles.itemContainer}>
        <Text>{taxLabel || t('invoice.totals.tax')}</Text>
        <Text>
          {tax ? `${taxMode === NSInvoice.AmountMode.FIXED ? '$' : '%'}${formatCurrency(parseInt(tax.toString()))}` : '-'}
        </Text>
      </View>

      <View style={[styles.itemContainer, styles.totalsTotalItem]}>
        <Text>{totalLabel || t('invoice.totals.total')}</Text>
        <Text>
          <Text style={styles.currency}>
            {formatCurrency(total, currency)}
          </Text>
        </Text>
      </View>
    </View>
  );
};

export default InvoiceTemplateTotals;