import loadingCardsStyles from './invoice-loading-cards.styles';

import { Card, Skeleton } from 'antd';

const InvoiceLoadingCards = () => {
  const { cardStyle } = loadingCardsStyles;

  return <>
    {new Array(8).fill(null).map((_, i) => (
      <Card key={i} style={cardStyle}>
        <Skeleton loading avatar active paragraph />
      </Card>
    ))}
  </>;
};

export default InvoiceLoadingCards;