/**
 * Calculates and returns the width of the given text using the given font.
 * @param text - The text to be measured.
 * @param font - The font to be applied to the text.
 * @returns The measured width of the text in pixels.
 * @throws Will throw an error if the canvas context doesn't exist.
 */
export const getTextWidth = (text: string, font: string): number => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (!context) {
    throw new Error('Canvas context not available.');
  }

  context.font = font;

  const metrics = context.measureText(text);

  return metrics.width;
};