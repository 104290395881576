import { Outlet, Navigate } from 'react-router-dom';
import { useAuthContext } from '../../store/auth/auth.context';

export const ProtectedRoute = () => {
  const authContext = useAuthContext();

  return (authContext.isGuest)
    ? <Navigate to="/" />
    : <Outlet />;
};
