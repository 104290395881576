import { StyleSheet } from '@react-pdf/renderer';
import { i18n } from 'i18next';

const getStyles = (locale: i18n) => {
  return StyleSheet.create({
    mainContainer: {
      width: '100%',
      fontSize: 10,
      marginTop: 10
    },
    itemContainer: {
      display: 'flex',
      flexDirection: locale.dir() === 'ltr' ? 'row' : 'row-reverse',
      justifyContent: 'space-between',
      width: '100%',
      paddingVertical: 3
    },
    totalsTotalItem: {
      fontWeight: 'medium',
      paddingBottom: 10,
      paddingTop: 10,
      marginTop: 6,
      borderBottom: '1 solid rgb(170, 170, 170)',
      borderTop: '1 solid rgb(170, 170, 170)'
    },
    currency: {
      fontFamily: 'Code 2000'
    }
  } as const);
};

export default getStyles;