import { Moment } from "moment";
import { ItemFormValues } from "../@types/ListItem";
import { IInvoiceState } from "./state";
import { NSInvoice } from "../@types/invoice";
import { Currency } from "../@types/currency";

export enum ActionTypes {
  UPDATE_ITEMS = "update-items",
  UPDATE_HEADER = "update-header",
  UPDATE_FOOTER = "update-footer",
  UPDATE_BILLING = "update-billing",
  UPDATE_RIGHT_RAIL = "update-right-rail",
  UPDATE_TOTALS = "update-totals",
  UPDATE_LANGUAGE = "update-language",
  UPDATE_STATE = "update-state"
}

export type InvoiceFooter = {
  notes: string;
};

export type InvoiceTotals = {
  taxMode: NSInvoice.AmountMode;
  tax: number | string | null;
  taxLabel: string;
  enableTax: boolean;

  discountMode: NSInvoice.AmountMode;
  discount: number | string | null;
  discountLabel: string;
  enableDiscount: boolean;

  subTotalLabel: string;
  totalLabel: string;
};

export type InvoiceHeader = {
  date: Moment;
  dueDate: Moment;
  logoId?: string | null;
  /**
   * Absolute (non-relative) path pointing to the logo, a relative path without the scheme or host may be stored on the backend.
   * FIXME: Mixed absolute/relative logo paths.
   */
  logoURL?: string | null;
  logoMimetype?: string | null;
  invoiceNumber: string;
};

export type InvoiceRightRail = {
  currency: Currency;
  project?: string;
};

export type InvoiceBilling = {
  email1: string;
  email2: string;
  billFrom: string;
  billTo: string;
};

export type InvoiceFooterPayload = Partial<InvoiceFooter>;

export type InvoiceTotalsPayload = Partial<InvoiceTotals>;

export type InvoiceHeaderPayload = Partial<InvoiceHeader>;

export type InvoiceRightRailPayload = Partial<InvoiceRightRail>;

export type InvoiceBillingPayload = Partial<InvoiceBilling>;

interface IUpdateItems {
  type: ActionTypes.UPDATE_ITEMS;
  payload: ItemFormValues;
}

interface IUpdateFooter {
  type: ActionTypes.UPDATE_FOOTER;
  payload: InvoiceFooterPayload;
}

interface IUpdateHeader {
  type: ActionTypes.UPDATE_HEADER;
  payload: InvoiceHeaderPayload;
}

interface IUpdateBilling {
  type: ActionTypes.UPDATE_BILLING;
  payload: InvoiceBillingPayload;
}

interface IUpdateRightRail {
  type: ActionTypes.UPDATE_RIGHT_RAIL;
  payload: InvoiceRightRailPayload;
}

interface IUpdateTotals {
  type: ActionTypes.UPDATE_TOTALS;
  payload: InvoiceTotalsPayload;
}

interface IUpdateLanguage {
  type: ActionTypes.UPDATE_LANGUAGE;
  payload: InvoiceTotalsPayload;
}

interface IUpdateState {
  type: ActionTypes.UPDATE_STATE;
  payload: IInvoiceState;
}

export type ISkipAutoSave = {
  skipAutoSave?: boolean;
};

export type Actions =
  (IUpdateItems
    | IUpdateFooter
    | IUpdateHeader
    | IUpdateBilling
    | IUpdateRightRail
    | IUpdateTotals
    | IUpdateLanguage
    | IUpdateState)
  & ISkipAutoSave;
