import './App.less';
import 'moment/locale/ar';
import { AuthContextProvider } from './store/auth/auth.context';
import { UserContextProvider } from './store/user/user.context';
import { ValidationContextProvider } from './store/validation/validation.context';
import arEG from 'antd/es/locale/ar_EG';
import { useTranslation } from 'react-i18next';

import HomePage from './Pages/home/home.page';
import InvoicesListPage from './Pages/invoice-list/invoice-list.page';
import ProjectListPage from './Pages/project-list/project-list.page';
import ProjectSettingsPage from './Pages/project-settings/project-settings.page';
import PageLayout from './Components/base/page-layout/page-layout.component';
import { LandingRedirectRoute } from './Components/custom-routes/landing-redirect-route.component';
import { ProtectedRoute } from './Components/custom-routes/protected-routes.component';
import { ConfigProvider } from 'antd';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

function App() {
  const { i18n } = useTranslation();

  return (
    <ConfigProvider locale={i18n.language === 'ar' ? arEG : undefined} direction={i18n.dir()}>
      <BrowserRouter basename="/">
        <AuthContextProvider>
          <UserContextProvider>
            <ValidationContextProvider>
              <div dir={i18n.dir()} className={`App ${i18n.language}`}>
                <PageLayout>
                  <Routes>
                    <Route element={<LandingRedirectRoute />}>
                      <Route path="/" element={<HomePage />} />
                    </Route>
                    <Route path="/:invoiceId" element={<HomePage />} />
                    <Route element={<ProtectedRoute />}>
                      <Route path="/projects" element={<ProjectListPage />} />
                      <Route path="/projects/invoices/:projectId" element={<InvoicesListPage />} />
                      <Route path="/projects/settings/:projectId?" element={<ProjectSettingsPage />} />
                    </Route>
                  </Routes>
                </PageLayout>
              </div>
            </ValidationContextProvider>
          </UserContextProvider>
        </AuthContextProvider>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;