import "./items-list.less";
import { Item, ItemFormValues } from "../../../@types/ListItem";
import { IInvoiceState } from "../../../state/state";
import { Actions } from "../../../state/actions";
import { NSInvoice } from "../../../@types/invoice";
import useControlledForm from "../../../Hooks/controlled-form.hook";
import { useTranslation } from "react-i18next";

import ItemsListRow from "./items-list-row.component";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Form } from "antd";

interface IProps {
  withDiscount?: boolean;
  state: { value: IInvoiceState, dispatch: React.Dispatch<Actions>; };
  onChange?: (changedValues: ItemFormValues, values: ItemFormValues) => void;
  states: NSInvoice.States;
};

type FormListProps = {
  add: (defaultValue?: Item, insertIndex?: number) => void;
  remove: (index: number | number[]) => void;
};

const ItemsList = (props: IProps) => {
  const { t } = useTranslation();
  const { form } = useControlledForm<ItemFormValues>(props.state.value.InvoiceItemList);

  return (
    <Form
      form={form}
      disabled={props.states.isLoading.value}
      onValuesChange={props.onChange}
      className="items-list-form"
      validateMessages={{ required: "${name} is required" }}
    >
      <Form.List name="items">
        {(fields, { add, remove }: FormListProps) => (
          <>
            <div className="table-container">
              {!!fields.length && (
                <div className="items-list-header">
                  <p>{t('invoice.invoice_items.item')}</p>
                  <p>{t('invoice.invoice_items.price')}</p>
                  <p>{t('invoice.invoice_items.quantity')}</p>
                  <p>{t('invoice.invoice_items.total')}</p>
                </div>
              )}

              <div className="items-list-body">
                {fields.map(({ key, name, ...restField }, i) => (
                  <ItemsListRow
                    form={form}
                    currency={props.state.value.InvoiceRightRail.currency}
                    withDiscount={props.withDiscount}
                    name={name}
                    add={add}
                    remove={remove}
                    restField={restField}
                    key={key}
                    index={i}
                  />
                ))}
              </div>
            </div>
            <Button
              size="large"
              className="add-btn"
              type="dashed"
              onClick={() => add(new Item())}
              block
              icon={<PlusOutlined />}
            >
              {t('invoice.invoice_items.add_item')}
            </Button>
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default ItemsList;
