import { useState } from "react";
import "./navbar.less";
import { isMongoId } from "../../../util/mongo-id";
import { settings } from "../../../settings";
import { useAuthContext } from "../../../store/auth/auth.context";
import { useTranslation } from "react-i18next";

import { Layout, Button, Space, Drawer, Menu } from "antd";
import { DropdownMenu } from "../dropdownMenu/dropdownMenu.component";
import LanguageSelector from "../language-selector/language-selector.component";
import NavButton from "../nav-button/nav-button.component";
import Tooltip from "../../shared/tooltip/tooltip.component";
import { useLocation, useNavigate } from "react-router";
import { List } from "phosphor-react";
import Item from "antd/lib/list/Item";
import DrawerMenu from "./drawer-menu/drawer-menu.component";

const { Header } = Layout;

export const Navbar = () => {
  const { t } = useTranslation();
  const authContext = useAuthContext();
  const [isTooltipOpen, setTooltipOpen] = useState(true);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleNavigation = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const currentPath = pathname.replace('/', '');
    const isObjectId = isMongoId(currentPath);

    if (!isObjectId && pathname !== '/') {
      event.preventDefault();
      navigate('/');
    }
  };

  const toggleDrawer = () => (
    setIsDrawerOpen(prev => !prev)
  );

  return (
    <>
      <DrawerMenu isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} />
      <Header>
        <div className="logo-set">
          <a href="/" onClick={handleNavigation}>
            <h1>Invoice Generator</h1>
          </a>
          <LanguageSelector />
          <div className="navigation">
            <NavButton to="/projects" text={t('header.navigate.my_invoices')} authenticationRequired />
          </div>
        </div>
        <div className="actions-set">
          {!authContext?.isAuthenticated ? (
            <>
              <Button
                href={settings().LOGIN_URL}
                size="large"
                shape="round"
              >
                {t('header.login')}
              </Button>&nbsp;&nbsp;
              <Tooltip
                defaultOpen
                trigger="click"
                open={isTooltipOpen}
                title={
                  <Space direction="vertical" style={{ rowGap: 4 }}>
                    <span>{t('popup.login_hint.title')}</span>
                    <Button
                      className="hint_button"
                      type="text"
                      onClick={() => { setTooltipOpen(false); }}
                    >
                      {t('popup.login_hint.ok')}
                    </Button>
                  </Space>
                }
              >
                <Button
                  onClick={() => window.location.href = settings().REGISTER_URL}
                  shape="round"
                  type="primary"
                  size="large"
                >
                  {t('header.sign_up')}
                </Button>
              </Tooltip>
            </>
          ) : (
            <DropdownMenu />
          )}
        </div>
        <Button className="hamburger-button" icon={<List size={32} weight="bold" color="#232323" />} onClick={toggleDrawer} />
      </Header>
    </>
  );
};
