import getStyles from "./invoice-template-items-list.styles";
import { InvoiceTemplateProps } from "../invoice-template.component";
import { formatCurrency } from "../../../../util/format-currency";
import { getTextWidth } from "../../../../util/get-text-width";
import { CurrencyInfo } from "../../../../@types/currency";
import { useTranslation } from "react-i18next";

import { Text, View } from "@react-pdf/renderer";

const LINE_MAX_CHARACTERS = 22;
const FALLBACK_COLUMN_WIDTH = 60;

const InvoiceTemplateItemsList: React.FC<InvoiceTemplateProps> = ({ state }) => {
  const { t, i18n } = useTranslation();
  const styles = getStyles(i18n);

  const { items } = state.InvoiceItemList;
  const currency = CurrencyInfo[state.InvoiceRightRail.currency].symbol;
  const textDir = i18n.dir() === 'ltr' ? 'right' : 'left';

  const getMaxTextWidth = (itemsArr: string[]) => {
    try {
      return Math.max(...itemsArr.map(item => getTextWidth(item.substring(0, LINE_MAX_CHARACTERS), '11px Nunito Sans')), 0);
    } catch (error) {
      console.error('Error calculating text width: ', error);
      return FALLBACK_COLUMN_WIDTH;
    }
  };

  const insertZeroWidthSpaces = (value: string, interval: number): string => {
    let result = '';

    for (let i = 0; i < value.length; i += interval) {
      result += value.substring(i, i + interval) + ' ';
    }

    return result;
  };

  const maxPriceWidth = getMaxTextWidth(items.map((item) => `${currency}${item?.price ?? 0}`));
  const maxQuantityWidth = getMaxTextWidth(items.map((item) => `${item?.quantity ?? 0}`));
  const maxTotalWidth = getMaxTextWidth(items.map((item) => `${currency}${(item?.price ?? 0) * (item?.quantity ?? 0)}`));

  return (
    <View style={styles.mainContainer}>
      <View style={[styles.row, styles.fontMedium]}>
        <View style={styles.itemsListItemContainer}>
          <Text>{t('invoice.invoice_items.item').toUpperCase()}</Text>
        </View>
        <View style={[styles.itemsListElementContainer, { width: maxPriceWidth }]}>
          <Text>{t('invoice.invoice_items.price').toUpperCase()}</Text>
        </View>
        <View style={[styles.itemsListElementContainer, { width: maxQuantityWidth }]}>
          <Text>{t('invoice.invoice_items.quantity').toUpperCase()}</Text>
        </View>
        <View style={[styles.itemsListTotalContainer, { width: maxTotalWidth }]}>
          <Text>{t('invoice.invoice_items.total').toUpperCase()}</Text>
        </View>
      </View>

      {items.map(item => {
        const totalPrice = (item?.price ?? 0) * (item?.quantity ?? 0);

        if (!item) {
          return;
        }


        return (
          <View key={item.id} style={styles.row}>
            <View style={styles.itemsListItemContainer}>
              <Text>{item.name}</Text>
              {item.description
                ? (
                  <Text style={[styles.tableItemDescriptionText]}>
                    {item.description}
                  </Text>
                ) : null}
            </View>
            <View style={[styles.itemsListElementContainer, { width: maxPriceWidth }, { textAlign: (item.price ?? 0) > 9999999999999999 ? textDir : 'center' }]}>
              <Text style={styles.currency}>{insertZeroWidthSpaces(`${currency}${formatCurrency(item.price ?? 0)}`, LINE_MAX_CHARACTERS)}</Text>
            </View>
            <View style={[styles.itemsListElementContainer, { width: maxQuantityWidth }]}>
              <Text style={styles.currency}>{insertZeroWidthSpaces((item.quantity ?? 0).toString(), LINE_MAX_CHARACTERS)}</Text>
            </View>
            <View style={[styles.itemsListTotalContainer, { width: maxTotalWidth }, { textAlign: textDir }]}>
              <Text style={styles.currency}>{insertZeroWidthSpaces(`${currency}${formatCurrency(totalPrice)}`, LINE_MAX_CHARACTERS)}</Text>
            </View>
          </View>
        );
      })}
    </View>
  );
};

export default InvoiceTemplateItemsList;