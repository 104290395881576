import getStyles from "./invoice-template-billing-info.styles";
import { InvoiceTemplateProps } from "../invoice-template.component";
import { useTranslation } from "react-i18next";

import { Text, View } from "@react-pdf/renderer";

const InvoiceTemplateBillingInfo: React.FC<InvoiceTemplateProps> = ({ state }) => {
  const { t, i18n } = useTranslation();
  const styles = getStyles(i18n);

  const { email1, email2, billFrom, billTo } = state.InvoiceBilling;

  return (
    <View style={styles.mainContainer}>
      <View style={styles.billingInfoContainer}>
        <Text style={styles.billingInfoTitleText}>
          {t('invoice.billing_info.bill_from')}
        </Text>
        <Text>{email1}</Text>
        <View style={styles.billingInfoTextArea}>
          {billFrom.split('\n').map(line => (
            <Text key={line}>{line}</Text>
          ))}
        </View>
      </View>

      <View style={styles.billingInfoContainer}>
        <Text style={styles.billingInfoTitleText}>
          {t('invoice.billing_info.bill_to')}
        </Text>
        <Text>{email2}</Text>
        <View style={styles.billingInfoTextArea}>
          {billTo.split('\n').map(line => (
            <Text key={line}>{line}</Text>
          ))}
        </View>
      </View>
    </View>
  );
};

export default InvoiceTemplateBillingInfo;