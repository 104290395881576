import { useEffect } from 'react';
import { FormInstance } from 'antd/es/form/Form';

import { Form } from 'antd';
import { useValidationContext } from '../store/validation/validation.context';

/**
 * makes a form controlled and returns a form instance object,
 * the object must be passed to the form
 * to control it.
 * @param fields An object with keys representing the names of the fields in an ant form element
 * @returns an object containing an ant form instance
 */
const useControlledForm = <T>(fields: Parameters<FormInstance<T>['setFieldsValue']>[0]) => {
  const validationContext = useValidationContext();
  const [form] = Form.useForm<T>();

  useEffect(() => {
    validationContext.registerValidationFunction(form.validateFields);
  }, []);

  useEffect(() => {
    form.setFieldsValue(fields);
    return () => { };
  }, [fields, form]);

  return { form };
};

export default useControlledForm;
