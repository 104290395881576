import './project-image-upload.less';
import classNames from 'classnames';
import { FormInstance } from 'antd/es/form/Form';
import { NSProject } from '../../../@types/project';
import { NSProjectSettings } from '../../../@types/project-settings';
import useProjectImageUpload from '../../../Hooks/project-settings/project-image-upload.hook';

import { Button, Form, Row, Upload } from 'antd';

export interface ProjectImageUploadProps {
  form: FormInstance<NSProject.FormData>;
  states: NSProjectSettings.States;
  params: NSProjectSettings.Params;
}

const ProjectImageUpload: React.FC<ProjectImageUploadProps> = (props) => {
  const { logoStates, logoConstants, logoHandlers, t } = useProjectImageUpload(props);

  const { loading, error } = logoStates;
  const { imageElement, endpoints, isImageLoaded, uploadIcon } = logoConstants;
  const { changeHandler, beforeUploadHandler, deleteHandler } = logoHandlers;

  return (
    <section className="image-upload">
      <div className="thumbnail-container">
        <div className={classNames('logo-container', { error, loading, disabled: props.states.isLoading.value })}>
          {isImageLoaded && !error
            ? (
              <img
                src={imageElement?.src}
                alt={t('project_settings.project_logo.title')}
              />
            )
            : uploadIcon
          }
        </div>

        <div className="info">
          <h2>{t('project_settings.project_logo.title')}</h2>
          <p title={t('project_settings.project_logo.accepted_formats')}>
            {t('project_settings.project_logo.description')}
          </p>
        </div>
      </div>

      <div className="actions">
        <Row>
          <Form.Item name="logoBase64" hidden />
          <Form.Item name="logo">
            <Upload
              name="logo"
              accept="image/*"
              onChange={changeHandler}
              beforeUpload={beforeUploadHandler}
              action={endpoints.upload}
              headers={endpoints.authHeaders}
              showUploadList={false}
            >
              <Button className='upload-btn'>
                {t(`project_settings.project_logo.${isImageLoaded ? 'new_' : ''}upload_button`)}
              </Button>
            </Upload>
          </Form.Item>

          <Button
            disabled={!isImageLoaded || props.states.isLoading.value}
            className='delete-btn'
            onClick={deleteHandler}
            danger
          >
            {t('project_settings.project_logo.delete_button')}
          </Button>
        </Row>
      </div>
    </section>
  );
};

export default ProjectImageUpload;