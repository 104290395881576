import React from 'react';
import './invoice-table-view.less';
import { NSInvoiceList } from '../../../@types/invoice';
import useInvoiceTableView from '../../../Hooks/invoice-list/invoice-table-view.hook';

import { Table } from 'antd';

export interface InvoiceTableViewProps {
  states: NSInvoiceList.States;
  params: NSInvoiceList.Params;
  functions: NSInvoiceList.Functions;
}

const InvoiceTableView: React.FC<InvoiceTableViewProps> = ({ states, params, functions }) => {
  const { tableHandlers, tableData, columns, rowSelection } = useInvoiceTableView({ states, params, functions });

  const { onRowHandler, handleTableChange } = tableHandlers;

  return (
    <Table
      rowSelection={rowSelection}
      columns={columns}
      dataSource={tableData}
      loading={states.data.value.loading}
      showSorterTooltip={false}
      pagination={false}
      onRow={onRowHandler}
      onChange={handleTableChange}
    />
  );
};

export default InvoiceTableView;
