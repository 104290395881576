import './project-default-values.less';
import { FormInstance } from 'antd/es/form/Form';
import { NSInvoice } from "../../../@types/invoice";
import { NSProject } from '../../../@types/project';
import { getInitialState } from '../../../state/state';
import { NSProjectSettings } from '../../../@types/project-settings';
import { Currency, CurrencyInfo } from '../../../@types/currency';
import useProjectDefaultValues from '../../../Hooks/project-settings/project-default-values.hook';

import { Button, Col, Form, Input, Row, Select } from 'antd';
import { AddressBook, At, CurrencyDollar, Link, Percent, Tag, TagSimple } from 'phosphor-react';
import SwitchableNumberInput from '../../shared/inplace-controlled-field/number/switchable-number-input.component';

export interface ProjectDefaultValuesProps {
  form: FormInstance<NSProject.FormData>;
  states: NSProjectSettings.States;
  params: NSProjectSettings.Params;
  formHandlers: NSProjectSettings.FormHandlers;
}

const ProjectDefaultValues: React.FC<ProjectDefaultValuesProps> = ({ form, states, params, formHandlers }) => {
  const { formStates, checkers, constants, t } = useProjectDefaultValues({ form, states, params, formHandlers });

  const { projectId } = params;
  const { isDefaultProject } = checkers;
  const { discount, tax } = formStates;
  const { addressRows } = constants;

  const initialState = getInitialState(t);

  return (
    <section className="bill-from">
      <h1>{t('project_settings.project_info.title')}</h1>
      <div className="invoice-info">
        <Row>
          <Form.Item name="title" className='title-field' label={t('project_settings.project_info.fields.title.label')} rules={[{ required: true, message: '' }]}>
            <Input disabled={isDefaultProject} placeholder={t('project_settings.project_info.fields.title.placeholder')} suffix={<TagSimple size={21} />} />
          </Form.Item>
          <Form.Item name="currency" className='currency-field' label={t('project_settings.project_info.fields.currency.label')}>
            <Select
              showSearch
              defaultActiveFirstOption={true}
              optionFilterProp="children"
              suffixIcon={<CurrencyDollar size={19} />}
              placeholder={t('project_settings.project_info.fields.currency.placeholder')}
              filterOption={(input, option) => {
                const foundLabel = option?.label.toLowerCase().includes(input.toLowerCase()) as boolean;
                const foundSymbol = input === CurrencyInfo[option?.value as unknown as Currency]?.symbol;
                return (foundSymbol || foundLabel);
              }}
              options={Object.keys(CurrencyInfo).map((currency) => {
                const currencyInfo = CurrencyInfo[currency as unknown as Currency];
                return { label: `${currencyInfo?.code} ${currencyInfo?.symbol}`, value: parseInt(currency) };
              })}
            />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item className='email-field' name="email" rules={[{ type: 'email', message: '' }]} label={t('project_settings.project_info.fields.email.label')}>
            <Input placeholder={t('project_settings.project_info.fields.email.placeholder')} type='email' suffix={<At size={21} />} />
          </Form.Item>
          <Col className='address-field'>
            <Form.Item name="address" label={t('project_settings.project_info.fields.address.label')}>
              <Input.TextArea className='address' rows={addressRows} placeholder={t('project_settings.project_info.fields.address.placeholder')} />
            </Form.Item>
            <AddressBook size={21} color='rgb(200, 200, 200)' />
          </Col>
        </Row>
      </div>
      <hr />
      <div className='discount-tax'>
        <Row>
          <Form.Item name={['discount', 'label']} initialValue={initialState.InvoiceTotals.discountLabel}>
            <Input placeholder={t('project_settings.project_info.fields.discount.placeholder')} suffix={<Tag size={20} />} />
          </Form.Item>
          <div className='connector'>
            <Link size={25} />
          </div>
          <Form.Item name={['discount', 'value']} initialValue={0}>
            <SwitchableNumberInput
              className="number-input"
              fixedSign={'$'}
              mode={discount?.mode ?? NSInvoice.AmountMode.RATIO}
              placeholder={discount?.mode === NSInvoice.AmountMode.RATIO ? '0' : '0.0'}
              onToggle={(mode) => form.setFieldValue(['discount', 'mode'], mode)}
              controls={false}
            />
          </Form.Item>
          <Form.Item name={['discount', 'mode']} initialValue={NSInvoice.AmountMode.FIXED} hidden />
        </Row>
        <Row>
          <Form.Item name={['tax', 'label']} initialValue={initialState.InvoiceTotals.taxLabel}>
            <Input placeholder={t('project_settings.project_info.fields.tax.placeholder')} suffix={<Percent size={20} />} />
          </Form.Item>
          <div className='connector'>
            <Link size={25} />
          </div>
          <Form.Item name={['tax', 'value']} initialValue={0}>
            <SwitchableNumberInput
              className="number-input"
              fixedSign={'$'}
              mode={tax?.mode ?? NSInvoice.AmountMode.RATIO}
              placeholder={tax?.mode === NSInvoice.AmountMode.RATIO ? '0' : '0.0'}
              onToggle={(mode) => form.setFieldValue(['tax', 'mode'], mode)}
              controls={false}
            />
          </Form.Item>
          <Form.Item name={['tax', 'mode']} initialValue={NSInvoice.AmountMode.FIXED} hidden />
        </Row>
      </div>
      <Row className='form-actions'>
        {projectId && (
          <Button
            className='delete-btn'
            onClick={formHandlers.showDeleteConfirmHandler}
            disabled={isDefaultProject}
            danger
          >
            {t('project_settings.project_info.delete_project_button')}
          </Button>
        )}
        <Button className='save-btn' htmlType='submit'>{t(`project_settings.project_info.${projectId ? 'save' : 'create'}_button`)}</Button>
      </Row>
    </section>
  );
};

export default ProjectDefaultValues;