import { ItemFormValues } from "../../../@types/ListItem";
import "./items-list-row.less";
import { formatCurrency } from "../../../util/format-currency";
import { getInitialState } from "../../../state/state";
import classNames from 'classnames';
import { useItemsListRow } from "../../../Hooks/items-list-row.hook";

import { Button, Col, Form, FormInstance, Row } from "antd";
import EditableInput from "../../shared/inplace-controlled-field/editable-input.component";
import EditableInputNumber from "../../shared/inplace-controlled-field/number/editable-input-number.component";
import { Currency, CurrencyInfo } from "../../../@types/currency";
import { Trash, Eraser } from "phosphor-react";
import Tooltip from "../../shared/tooltip/tooltip.component";

interface IProps {
  currency: Currency;
  withDiscount?: boolean;
  form: FormInstance<ItemFormValues>;
  restField: {
    fieldKey?: number;
  };
  name: number;
  remove: (index: number | number[]) => void;
  add: (defaultValue?: any, insertIndex?: number) => void;
  index: number;
}

const ItemsListRow = (props: IProps) => {
  const { total, i18n, t } = useItemsListRow(props.name, props.form, props.withDiscount);
  const isSingleItem = (props.form.getFieldValue('items') || []).length == 1;
  const rowValue = Form.useWatch(['items', props.index], props.form);
  const name = rowValue?.name || '';
  const price = rowValue?.price || 0;
  const quantity = rowValue?.quantity || 0;
  const description = rowValue?.description;

  const handleRemoveOrClear = () => {
    if (isSingleItem) {
      props.form.setFieldsValue(getInitialState().InvoiceItemList);
    } else {
      props.remove(props.name);
    }
  };

  return (
    <div className="items-list-row">
      <Col>
        <Form.Item
          {...props.restField}
          name={[props.name, "name"]}
          rules={[{ required: true, message: '' }]}
        >
          <EditableInput
            placeholder={t('invoice.invoice_items.item_name')}
            className="item-name-input"
          />
        </Form.Item>
        <p className={classNames('print-helper', { 'no-data': !name })}>{name || t('invoice.print.no_data_entered')}</p>
      </Col>
      <Col>
        <Form.Item
          {...props.restField}
          name={[props.name, "price"]}
          rules={[{ required: true, message: '' }]}
          initialValue={0}
        >
          <EditableInputNumber
            prefix={CurrencyInfo[props.currency].symbol}
            noFixedSign
            className="number-input"
            placeholder={t('invoice.invoice_items.price')}
            min={0}
          />
        </Form.Item>
        <p className="print-helper">{CurrencyInfo[props.currency].symbol}{price}</p>
      </Col>
      <Col>
        <Form.Item
          {...props.restField}
          name={[props.name, "quantity"]}
          rules={[{ required: true, message: '' }]}
          initialValue={1}
        >
          <EditableInputNumber
            noFixedSign
            className="number-input"
            placeholder={t('invoice.invoice_items.quantity')}
            min={1}
          />
        </Form.Item>
        <p className="print-helper">{quantity}</p>
      </Col>
      {props.withDiscount && (
        <Col>
          <Form.Item
            {...props.restField}
            name={[props.name, "discount"]}
            rules={[{ required: true, message: '' }]}
          >
            <EditableInputNumber
              noFixedSign
              className="number-input"
              placeholder="Discount%"
              percentage
            />
          </Form.Item></Col>

      )}
      <Col className="col-total overflow">
        <span>
          {formatCurrency(total, props.currency, i18n)}
        </span>
      </Col>
      <Row className="item-row">
        <Col span={22}>
          <Form.Item
            {...props.restField}
            name={[props.name, "description"]}
          >
            <EditableInput
              placeholder={t('invoice.invoice_items.description')}
              className="item-desc-input"
            />
          </Form.Item>
          <p className="print-helper">{description}</p>
        </Col>
        <Col span={1}>
          <Tooltip
            placement="top"
            title={isSingleItem ? t('invoice.invoice_items.clear_item_tooltip') : t('invoice.invoice_items.delete_item_tooltip')}
            mouseEnterDelay={0}
            overlayInnerStyle={{ padding: "0.5rem", fontSize: "0.7rem" }}
            color="rgb(0 0 0 / 73%)"
          >
            <Button
              icon={isSingleItem ? <Eraser size={24} /> : <Trash size={24} />}
              type="text"
              danger
              onClick={handleRemoveOrClear}
              className="delete-btn"
            />
          </Tooltip>
        </Col>
      </Row>
    </div>
  );
};

export default ItemsListRow;
