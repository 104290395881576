import { FC } from 'react';
import './page-header.less';
import { ButtonProps } from 'antd/es/button';
import { IBreadcrumbItemProps } from '../breadcrumb/breadcrumb-item/breadcrumb-item.component';

import BreadcrumbNavigation from '../breadcrumb/breadcrumb.component';
import { Button } from 'antd';

interface IProps {
  text: string;
  actions?: {
    title: string;
    icon: JSX.Element;
    handler: () => void;
    props?: ButtonProps;
  }[];
  customBreadcrumbItems?: IBreadcrumbItemProps[];
}

const PageHeader: FC<IProps> = ({ text, actions, customBreadcrumbItems }) => {

  return (
    <div className="page-header">
      <div>
        <h1>{text}</h1>
        <BreadcrumbNavigation customItems={customBreadcrumbItems} />
      </div>

      <div>
        {actions?.map((action) => {
          const { title, icon, handler } = action;

          return <Button key={title} className="action-btn" onClick={handler} icon={icon} {...action.props}>
            {title}
          </Button>;
        })}
      </div>
    </div>
  );
};

export default PageHeader;