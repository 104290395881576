import Cookies from 'universal-cookie';
import jwt from 'jwt-decode';

const cookies = new Cookies();

class AccessTokenService {
  get = () => {
    let accessToken = cookies.get('accessToken');
    if (accessToken) {
      if (accessToken !== 'guest') {
        const data: { exp: number; sub: any; } = jwt(accessToken);
        const exp = data.exp;

        if (exp < (new Date().getTime() + 1) / 1000) {
          accessToken = 'guest';
        }
      }
    } else {
      accessToken = 'guest';
    }

    return accessToken;
  };

  set = (value: string) => {
    cookies.set('accessToken', value);
  };

}

export default new AccessTokenService();
