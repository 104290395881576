import { FC } from 'react';
import './breadcrumb.less';
import { useLocation } from 'react-router-dom';

import BreadcrumbItem, { IBreadcrumbItemProps } from './breadcrumb-item/breadcrumb-item.component';
import { Breadcrumb } from 'antd';
import { isMongoId } from '../../../util/mongo-id';
import { useAuthContext } from '../../../store/auth/auth.context';
import { classNames } from '@react-pdf-viewer/core';

interface IProps {
  customItems?: IBreadcrumbItemProps[];
}

const BreadcrumbNavigation: FC<IProps> = ({ customItems }) => {
  const location = useLocation();
  const authCtx = useAuthContext();
  const pathElements = location.pathname
    .split('/')
    .filter(item => item && !isMongoId(item));

  const breadcrumbItems = (
    customItems
      ? (
        customItems.map(pathElement => {
          const route = `/${pathElement}`;
          return (
            <BreadcrumbItem
              key={route}
              {...pathElement}
              custom
            />
          );
        })
      )
      : (
        pathElements.map((pathElement, i) => {
          const route = `/${pathElement}`;
          const isLastElement = i === pathElements.length - 1;

          return (
            <BreadcrumbItem
              key={pathElement}
              title={pathElement}
              route={!isLastElement ? route : undefined}
            />
          );
        })
      )
  );

  return (
    <Breadcrumb className={classNames({ empty: authCtx.isGuest })}>
      {
        authCtx.isAuthenticated && (
          breadcrumbItems
        )
      }
    </Breadcrumb>
  );
};

export default BreadcrumbNavigation;
