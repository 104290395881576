import { message } from 'antd';
import http from './http-error';
import MissingBlockProperties from './missing-block-properties';
import MissingResponseProperties from './missing-response-properties';

const { HttpError, throwHttpError } = http;

/**
 * Displays an error toast to the user.
 * Throws caught error immediately after
 * @param {string} msg (Optional) Message to display to the user. Defaults to 'Something went wrong'.
 */
export const errorToast = (msg: string = 'Something went wrong') => (err: Error) => {
  message.error(msg);
  throw err;
};

/**
 * Exports error classes and other error functions.
 */
export {
  HttpError,
  throwHttpError,
  MissingBlockProperties,
  MissingResponseProperties,
};

/**
 * Exports a collection of error classes.
 */
export default {
  HttpError,
  MissingBlockProperties,
  MissingResponseProperties
} as const;