import { useEffect, useState } from 'react';
import invoiceService from '../../Services/invoice.service';
import { APIResponse } from '../../@types/responses';
import { message } from 'antd';
import { pdf } from '@react-pdf/renderer';
import { settings } from '../../settings';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';

import SendInvoiceModal from '../../Components/invoice/send-invoice-modal/send-invoice-modal.component';
import InvoiceTemplate from '../../Components/invoice/invoice-template/invoice-template.component';

const useSendInvoiceModal = (props: SendInvoiceModal) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [isAttachMessageShown, setAttachMessageShown] = useState(false);
  const [shareURL, setShareURL] = useState('');

  useEffect(() => {
    setAttachMessageShown(false);
    setShareURL('');
    form.resetFields();
  }, [props.open]);

  const toggleAttachMessageShown = () => {
    setAttachMessageShown(prev => !prev);
  };

  const handleSubmit = async ({ recipients, subject, body }: { recipients: string[], subject?: string, body?: string; }) => {
    const doc = <InvoiceTemplate state={props.state} />;
    const asPdfBlob = await pdf(doc).toBlob();
    const formData = new FormData();

    subject && formData.append('subject', subject);
    body && formData.append('body', body);
    formData.append('invoiceNumber', props.state.InvoiceHeader.invoiceNumber);
    formData.append('recipients', JSON.stringify(recipients));
    formData.append('pdf', asPdfBlob);

    try {
      await invoiceService.mailShare(formData, recipients);
      message.success(t('invoice.share_modal.sent_success'));
    } catch {
      message.error(t('invoice.share_modal.sent_error'));
    }
  };

  const generateInvoiceShareURL = async () => {
    if (shareURL) {
      return shareURL;
    }

    const doc = <InvoiceTemplate state={props.state} />;
    const asPdfBlob = await pdf(doc).toBlob();
    const formData = new FormData();

    formData.append('pdf', asPdfBlob, `invoice_${props.state.InvoiceHeader.invoiceNumber}.pdf`);

    const response: APIResponse.IPostUploadedFile = await invoiceService.linkShare(formData);
    const url = `${(settings().INVOICE_API)}/invoices/download/${response._id}`;

    setShareURL(url);

    return url;
  };

  const copyLink = async () => {
    const invoiceLink = await generateInvoiceShareURL();
    navigator.clipboard.writeText(invoiceLink);
    message.success(t('invoice.share_modal.copy_success'));
  };

  const handleShareButton = async (shareFunction: (url: string) => void) => {
    const invoiceLink = await generateInvoiceShareURL();
    shareFunction(invoiceLink);
  };

  return {
    states: {
      isAttachMessageShown: { value: isAttachMessageShown, set: setAttachMessageShown, toggle: toggleAttachMessageShown },
      shareURL: { value: shareURL, set: setShareURL }
    },
    functions: {
      handleSubmit,
      copyLink,
      handleShareButton
    },
    form,
    t
  };
};

export default useSendInvoiceModal;