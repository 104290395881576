import { StyleSheet } from '@react-pdf/renderer';
import { i18n } from 'i18next';

const getStyles = (locale: i18n) => {
  const textDir = locale.dir() === 'ltr' ? 'right' : 'left';
  const flexDir = locale.dir() === 'ltr' ? 'row' : 'row-reverse';

  return StyleSheet.create({
    mainContainer: {
      flexDirection: flexDir,
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      paddingBottom: 15,
      borderBottom: '1 solid black'
    },
    sideInfoContainer: {
      fontSize: 10,
      width: '30%'
    },
    invoiceNumberContainer: {
      flexDirection: flexDir
    },
    invoiceNumberText: {
      fontWeight: 'medium'
    },
    dateTitleText: {
      marginTop: 3,
      fontWeight: 'medium'
    },
    logoImage: {
      position: 'relative',
      bottom: 7,
      height: 50,
      width: 50,
      borderRadius: 5
    },
    alignEnd: {
      textAlign: textDir
    }
  } as const);
};

export default getStyles;