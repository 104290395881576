import { StyleSheet } from '@react-pdf/renderer';
import { i18n } from 'i18next';

const getStyles = (locale: i18n) => {
  return StyleSheet.create({
    mainContainer: {
      flexDirection: locale.dir() === 'ltr' ? 'row' : 'row-reverse',
      paddingTop: 20,
      paddingBottom: 20,
      borderBottom: '1 solid rgb(170, 170, 170)'
    },
    billingInfoContainer: {
      width: '50%',
      fontSize: 10
    },
    billingInfoTitleText: {
      fontWeight: 'medium',
      marginBottom: 3
    },
    billingInfoTextArea: {
      marginTop: 5
    }
  } as const);
};

export default getStyles;