import { FolderNotch } from 'phosphor-react';
import './card-icon.less';

interface IProps {
  invoicesCount: number;
}

const CardIcon = ({ invoicesCount }: IProps) => {
  return <div className="card-icon">
    <FolderNotch size={23} />
    <p className='count'>{invoicesCount}</p>
  </div>;
};

export default CardIcon;