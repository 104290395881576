import { createContext, useContext, useEffect, useMemo, useState } from "react";
import projectService from "../../Services/project.service";
import { User } from "../../@types/user";
import { NSProject } from "../../@types/project";
import { settings } from "../../settings";
import { message } from "antd";
import { t } from "i18next";
import { useJwt } from "react-jwt";
import { useAuthContext } from "../auth/auth.context";

const UserContext = createContext<User.Context>(null as any);

export const useUserContext = () => {
  const userContext = useContext(UserContext);

  return userContext;
};

/**
 * This is a store for user related data and user-inputted settings, and acts as a single source of truth for it.
 * 
 * This includes:
 * - User information (e.g. email and workspaces)
 * - Project information (e.g. default project ref)
 * 
 * This information is global, `readonly` and independent of the current invoice selected.
 */
export const UserContextProvider: React.FC = ({ children }) => {
  const [projects, setProjects] = useState<NSProject.Dto[]>([]);
  const [loading, setLoading] = useState(true);
  const authContext = useAuthContext();
  const { decodedToken }: any = useJwt(authContext?.accessToken || '');

  const fetchAllUserProjects = async () => {
    if (!authContext.isAuthenticated) return;
    setLoading(true);
    projectService
      .getAllUserProjects({ pageSize: settings().PROJECT_MAX_COUNT })
      .then(res => setProjects(res.dataList))
      .catch(() => message.error(t('messages.user_projects_fetch_error')))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
  }, [projects]);

  useEffect(() => {
    fetchAllUserProjects();
  }, []);

  let userInfo = {
    sub: "",
    email: "",
    firstName: "",
    lastName: "",
    workspaces: []
  };

  if (decodedToken) {
    userInfo = decodedToken ? JSON.parse(decodedToken?.sub) : userInfo;
  }

  const contextValue: User.Context = useMemo(() => {
    const defaultProj = projects.find(project => project.name === 'default')!;
    return {
      projects: {
        value: projects,
        set: setProjects,
        refresh: fetchAllUserProjects,
        defaultProjectRef: defaultProj,
      },
      userInfo,
      loading: loading,
    };
  }, [projects, userInfo]);

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export default UserContext;
