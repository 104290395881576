import { SetURLSearchParams, useSearchParams as useRouterSearchParams } from 'react-router-dom';

const useSearchParams = <T>(key: string, routerSearchParams?: [URLSearchParams, SetURLSearchParams], isArray?: boolean) => {
  const [searchParams, setSearchParams] = routerSearchParams ?? useRouterSearchParams();

  const setParam = (value: T | undefined) => {
    setSearchParams((prev) => {
      if (!value) {
        prev.delete(key);
      } else if (Array.isArray(value) && value.length > 0) {
        prev.delete(key);
        value.forEach(item => prev.append(key, item));
      } else {
        prev.set(key, value as any);
      }
      return prev;
    });
  };

  const getParam = (): T | null => {
    const value = isArray ? searchParams.getAll(key) : searchParams.get(key);
    if (value === null || (isArray && !value?.length)) return null;
    return value as any;
  };

  return [getParam() ?? undefined, setParam] as const;
};

export default useSearchParams;
