import './profile-info.less';
import { useUserContext } from '../../../../../store/user/user.context';

import Avatar from "react-avatar";
import { Link } from 'react-router-dom';
import { settings } from '../../../../../settings';

const ProfileInfo = () => {
  const userContext = useUserContext();
  const profileDest = `${settings().SSO}/profile`;

  const userAvatar = (
    <Avatar
      className='user-avatar'
      name={`${userContext.userInfo.firstName} ${userContext.userInfo.lastName}`}
      round
      color="#ED6D9C"
      textSizeRatio={2.4}
      size="65"
    />
  );

  return (
    <Link className='user-profile-info' reloadDocument={false} to={profileDest}>
      {userAvatar}
      <div className="user-info">
        <p className="user-full-name">
          {userContext.userInfo.firstName} {userContext.userInfo.lastName}
        </p>
        <p className="user-email">{userContext.userInfo.email}</p>
      </div>
    </Link>
  );
};

export default ProfileInfo;