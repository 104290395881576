import React, { useEffect, useRef } from 'react';
import './resizable-editable-input.less';

import { FormInstance } from 'antd/es/form/Form';
import { InvoiceHeader } from '../../../state/actions';

import { Form, Input, InputRef } from 'antd';
import EditableInput from './editable-input.component';

type IProps = React.ComponentProps<typeof Input> & {
  formInstance: FormInstance<InvoiceHeader>;
  sanitize?: boolean;
};

const ResizableEditableInput = (props: IProps) => {
  const inputRef = useRef<InputRef>(null);
  const shadowRef = useRef<HTMLDivElement>(null);
  const fieldValue = Form.useWatch(props.name!, props.formInstance);
  const {formInstance, sanitize, ...inputProps} = props;

  const sanitizeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.sanitize) {
      const sanitizedValue = event.target.value.replace(/[^a-zA-Z0-9-_ ]/g, '');
      event.target.value = sanitizedValue;
    }

    if (props.onChange) {
      props.onChange(event);
    }
  };

  useEffect(() => {
    const inputElement = inputRef.current?.input;
    const shadowElement = shadowRef.current;

    if (inputElement && shadowElement) {
      const inputValue = inputElement.value;

      shadowElement.innerHTML = inputValue.replaceAll(' ', '&nbsp;');
      inputElement.style.width = `${Math.max(22, shadowElement.offsetWidth)}px`;
    }

  }, [fieldValue]);

  return (
    <>
      <EditableInput
        {...inputProps}
        ref={inputRef}
        className='resizable'
        type='text'
        onChange={sanitizeInput}
      />
      <div className='shadow' ref={shadowRef} />
    </>
  );
};

export default ResizableEditableInput;