import { Button } from "antd";
import { useTranslation } from 'react-i18next';
import { ITranslation } from "../../../../../@types/translation";
import { useEffect, useState } from "react";

interface IProps {
  isDrawerOpen: boolean;
}

const LanguageSelector = (props: IProps) => {
  const { i18n, t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(prev => !prev);
  };

  useEffect(() => {
    if (!props.isDrawerOpen) {
      setIsOpen(false);
    }
  }, [props.isDrawerOpen]);

  return (
    <>
      <Button onClick={toggleOpen}>{t('header.language')} ({(ITranslation.LanguageInfo[i18n.language as ITranslation.Language] ?? ITranslation.LanguageInfo['en']).title})</Button>
      {isOpen && <>
        {Object.values(ITranslation.Language).map((language) => {
          const languageInfo = ITranslation.LanguageInfo[language as ITranslation.Language];
          return <Button onClick={() => { i18n.changeLanguage(languageInfo.shortcut); }}>{languageInfo.title}</Button>;
        })}
      </>}
    </>
  );
};

export default LanguageSelector;