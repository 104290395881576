import React from 'react';
import { UploadProps, Upload, message } from 'antd';
import './invoice-image-upload.less';
import { IInvoiceState } from '../../../state/state';
import { useUpload } from '../../../Hooks/upload.hook';
import { APIResponse } from '../../../@types/responses';
import classNames from 'classnames';

import { CloudArrowUp, FileX, HourglassMedium, Trash } from 'phosphor-react';
import { ActionTypes, Actions, InvoiceHeaderPayload } from '../../../state/actions';
import Tooltip from '../tooltip/tooltip.component';
import { isDefaultProjectLogo } from '../../../util/get-logo-endpoint';

interface IProps extends Omit<UploadProps, 'accept' | 'action'> {
  state: { value: IInvoiceState, dispatch: React.Dispatch<Actions>; };
  handleInput: (payload: InvoiceHeaderPayload) => void;
  onSelect?: (info?: APIResponse.IPostUploadedFile) => void;
  uploadEndpoints: {
    /**
     * Endpoint to upload the image to.
     */
    upload: string;
    /**
     * Endpoint to delete the image, pass `undefined` if there is no image to delete.
     */
    delete?: string;
    /**
     * headers to be sent along with the image, if they exist, for authentication.
     */
    authHeaders?: Record<string, string>;
  };
}

const InvoiceImageUpload = (props: IProps) => {
  const { handleInput, onSelect, className, state, uploadEndpoints, ...rest } = props;
  const {
    imageElement,
    classes,
    hint,
    loading,
    error,
    changeHandler,
    deleteFile,
    beforeUploadHandler,
    t,
  } = useUpload(
    state.value.InvoiceHeader.logoURL,
    onSelect
  );

  const { logoURL } = state.value.InvoiceHeader;
  const uploadButton = (
    <div className="btn-content">
      <div className="logo__container">
        {error !== null ? (
          <FileX color="red" size={32} className="logo" />
        ) : loading ? (
          <HourglassMedium size={32} className="logo" />
        ) : (
          <CloudArrowUp size={45} className="logo" />
        )}
      </div>
      <div className={classNames('hint', { error })}>{hint}</div>
    </div>
  );

  const tooltipProps = {
    placement: "top",
    mouseEnterDelay: 0,
    overlayInnerStyle: { padding: "0.5rem", fontSize: "0.9rem" },
    color: "rgb(0 0 0 / 73%)",
  } as const;

  const handleDelete = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (uploadEndpoints.delete) {
      deleteFile(uploadEndpoints.delete, uploadEndpoints.authHeaders);
    } else if (logoURL && isDefaultProjectLogo(logoURL)) {
      props.state.dispatch({ type: ActionTypes.UPDATE_HEADER, payload: { logoURL: null } });
    } else {
      console.error('Failed to delete message: Deletion endpoint is not defined');
      message.error(t('messages.deleting_failed')); // TODO: better message 
    }
    e.stopPropagation();
  };

  return (
    <Upload
      beforeUpload={beforeUploadHandler}
      className={classNames(classes, className)}
      action={uploadEndpoints.upload}
      showUploadList={false}
      accept="image/*"
      headers={uploadEndpoints.authHeaders}
      onChange={changeHandler}
      name="logo"
      {...rest}
    >
      {logoURL && imageElement && !error ? (
        <>
          <div className="backdrop">
            <Tooltip title="Change" {...tooltipProps}>
              <button className="modal change-btn">
                <CloudArrowUp size={32} color="#f0f2f5" weight="regular" />
              </button>
            </Tooltip>
            <Tooltip title="Delete" {...tooltipProps}>
              <button className="modal trash-btn">
                <Trash onClick={handleDelete} size={32} color="#a10000" weight="regular" />
              </button>
            </Tooltip>
          </div>
          <div className="thumbnail-wrapper">
            <img src={imageElement.src} alt="Invoice Logo" className="thumbnail" />
          </div>
        </>
      ) : (
        uploadButton
      )}
    </Upload>
  );
};

export default InvoiceImageUpload;
