import './page-layout.less';
import { Layout } from 'antd';
import { Navbar } from '../../nav-bar/navbar/navbar.component';
import { useTranslation } from 'react-i18next';

const { Content, Footer } = Layout;

export const PageLayout: React.FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Navbar />
      <Content className="main-content">
        {children}
      </Content>
      <Footer color="#fff">&copy; {new Date().getFullYear()} {t('footer.copyright')}</Footer>
    </Layout>
  );
};

export default PageLayout;