import { CSSProperties } from 'react';

const cardStyle: CSSProperties = {
  width: 280,
  height: 60,
  marginTop: 16,
  borderRadius: 15,
  paddingRight: 20,
  paddingLeft: 20,
  opacity: .4,
};

const skeletonStyle: CSSProperties = {
  marginTop: 12
};

export default { cardStyle, skeletonStyle };