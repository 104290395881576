import './drawer-menu.less';

import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import Item from 'antd/lib/list/Item';
import { Button, Drawer, Menu, MenuProps } from "antd";
import LanguageSelector from './language-selector/language-selector.component';
import NavButton from '../../nav-button/nav-button.component';
import ProfileInfo from './profile-info/profile-info.component';
import { useAuthContext } from '../../../../store/auth/auth.context';

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const DrawerMenu = (props: IProps) => {
  const { i18n, t } = useTranslation();
  const authContext = useAuthContext();

  return (
    <Drawer
      className="navigation-drawer"
      placement="bottom"
      headerStyle={{ display: 'none' }}
      onClose={() => { props.setIsOpen(false); }}
      open={props.isOpen}
      closeIcon={null}
      height="auto"
    >
      <Menu mode="vertical">
        <Item key="1">
          <ProfileInfo />
        </Item>
        <Item key="2">
          <NavButton to="/projects" text={t('header.navigate.my_invoices')} authenticationRequired closeDrawer={() => { props.setIsOpen(false); }} />
        </Item>
        <Item key="3">
          <LanguageSelector isDrawerOpen={props.isOpen} />
        </Item>
        <Item key="4">
          <Button className='logout' onClick={() => { authContext.logout(); }}>{t('header.actions.logout')}</Button>
        </Item>
      </Menu>
    </Drawer>
  );
};

export default DrawerMenu;