import { Item, ItemFormValues } from "../@types/ListItem";
import { NSInvoice } from "../@types/invoice";
import { calcTotalsData } from "../Hooks/totals.hook";

export const calculateSubTotals = (itemsList?: ItemFormValues) => {
  if (!itemsList)
    return 0;
  return (itemsList).items?.reduce(
    (acc: number, e: Item | null) => acc + +(e?.price ?? 0) * +(e?.quantity ?? 0) * ((1 - (+(e?.discount ?? 0) / 100.0))),
    0,
  ) ?? 0;
};

export const calculateTotal = ({ InvoiceItemList, InvoiceTotals }: calcTotalsData) => {
  if (InvoiceTotals === undefined || InvoiceItemList === undefined)
    return {};
  const subTotal = calculateSubTotals(InvoiceItemList);

  const {
    discount = 0,
    discountMode,
    taxMode,
    tax = 0,
    enableTax = true,
    enableDiscount = true
  } = InvoiceTotals;

  let total = subTotal;

  if (enableDiscount && discount !== null)
    if (discountMode === NSInvoice.AmountMode.RATIO)
      total *= 1 - (+discount / 100.0);
    else if (discountMode === NSInvoice.AmountMode.FIXED)
      total -= +discount;

  if (enableTax && tax !== null)
    if (taxMode === NSInvoice.AmountMode.RATIO)
      total *= 1 + (+tax / 100.0);
    else if (taxMode === NSInvoice.AmountMode.FIXED)
      total += +tax;

  return { total, subTotal };
};
