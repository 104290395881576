import './invoice-list.less';
import useInvoiceList from '../../Hooks/invoice-list/invoice-list.hook';

import { Pagination } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import InvoiceTableView from '../../Components/invoice-list/invoice-table-view/invoice-table-view.component';
import InvoiceCardsView from '../../Components/invoice-list/invoice-cards-view/invoice-cards-view.component';
import InvoiceControls from '../../Components/invoice-list/invoice-controls/invoice-controls.component';
import PageHeader from '../../Components/shared/page-header/page-header.component';

const InvoiceListPage = () => {
  const { states, params, functions, constants, t } = useInvoiceList();

  const { currentPage, totalItems, loading } = states;
  const { isTableView, pageSize } = constants;
  const { createNewInvoiceHandler, getInvoiceListBreadcrumbItems } = functions;

  return (
    <div className="invoices-list-wrapper">
      <PageHeader
        text={t('invoice_list.page_title')}
        actions={[{
          title: t('invoice_list.actions.create_new_invoice'),
          icon: <PlusOutlined />,
          handler: createNewInvoiceHandler,
          props: {
            loading: loading.value
          }
        }]}
        customBreadcrumbItems={getInvoiceListBreadcrumbItems()}
      />

      <div className="body">
        <InvoiceControls
          states={states}
          params={params}
          functions={functions}
          allowSorting={!isTableView}
        />
        {
          isTableView
            ? <InvoiceTableView states={states} params={params} functions={functions} />
            : <InvoiceCardsView states={states} functions={functions} />
        }
      </div>

      <div className="footer">
        <Pagination
          current={currentPage.value}
          onChange={currentPage.set}
          total={totalItems.value}
          pageSize={pageSize}
        />
        <p>{t('invoice_list.shown_invoices')} {totalItems.value}</p>
      </div>
    </div>
  );
};

export default InvoiceListPage;
