import { PDFDownloadLink } from '@react-pdf/renderer';
import { useTranslation } from "react-i18next";
import { useValidationContext } from '../../../../store/validation/validation.context';
import { message } from 'antd';

import { IInvoiceState } from '../../../../state/state';
import InvoiceTemplate from '../invoice-template.component';

interface IProps {
  state: IInvoiceState;
  actionButtonType?: ACTION_BUTTON_TYPE,
  previewLink?: { value: string | null, set: React.Dispatch<React.SetStateAction<string | null>>; };
}

export enum ACTION_BUTTON_TYPE {
  DOWNLOAD = 'download',
  PREVIEW = 'preview'
}

const PDFDownloadButton = ({ state, actionButtonType = ACTION_BUTTON_TYPE.DOWNLOAD, previewLink }: IProps) => {
  const { t } = useTranslation();
  const validationContext = useValidationContext();
  const fileName = `invoice_${state.InvoiceHeader.invoiceNumber}.pdf`;

  const handleClick = async (blob: Blob | null) => {
    const isFieldsValid = await validationContext.executeAllValidations();

    if (!isFieldsValid) {
      message.error(t('messages.empty_fields'));
      return;
    }

    const blobURL = window.URL.createObjectURL(blob as any);

    if (actionButtonType === ACTION_BUTTON_TYPE.DOWNLOAD) {
      const tempLink = document.createElement('a');
      tempLink.href = blobURL;
      tempLink.setAttribute('download', fileName);
      tempLink.click();
    } else {
      previewLink?.set(blobURL);
    }
  };

  return <PDFDownloadLink document={<InvoiceTemplate state={state} />}
    fileName={fileName}>

    {({ blob }) => {
      return <button
        className={`${actionButtonType}-btn`}
        onClick={(e) => { e.preventDefault(); handleClick(blob); }}
      >
        {t(`invoice_actions.${actionButtonType}`)}
      </button>;
    }}
  </PDFDownloadLink>;
};

export default PDFDownloadButton;