import { Form } from "antd";
import { ProjectDefaultValuesProps } from "../../Components/project-settings/project-default-values/project-default-values.component";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../store/user/user.context";


const useProjectDefaultValues = ({ form, params }: ProjectDefaultValuesProps) => {
  const { t } = useTranslation();
  const userContext = useUserContext();
  const address = Form.useWatch('address', form);
  const discount = Form.useWatch('discount', form);
  const tax = Form.useWatch('tax', form);
  const addressRows = Math.min(address?.split('\n').length ?? 1, 6);

  const isDefaultProject = userContext.projects.defaultProjectRef?._id === params.projectId;

  return {
    formStates: {
      discount,
      tax
    },
    checkers: {
      isDefaultProject
    },
    constants: {
      addressRows
    },
    t
  };
};

export default useProjectDefaultValues;