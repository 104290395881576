import { StyleSheet } from '@react-pdf/renderer';
import { i18n } from 'i18next';

const getStyles = (locale: i18n) => {
  return StyleSheet.create({
    mainContainer: {
      flexDirection: 'column',
    },
    itemsListElementContainer: {
      marginHorizontal: 5,
      textAlign: 'center',
      minWidth: 60,
    },
    itemsListItemContainer: {
      flex: 1,
      marginHorizontal: 5,
      minWidth: 100,
    },
    itemsListTotalContainer: {
      marginHorizontal: 5,
      textAlign: 'center',
      minWidth: 60,
    },
    tableItemText: {
      width: '100%',
    },
    tableItemDescriptionText: {
      color: 'rgba(0, 0, 0, 0.4)'
    },
    row: {
      flexDirection: locale.dir() === 'ltr' ? 'row' : 'row-reverse',
      fontSize: 10,
      justifyContent: 'space-between',
      padding: 10,
      borderBottom: '1 solid rgb(170, 170, 170)'
    },
    currency: {
      fontFamily: 'Code 2000'
    },
    fontMedium: {
      fontWeight: 'medium'
    }
  } as const);
};

export default getStyles;