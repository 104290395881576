import React from 'react';
import './editable-textarea.less';

import { Input } from 'antd';

interface IProps extends React.ComponentProps<typeof Input.TextArea> {
}

const EditableTextArea = (props: IProps) => {
  return (
    <Input.TextArea
      {...props}
      className={`inplace-editable-textarea ${props?.className ?? ''}`}
    />
  );
};

export default EditableTextArea;
