import { useState } from 'react';
import './invoice-card.less';
import { NSInvoiceList, NSInvoice } from '../../../@types/invoice';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { toAbsoluteLogoURL } from '../../../util/get-logo-endpoint';

import { IdcardOutlined, CalendarOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import { ImageSquare, Trash } from 'phosphor-react';

interface IProps {
  invoice: NSInvoice.CreateAndUpdateInvoiceDto;
  onClick?: () => void;
  functions: NSInvoiceList.Functions;
}

const InvoiceCard = ({ invoice, onClick, functions }: IProps) => {
  const [isImageError, setImageError] = useState(false);
  const { i18n, t } = useTranslation();

  return <Card
    onClick={onClick}
    style={{ width: 280, marginTop: 16, borderRadius: 15 }}
  >
    <div className="actions">
      <Button
        type="text"
        icon={<Trash className="trash-icon" size={18} />}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          functions.showDeleteConfirm([invoice._id ?? ''], invoice.invoiceNumber);
        }}
      />
    </div>

    <div className="card-header">
      <div className="invoice-img">
        {invoice?.logoURL && !isImageError
          ? <img src={toAbsoluteLogoURL(invoice.logoURL)!} onError={() => { setImageError(true); }} alt="Invoice" />
          : <ImageSquare size={35} />}
      </div>
      <div className="info">
        <h1>
          {t('invoice.header.invoice')}
          {' '}
          #
          <span className='invoice-number'>{invoice.invoiceNumber ?? '000'}</span>
        </h1>
        {invoice?.comments && invoice.comments.length !== 0 && <p>{invoice.comments[0].text}</p>}
      </div>
    </div>

    <div className="card-content">
      {invoice?.customerData.email
        && <span
          title="Customer Identity"
          className="icon-text"
        >
          <IdcardOutlined />
          {invoice.customerData?.email}
        </span>}
      {invoice?.createdAt
        && <span
          title="Due date"
          className="icon-text"
        >
          <CalendarOutlined />
          {moment(invoice.createdAt).locale(i18n.language).format('DD MMMM, YYYY')}
        </span>}
    </div>

    <div className="card-footer">
      {invoice?.updatedAt && <p className="last-updated">
        {t('invoice_list.last_updated')}
        <span>{moment(invoice.updatedAt).locale(i18n.language).format('DD MMMM, YYYY')}</span>
      </p>}
    </div>
  </Card>;
};

export default InvoiceCard;
