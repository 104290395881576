import moment from "moment";
import getStyles from "./invoice-template-header.styles";
import { InvoiceTemplateProps } from "../invoice-template.component";
import { useTranslation } from "react-i18next";
import { toAbsoluteLogoURL } from "../../../../util/get-logo-endpoint";

import { Image, Text, View } from "@react-pdf/renderer";

const InvoiceTemplateHeader: React.FC<InvoiceTemplateProps> = ({ state }) => {
  const { t, i18n } = useTranslation();
  const styles = getStyles(i18n);

  const { invoiceNumber, logoURL: logoPath, date, dueDate } = state.InvoiceHeader;
  const logoURL = logoPath ? toAbsoluteLogoURL(logoPath) : null;

  return (
    <View style={styles.mainContainer}>
      <View style={styles.sideInfoContainer}>
        <View style={styles.invoiceNumberContainer}>
          <Text>{t('invoice.header.invoice')}</Text>
          <Text style={styles.invoiceNumberText}>
            &nbsp;
            #{invoiceNumber}
          </Text>
        </View>

        <Text style={styles.dateTitleText}>
          {t('invoice.header.date')}
        </Text>
        <Text>
          {moment(date).locale('en').format('YY/MM/DD')}
        </Text>
      </View>

      {logoURL && (
        <Image style={styles.logoImage} src={logoURL} />
      )}

      <View style={[styles.sideInfoContainer, styles.alignEnd]}>
        <Text style={styles.dateTitleText}>
          {t('invoice.header.due_date')}
        </Text>
        <Text>
          {moment(dueDate).locale('en').format('YY/MM/DD')}
        </Text>
      </View>
    </View>
  );
};

export default InvoiceTemplateHeader;