

const openShareWindow = (title: string, url: string) => {
  const width = 600;
  const height = 400;
  const left = (window.innerWidth - width) / 2;
  const top = (window.innerHeight - height) / 2;

  const popupSettings = `width=${width},height=${height},left=${left},top=${top},scrollbars=no,resizable=no,menubar=no,toolbar=no,location=no,status=no`;

  window.open(url, title, popupSettings);
};

export const shareToTwitter = (url: string) => {
  const socialURL = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`;
  openShareWindow('Share to Twitter', socialURL);
};

export const shareToFacebook = (url: string) => {
  const socialURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
  openShareWindow('Share to Facebook', socialURL);
};

export const shareToTelegram = (url: string) => {
  const socialURL = `https://t.me/share/url?url=${encodeURIComponent(url)}`;
  openShareWindow('Share to Telegram', socialURL);
};

export const shareToWhatsApp = (url: string) => {
  const socialURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`;
  openShareWindow('Share to WhatsApp', socialURL);
};