import { Dispatch, SetStateAction, useEffect } from 'react';

import { t } from 'i18next';
import { Modal, ModalProps } from 'antd';
import { Actions } from '../../../state/actions';
import { IInvoiceState } from '../../../state/state';
import useConfirmInvoiceClearModal from '../../../Hooks/invoice/confirm-invoice-clear-modal.hook';

import { Warning } from 'phosphor-react';

export type ConfirmInvoiceClearModalProps = ModalProps & {
  state: { value: IInvoiceState, dispatch: React.Dispatch<Actions>; };
  resetModalTrigger: { value: string | undefined; set: Dispatch<SetStateAction<string | undefined>>; };
};

const ConfirmInvoiceClear = (props: ConfirmInvoiceClearModalProps) => {
  const { handleOk, handleClose } = useConfirmInvoiceClearModal(props);
  const { resetModalTrigger, ...modalProps } = props;

  return (
    <Modal
      className='modal confirm-invoice-reset-modal'
      title={
        <>
          <Warning size={40} />
          <h1>{t('invoice.confirm_invoice_reset_modal.title')}</h1>
        </>
      }
      okText={t('invoice.confirm_invoice_reset_modal.button_done')}
      okType='danger'
      cancelText={t('invoice.confirm_invoice_reset_modal.button_cancel')}
      onOk={handleOk}
      onCancel={handleClose}
      open={resetModalTrigger.value != undefined}
      centered
      {...modalProps}
    >
      <p>{t('invoice.confirm_invoice_reset_modal.description')}</p>
    </Modal>
  );
};

export default ConfirmInvoiceClear;