export enum Currency {
  ALL,
  AFN,
  ARS,
  AWG,
  AUD,
  AZN,
  BSD,
  BBD,
  BYN,
  BZD,
  BMD,
  BOB,
  BAM,
  BWP,
  BGN,
  BRL,
  BND,
  KHR,
  CAD,
  KYD,
  CLP,
  CNY,
  COP,
  CRC,
  HRK,
  CUP,
  CZK,
  DKK,
  DOP,
  XCD,
  EGP,
  SVC,
  EUR,
  FKP,
  FJD,
  GHS,
  GIP,
  GTQ,
  GGP,
  GYD,
  HNL,
  HKD,
  HUF,
  ISK,
  INR,
  IDR,
  IRR,
  IMP,
  ILS,
  JMD,
  JPY,
  JEP,
  KZT,
  KPW,
  KGS,
  LAK,
  LBP,
  LRD,
  MKD,
  MYR,
  MUR,
  MXN,
  MNT,
  MZN,
  NAD,
  NPR,
  ANG,
  NZD,
  NIO,
  NGN,
  NOK,
  OMR,
  PKR,
  PAB,
  PYG,
  PEN,
  PHP,
  PLN,
  QAR,
  RON,
  RUB,
  SHP,
  SAR,
  RSD,
  SCR,
  SGD,
  SBD,
  SOS,
  KRW,
  ZAR,
  LKR,
  SEK,
  CHF,
  SRD,
  SYP,
  TWD,
  THB,
  TTD,
  TRY,
  TVD,
  UAH,
  AED,
  GBP,
  USD,
  UYU,
  UZS,
  VEF,
  VND,
  YER,
  ZWD,
  BDT,
  BIF,
  CDF,
  DJF,
  ETB,
  GNF,
  HTG,
  IQD,
  JOD,
  KES,
  KMF,
  KWD,
  LYD,
  MMK,
  MVR,
  MWK,
  MGA,
  RWF,
  SDG,
  SLL,
  SSP,
  SZL,
  TJS,
  TMT,
  TND,
  TOP,
  TZS,
  UGX,
  VUV,
  WST,
  XAF,
  XOF,
  XPF,
  ZMW
}

export const CurrencyInfo = {
  [Currency.ALL]: {
    code: 'ALL',
    symbol: 'Lek'
  },
  [Currency.AFN]: {
    code: 'AFN',
    symbol: '؋'
  },
  [Currency.ARS]: {
    code: 'ARS',
    symbol: '$'
  },
  [Currency.AWG]: {
    code: 'AWG',
    symbol: 'ƒ'
  },
  [Currency.AUD]: {
    code: 'AUD',
    symbol: '$'
  },
  [Currency.AZN]: {
    code: 'AZN',
    symbol: '₼'
  },
  [Currency.BSD]: {
    code: 'BSD',
    symbol: '$'
  },
  [Currency.BBD]: {
    code: 'BBD',
    symbol: '$'
  },
  [Currency.BYN]: {
    code: 'BYN',
    symbol: 'Br'
  },
  [Currency.BZD]: {
    code: 'BZD',
    symbol: 'BZ$'
  },
  [Currency.BMD]: {
    code: 'BMD',
    symbol: '$'
  },
  [Currency.BOB]: {
    code: 'BOB',
    symbol: '$b'
  },
  [Currency.BAM]: {
    code: 'BAM',
    symbol: 'KM'
  },
  [Currency.BWP]: {
    code: 'BWP',
    symbol: 'P'
  },
  [Currency.BGN]: {
    code: 'BGN',
    symbol: 'лв'
  },
  [Currency.BRL]: {
    code: 'BRL',
    symbol: 'R$'
  },
  [Currency.BND]: {
    code: 'BND',
    symbol: '$'
  },
  [Currency.KHR]: {
    code: 'KHR',
    symbol: '៛'
  },
  [Currency.CAD]: {
    code: 'CAD',
    symbol: '$'
  },
  [Currency.KYD]: {
    code: 'KYD',
    symbol: '$'
  },
  [Currency.CLP]: {
    code: 'CLP',
    symbol: '$'
  },
  [Currency.CNY]: {
    code: 'CNY',
    symbol: '¥'
  },
  [Currency.COP]: {
    code: 'COP',
    symbol: '$'
  },
  [Currency.CRC]: {
    code: 'CRC',
    symbol: '₡'
  },
  [Currency.HRK]: {
    code: 'HRK',
    symbol: 'kn'
  },
  [Currency.CUP]: {
    code: 'CUP',
    symbol: '₱'
  },
  [Currency.CZK]: {
    code: 'CZK',
    symbol: 'Kč'
  },
  [Currency.DKK]: {
    code: 'DKK',
    symbol: 'kr'
  },
  [Currency.DOP]: {
    code: 'DOP',
    symbol: 'RD$'
  },
  [Currency.XCD]: {
    code: 'XCD',
    symbol: '$'
  },
  [Currency.EGP]: {
    code: 'EGP',
    symbol: '£'
  },
  [Currency.SVC]: {
    code: 'SVC',
    symbol: '$'
  },
  [Currency.EUR]: {
    code: 'EUR',
    symbol: '€'
  },
  [Currency.FKP]: {
    code: 'FKP',
    symbol: '£'
  },
  [Currency.FJD]: {
    code: 'FJD',
    symbol: '$'
  },
  [Currency.GHS]: {
    code: 'GHS',
    symbol: '¢'
  },
  [Currency.GIP]: {
    code: 'GIP',
    symbol: '£'
  },
  [Currency.GTQ]: {
    code: 'GTQ',
    symbol: 'Q'
  },
  [Currency.GGP]: {
    code: 'GGP',
    symbol: '£'
  },
  [Currency.GYD]: {
    code: 'GYD',
    symbol: '$'
  },
  [Currency.HNL]: {
    code: 'HNL',
    symbol: 'L'
  },
  [Currency.HKD]: {
    code: 'HKD',
    symbol: '$'
  },
  [Currency.HUF]: {
    code: 'HUF',
    symbol: 'Ft'
  },
  [Currency.ISK]: {
    code: 'ISK',
    symbol: 'kr'
  },
  [Currency.INR]: {
    code: 'INR',
    symbol: '₹'
  },
  [Currency.IDR]: {
    code: 'IDR',
    symbol: 'Rp'
  },
  [Currency.IRR]: {
    code: 'IRR',
    symbol: '﷼'
  },
  [Currency.IMP]: {
    code: 'IMP',
    symbol: '£'
  },
  [Currency.ILS]: {
    code: 'ILS',
    symbol: '₪'
  },
  [Currency.JMD]: {
    code: 'JMD',
    symbol: 'J$'
  },
  [Currency.JPY]: {
    code: 'JPY',
    symbol: '¥'
  },
  [Currency.JEP]: {
    code: 'JEP',
    symbol: '£'
  },
  [Currency.KZT]: {
    code: 'KZT',
    symbol: 'лв'
  },
  [Currency.KPW]: {
    code: 'KPW',
    symbol: '₩'
  },
  [Currency.KGS]: {
    code: 'KGS',
    symbol: 'лв'
  },
  [Currency.LAK]: {
    code: 'LAK',
    symbol: '₭'
  },
  [Currency.LBP]: {
    code: 'LBP',
    symbol: '£'
  },
  [Currency.LRD]: {
    code: 'LRD',
    symbol: '$'
  },
  [Currency.MKD]: {
    code: 'MKD',
    symbol: 'ден'
  },
  [Currency.MYR]: {
    code: 'MYR',
    symbol: 'RM'
  },
  [Currency.MUR]: {
    code: 'MUR',
    symbol: '₨'
  },
  [Currency.MXN]: {
    code: 'MXN',
    symbol: '$'
  },
  [Currency.MNT]: {
    code: 'MNT',
    symbol: '₮'
  },
  [Currency.MZN]: {
    code: 'MZN',
    symbol: 'MT'
  },
  [Currency.NAD]: {
    code: 'NAD',
    symbol: '$'
  },
  [Currency.NPR]: {
    code: 'NPR',
    symbol: '₨'
  },
  [Currency.ANG]: {
    code: 'ANG',
    symbol: 'ƒ'
  },
  [Currency.NZD]: {
    code: 'NZD',
    symbol: '$'
  },
  [Currency.NIO]: {
    code: 'NIO',
    symbol: 'C$'
  },
  [Currency.NGN]: {
    code: 'NGN',
    symbol: '₦'
  },
  [Currency.NOK]: {
    code: 'NOK',
    symbol: 'kr'
  },
  [Currency.OMR]: {
    code: 'OMR',
    symbol: '﷼'
  },
  [Currency.PKR]: {
    code: 'PKR',
    symbol: '₨'
  },
  [Currency.PAB]: {
    code: 'PAB',
    symbol: 'B/.'
  },
  [Currency.PYG]: {
    code: 'PYG',
    symbol: 'Gs'
  },
  [Currency.PEN]: {
    code: 'PEN',
    symbol: 'S/.'
  },
  [Currency.PHP]: {
    code: 'PHP',
    symbol: '₱'
  },
  [Currency.PLN]: {
    code: 'PLN',
    symbol: 'zł'
  },
  [Currency.QAR]: {
    code: 'QAR',
    symbol: '﷼'
  },
  [Currency.RON]: {
    code: 'RON',
    symbol: 'lei'
  },
  [Currency.RUB]: {
    code: 'RUB',
    symbol: '₽'
  },
  [Currency.SHP]: {
    code: 'SHP',
    symbol: '£'
  },
  [Currency.SAR]: {
    code: 'SAR',
    symbol: '﷼'
  },
  [Currency.RSD]: {
    code: 'RSD',
    symbol: 'Дин.'
  },
  [Currency.SCR]: {
    code: 'SCR',
    symbol: '₨'
  },
  [Currency.SGD]: {
    code: 'SGD',
    symbol: '$'
  },
  [Currency.SBD]: {
    code: 'SBD',
    symbol: '$'
  },
  [Currency.SOS]: {
    code: 'SOS',
    symbol: 'S'
  },
  [Currency.KRW]: {
    code: 'KRW',
    symbol: '₩'
  },
  [Currency.ZAR]: {
    code: 'ZAR',
    symbol: 'R'
  },
  [Currency.LKR]: {
    code: 'LKR',
    symbol: '₨'
  },
  [Currency.SEK]: {
    code: 'SEK',
    symbol: 'kr'
  },
  [Currency.CHF]: {
    code: 'CHF',
    symbol: 'CHF'
  },
  [Currency.SRD]: {
    code: 'SRD',
    symbol: '$'
  },
  [Currency.SYP]: {
    code: 'SYP',
    symbol: '£'
  },
  [Currency.TWD]: {
    code: 'TWD',
    symbol: 'NT$'
  },
  [Currency.THB]: {
    code: 'THB',
    symbol: '฿'
  },
  [Currency.TTD]: {
    code: 'TTD',
    symbol: 'TT$'
  },
  [Currency.TRY]: {
    code: 'TRY',
    symbol: '₺'
  },
  [Currency.TVD]: {
    code: 'TVD',
    symbol: '$'
  },
  [Currency.UAH]: {
    code: 'UAH',
    symbol: '₴'
  },
  [Currency.AED]: {
    code: 'AED',
    symbol: ' د.إ'
  },
  [Currency.GBP]: {
    code: 'GBP',
    symbol: '£'
  },
  [Currency.USD]: {
    code: 'USD',
    symbol: '$'
  },
  [Currency.UYU]: {
    code: 'UYU',
    symbol: '$U'
  },
  [Currency.UZS]: {
    code: 'UZS',
    symbol: 'лв'
  },
  [Currency.VEF]: {
    code: 'VEF',
    symbol: 'Bs'
  },
  [Currency.VND]: {
    code: 'VND',
    symbol: '₫'
  },
  [Currency.YER]: {
    code: 'YER',
    symbol: '﷼'
  },
  [Currency.ZWD]: {
    code: 'ZWD',
    symbol: 'Z$'
  },
  [Currency.BDT]: {
    code: 'BDT',
    symbol: '৳'
  },
  [Currency.BIF]: {
    code: 'BIF',
    symbol: '₣'
  },
  [Currency.CDF]: {
    code: 'CDF',
    symbol: '₣'
  },
  [Currency.DJF]: {
    code: 'DJF',
    symbol: '₣'
  },
  [Currency.ETB]: {
    code: 'ETB',
    symbol: 'Br'
  },
  [Currency.GNF]: {
    code: 'GNF',
    symbol: '₣'
  },
  [Currency.HTG]: {
    code: 'HTG',
    symbol: 'G'
  },
  [Currency.IQD]: {
    code: 'IQD',
    symbol: 'ع.د'
  },
  [Currency.JOD]: {
    code: 'JOD',
    symbol: 'د.ا'
  },
  [Currency.KES]: {
    code: 'KES',
    symbol: 'Sh'
  },
  [Currency.KMF]: {
    code: 'KMF',
    symbol: '₣'
  },
  [Currency.KWD]: {
    code: 'KWD',
    symbol: 'د.ك'
  },
  [Currency.LYD]: {
    code: 'LYD',
    symbol: 'ل.د'
  },
  [Currency.MGA]: {
    code: 'MGA',
    symbol: 'Ar'
  },
  [Currency.MMK]: {
    code: 'MMK',
    symbol: 'Ks'
  },
  [Currency.MVR]: {
    code: 'MVR',
    symbol: '.ރ'
  },
  [Currency.MWK]: {
    code: 'MWK',
    symbol: 'MK'
  },
  [Currency.RWF]: {
    code: 'RWF',
    symbol: '₣'
  },
  [Currency.SDG]: {
    code: 'SDG',
    symbol: 'ج.س'
  },
  [Currency.SLL]: {
    code: 'SLL',
    symbol: 'Le'
  },
  [Currency.SSP]: {
    code: 'SSP',
    symbol: '£'
  },
  [Currency.SZL]: {
    code: 'SZL',
    symbol: 'L'
  },
  [Currency.TJS]: {
    code: 'TJS',
    symbol: 'ЅМ'
  },
  [Currency.TMT]: {
    code: 'TMT',
    symbol: 'm'
  },
  [Currency.TND]: {
    code: 'TND',
    symbol: 'د.ت'
  },
  [Currency.TOP]: {
    code: 'TOP',
    symbol: 'T$'
  },
  [Currency.TZS]: {
    code: 'TZS',
    symbol: 'Sh'
  },
  [Currency.UGX]: {
    code: 'UGX',
    symbol: 'Sh'
  },
  [Currency.VUV]: {
    code: 'VUV',
    symbol: 'Vt'
  },
  [Currency.WST]: {
    code: 'WST',
    symbol: 'T'
  },
  [Currency.XAF]: {
    code: 'XAF',
    symbol: '₣'
  },
  [Currency.XOF]: {
    code: 'XOF',
    symbol: '₣'
  },
  [Currency.XPF]: {
    code: 'XPF',
    symbol: '₣'
  },
  [Currency.ZMW]: {
    code: 'ZMW',
    symbol: 'ZK'
  }
};