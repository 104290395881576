import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Breadcrumb, Button } from 'antd';

export interface IBreadcrumbItemProps {
  title?: string | React.ReactElement;
  route?: string;
  custom?: boolean;
}

const BreadcrumbItem: FC<IBreadcrumbItemProps> = ({ title, route, custom }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigation = () => {
    if (route) {
      navigate(route);
    }
  };

  return (
    <Breadcrumb.Item>
      <Button disabled={!route} type="text" onClick={handleNavigation}>
        {title && (custom ? title : t(`breadcrumb.${title}`))}
      </Button>
    </Breadcrumb.Item>
  );
};

export default BreadcrumbItem;