import { CSSProperties } from 'react';

const cardStyle: CSSProperties = {
  width: 280,
  height: 194.5,
  marginTop: 16,
  borderRadius: 15,
  opacity: .4,
};

export default { cardStyle } as const;