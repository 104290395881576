import { i18n } from "i18next";
import { Currency, CurrencyInfo } from "../@types/currency";

export const formatCurrency = (amount: number, currency?: Currency, locale?: i18n): string => {
  const formattedAmount = amount.toLocaleString(locale?.language || 'en-US', {
    maximumFractionDigits: 2,
  });

  const symbol = currency ? CurrencyInfo[currency].symbol : '';

  return locale?.dir() === 'rtl' ? `${formattedAmount}${symbol}` : `${symbol}${formattedAmount}`;
};