import './project-settings.less';
import { Form, Spin } from 'antd';
import useProjectSettings from '../../Hooks/project-settings/project-settings.hook';

import PageHeader from '../../Components/shared/page-header/page-header.component';
import ProjectsListNavigator from '../../Components/project-settings/projects-list-navigator/projects-list-navigator.component';
import ProjectImageUpload from '../../Components/project-settings/project-image-upload/project-image-upload.component';
import ProjectDefaultValues from '../../Components/project-settings/project-default-values/project-default-values.component';

const ProjectSettingsPage = () => {
  const { states, params, form, formHandlers, t } = useProjectSettings();

  const { isLoading } = states;
  const { submitHandler, handleFinishFailed } = formHandlers;

  return (
    <div className="projects-settings-wrapper">
      <PageHeader text={t('project_settings.page_title')} />

      <div className="content">
        <ProjectsListNavigator />
        <Spin spinning={isLoading.value}>
          <Form
            form={form}
            className='project-settings-form'
            onFinish={submitHandler}
            onFinishFailed={handleFinishFailed}
            disabled={isLoading.value}
            layout="vertical"
            requiredMark={false}
          >
            <ProjectImageUpload form={form} states={states} params={params} />
            <ProjectDefaultValues form={form} states={states} params={params} formHandlers={formHandlers} />
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default ProjectSettingsPage;
