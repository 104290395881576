import { useState } from 'react';
import { APIResponse } from '../@types/responses';

function usePagination<T>(pageSize: number, buildingFunction: any) {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<{ loading: boolean, data: T[]; }>({ loading: true, data: [] });
  const [totalItems, setTotalItems] = useState(0);

  const fetchData = async (): Promise<T[]> => {
    setData((prev) => ({ ...prev, loading: true }));
    const response: APIResponse.ListDataResponse<T> = await buildingFunction(currentPage, pageSize);

    if (response) {
      if (response.total && !response.dataList.length && currentPage > 1) {
        setCurrentPage((prevCurrentPage) => prevCurrentPage - 1);
        return [];
      }

      setData({ loading: false, data: response.dataList });
      setTotalItems(response.total);
      return response.dataList;
    }

    return [];
  };

  return {
    data: { value: data, set: setData },
    currentPage: { value: currentPage, set: setCurrentPage },
    totalItems: { value: totalItems, set: setTotalItems },
    functions: { fetchData },
    constants: { pageSize }
  };
}

export default usePagination;
