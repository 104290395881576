import React from 'react';
import './project-controls.less';
import { NSProject } from '../../../@types/project';
import { useTranslation } from "react-i18next";

import { DatePicker, Input } from "antd";
import { SearchOutlined } from '@ant-design/icons';

interface IProps {
  params: NSProject.Params;
}

export type RangeValue = Parameters<NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>['onChange']>>[0];

const ProjectControls = ({ params }: IProps) => {
  const { t } = useTranslation();
  const { search } = params;

  return (
    <div className="controls">
      <div className="filters">
        <Input
          className="search"
          suffix={<SearchOutlined color="red" />}
          placeholder={t('project_list.search_placeholder')}
          value={search.value}
          onChange={e => { search?.set(e.target.value); }}
        />
      </div>
    </div>
  );
};

export default ProjectControls;
