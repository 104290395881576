import { StyleSheet } from '@react-pdf/renderer';

const getStyles = () => {
  return StyleSheet.create({
    mainContainer: {
      fontSize: 10,
      paddingTop: 20
    },
    notesTitleText: {
      fontWeight: 'medium'
    },
    notesContainer: {
      marginTop: 3
    }
  } as const);
};

export default getStyles;