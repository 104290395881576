import './invoice-controls.less';
import { NSInvoiceList, NSInvoice } from '../../../@types/invoice';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  Button, DatePicker, Input, Radio, Select
} from 'antd';
import { SearchOutlined, FilterOutlined, CalendarOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { Cards, Table, Trash } from 'phosphor-react';

interface IProps {
  states: NSInvoiceList.States;
  params: NSInvoiceList.Params;
  functions: NSInvoiceList.Functions;
  allowSorting?: boolean;
}

export type RangeValue = Parameters<NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>['onChange']>>[0];

const InvoiceControls = ({ states, params, functions, allowSorting }: IProps) => {
  const { t } = useTranslation();
  const { selectedIds, pageView } = states;
  const { search, status, dates, sortedBy } = params;
  const { RangePicker } = DatePicker;

  const updateDate = (datesRange: RangeValue) => {
    if (!datesRange) {
      dates.set(undefined);
      return;
    }

    const [start, end] = datesRange;

    dates.set(
      start && end
        ? [start.locale('en').format('YYYY-MM-DD'), end.locale('en').format('YYYY-MM-DD')]
        : undefined
    );
  };

  return (
    <div className="controls">
      <Input
        className="search"
        suffix={<SearchOutlined color="red" />}
        placeholder={t('invoice_list.search_placeholder')}
        value={search.value}
        onChange={(e) => { search?.set(e.target.value); }}
      />
      <div className="input-group date-picker">
        <span className="placeholder">{t('invoice_list.date_placeholder')}</span>
        <RangePicker
          className="date-picker"
          suffixIcon={<CalendarOutlined className="icon" />}
          value={dates.value?.length ? [moment(dates.value[0]), moment(dates.value[1])] : null}
          onChange={updateDate}
          allowClear
        />
      </div>
      <Select
        className="select"
        placeholder={t('invoice_list.status_placeholder')}
        suffixIcon={<FilterOutlined className="icon" />}
        style={{ width: 150 }}
        options={
          Object.keys(NSInvoice.InvoiceStatus).map((status) => (
            { label: t(`invoice_list.status.${status}`), value: status }
          ))
        }
        value={status.value || null}
        onChange={(value) => { status.set(value as NSInvoice.InvoiceStatus); }}
        allowClear
      />
      {allowSorting && (
        <Select
          className="select"
          placeholder={t('invoice_list.sort_placeholder')}
          suffixIcon={<SortDescendingOutlined className="icon" />}
          style={{ width: 150 }}
          options={
            NSInvoice.InvoiceSortedByArray.map((sortedBy) => (
              { label: t(`invoice_list.sortedBy.${sortedBy}`), value: sortedBy }
            ))
          }
          value={sortedBy.value}
          onChange={(value) => { sortedBy.set(value as NSInvoice.InvoiceSortedBy); }}
        />
      )}
      {!!selectedIds.value.length
        && <Button
          className="delete-btn"
          onClick={() => functions.showDeleteConfirm(selectedIds.value)}
        >
          <Trash size={20} />
        </Button>}
      <Radio.Group className="page-view-selector" value={pageView.value} onChange={(e) => { pageView.set(e.target.value); }}>
        <Radio.Button className='table' value={NSInvoice.ViewPreference.TABLE}>
          <Table size={19} />
          {t('invoice_list.page_view.table')}
        </Radio.Button>
        <Radio.Button className='cards' value={NSInvoice.ViewPreference.CARDS}>
          <Cards size={19} />
          {t('invoice_list.page_view.cards')}
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};

export default InvoiceControls;
