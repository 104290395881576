import React from "react";
import { NSInvoice } from "../../@types/invoice";
import { Actions, ActionTypes, InvoiceRightRail } from "../../state/actions";
import { IInvoiceState } from "../../state/state";
import { ACTION_BUTTON_TYPE } from "./invoice-template/pdf-download/pdf-download.component";
import { Currency, CurrencyInfo } from "../../@types/currency";
import useRightRailForm from "../../Hooks/invoice/right-rail-form.hook";

import { Button, Form, Select } from "antd";
import { SaveFilled } from "@ant-design/icons";
import RestrictedActionTooltip from "../shared/restricted-action-tooltip/restricted-action-tooltip.component";
import SendInvoiceModal from "./send-invoice-modal/send-invoice-modal.component";
import PDFDownloadButton from "./invoice-template/pdf-download/pdf-download.component";
import PDFView from "./invoice-template/pdf-view/pdf-view.component";
import ConfirmProjectSwitchModal from "./confirm-project-switch-modal/confirm-project-switch-modal.component";
import { ArrowsClockwise } from "phosphor-react";
import ConfirmInvoiceClear from "./confirm-invoice-clear/confirm-invoice-clear.component";

export interface RightRailFormProps {
  state: { value: IInvoiceState, dispatch: React.Dispatch<Actions>; };
  states: NSInvoice.States;
  invoiceHandlers: NSInvoice.InvoiceHandlers;
}

const RightRailForm = (props: RightRailFormProps) => {
  const { states, invoiceHandlers, contexts, form, t } = useRightRailForm(props);

  const { shareModalOpen, switchProjectModal, previewLink, clearModalTrigger } = states;
  const { saveInvoice, showSendInvoice, clearInvoice } = invoiceHandlers;
  const { userCtx, authCtx } = contexts;
  const project = Form.useWatch('project', form);

  return (<>
    <SendInvoiceModal open={shareModalOpen.value} state={props.state.value} shareModalOpen={shareModalOpen} />
    <ConfirmInvoiceClear state={props.state} resetModalTrigger={clearModalTrigger} />
    <ConfirmProjectSwitchModal state={props.state} switchProjectModal={switchProjectModal} />
    <PDFView previewLink={previewLink} />
    <div className="right-col-wrapper">
      <Form
        form={form}
        disabled={props.states.isLoading.value}
        className="right-col"
        initialValues={props.state.value.InvoiceRightRail}
        name="right-rail"
        onValuesChange={(_: InvoiceRightRail, values: InvoiceRightRail) =>
          props.state.dispatch({
            type: ActionTypes.UPDATE_RIGHT_RAIL,
            payload: values
          })
        }
      >
        <Form.Item name="project" hidden />
        <div className="top-row">
          {authCtx.isAuthenticated && (
            <div>
              <p>{t('invoice_actions.project')}</p>
              <Select
                value={project}
                onSelect={((projectId) => { switchProjectModal.set(projectId); })}
                showSearch
                optionFilterProp="children"
                defaultValue="default"
                filterOption={(input, option) => (
                  option?.label.toLowerCase().includes(input.toLowerCase())!
                )}
                options={userCtx.projects.value.map(project => ({
                  label: project.name,
                  value: project._id
                }))}
              />
            </div>
          )}
          <div>
            <p>{t('invoice_actions.currency')}</p>
            <Form.Item name="currency">
              <Select
                showSearch
                defaultActiveFirstOption={true}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  const foundLabel = option?.label.toLowerCase().includes(input.toLowerCase()) as boolean;
                  const foundSymbol = input === CurrencyInfo[option?.value as unknown as Currency].symbol;
                  return (foundSymbol || foundLabel);
                }}
                options={Object.keys(CurrencyInfo).map((currency) => {
                  const currencyInfo = CurrencyInfo[currency as unknown as Currency];
                  return { label: `${currencyInfo?.code} ${currencyInfo?.symbol}`, value: parseInt(currency) };
                })}
              />
            </Form.Item>
          </div>
        </div>
        <div className="border" />
        <div className="mid-row">
          <div className="btns-group">
            <RestrictedActionTooltip restrict={authCtx.isGuest}>
              <Button className="send-btn" onClick={showSendInvoice}>{t('invoice_actions.send_invoice')}</Button>
            </RestrictedActionTooltip>
            <RestrictedActionTooltip restrict={authCtx.isGuest}>
              <Button
                className="save-btn"
                onClick={saveInvoice}
                icon={<SaveFilled />}
                loading={props.states.isSaving.value}
              />
            </RestrictedActionTooltip>
          </div>

          <div className="btns-group">
            <PDFDownloadButton state={props.state.value}
              actionButtonType={ACTION_BUTTON_TYPE.PREVIEW}
              previewLink={previewLink} />
            <PDFDownloadButton state={props.state.value} />
          </div>

          <Button onClick={clearInvoice} icon={<ArrowsClockwise size={18} />}>
            {t('invoice.utils.reset_invoice')}
          </Button>
        </div>
      </Form>
    </div>
  </>);
};

export default RightRailForm;