import { useEffect, useRef } from 'react';
import './pdf-view.less';
import { pdfjs } from 'react-pdf';

import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Printer } from 'phosphor-react';

interface IProps {
  previewLink: { value: string | null, set: React.Dispatch<React.SetStateAction<string | null>>; };
}

const PDFView = ({ previewLink }: IProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.7.107/pdf.worker.js';
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        previewLink.set(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [previewLink.value]);

  const handlePrint = () => {
    window.print();
  };

  return <>
    {previewLink.value !== null &&
      <div className="pdf-view-overlay">
        <div ref={containerRef} className="pdf-view-container">
          <button onClick={handlePrint}><Printer size={25} /></button>
          <Worker workerUrl={pdfjs.GlobalWorkerOptions.workerSrc}>
            <Viewer fileUrl={previewLink.value} />
          </Worker>
        </div>
      </div>
    }
  </>;
};

export default PDFView;
