import './nav-button.less';
import { Link, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../../store/auth/auth.context';
import classNames from 'classnames';

import { Button } from 'antd';
import RestrictedActionTooltip from '../../shared/restricted-action-tooltip/restricted-action-tooltip.component';

interface IProps {
  to: string;
  relatedPaths?: string[];
  text: string;
  authenticationRequired?: boolean;
  queryParams?: string;
  closeDrawer?: () => void;
}

const NavButton = ({ to, text, authenticationRequired, queryParams, relatedPaths = [], closeDrawer }: IProps) => {
  const authContext = useAuthContext();
  const location = useLocation();
  const pathname = location.pathname;
  const restrict = !!authenticationRequired && !authContext?.isAuthenticated;

  return <RestrictedActionTooltip restrict={restrict}>
    <Button className={classNames({ selected: [to, ...relatedPaths].some(path => pathname.includes(path)) })} type="default">
      <Link onClick={(e) => { restrict && e.preventDefault(); closeDrawer?.(); }} to={`${to}${queryParams ?? ''}`}>{text}</Link>
    </Button>
  </RestrictedActionTooltip>;
};

export default NavButton;