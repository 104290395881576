import React from 'react';
import { NSInvoice } from '../../@types/invoice';
import { Actions, ActionTypes, InvoiceFooter } from '../../state/actions';
import { IInvoiceState } from '../../state/state';
import useControlledForm from '../../Hooks/controlled-form.hook';
import { useTranslation } from 'react-i18next';
import { useWatch } from 'antd/lib/form/Form';

import { Form } from 'antd';
import EditableTextArea from '../shared/inplace-controlled-field/editable-textarea.component';

interface IProps {
  state: { value: IInvoiceState, dispatch: React.Dispatch<Actions>; };
  states: NSInvoice.States;
};

const InvoiceFooterForm = (props: IProps) => {
  const { t } = useTranslation();
  const { form } = useControlledForm<InvoiceFooter>(props.state.value.InvoiceFooter ?? {});
  const notes = useWatch("notes", form);

  return (
    <Form
      form={form}
      disabled={props.states.isLoading.value}
      labelAlign='left'
      name="invoice-footer"
      onValuesChange={(changedValues: InvoiceFooter, values: InvoiceFooter) => props.state.dispatch({
        type: ActionTypes.UPDATE_FOOTER,
        payload: values
      })}
    >
      <div className="notes__sec">
        <span>{t('invoice.notes.title')}</span>
        <Form.Item name={'notes'}>
          <EditableTextArea
            name="notes"
            placeholder={t('invoice.notes.placeholder')}
            className="text-input"
            rows={2}
          />
        </Form.Item>
        <p className="print-helper">{notes}</p>
      </div>
    </Form>
  );
};

export default InvoiceFooterForm;
