import { Dispatch, SetStateAction } from 'react';
import './send-invoice-modal.less';
import { IInvoiceState } from '../../../state/state';
import { shareToFacebook, shareToTelegram, shareToTwitter, shareToWhatsApp } from '../../../util/social-media-share';
import useSendInvoiceModal from '../../../Hooks/invoice/send-invoice-modal.hook';

import { FormOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, ModalProps, Select } from 'antd';
import { Copy, FacebookLogo, TelegramLogo, TwitterLogo, WhatsappLogo } from 'phosphor-react';
import TextArea from 'antd/lib/input/TextArea';

type SendInvoiceModal = ModalProps & {
  shareModalOpen: { set: Dispatch<SetStateAction<boolean>>; };
  state: IInvoiceState;
};

const SendInvoiceModal = (props: SendInvoiceModal) => {
  const { form, states, functions, t } = useSendInvoiceModal(props);

  const { isAttachMessageShown } = states;
  const { handleSubmit, copyLink, handleShareButton } = functions;
  const { state, shareModalOpen, ...modalProps } = props;

  return (
    <Modal
      className='modal send-invoice-modal'
      title={<>
        <h1>{t('invoice.share_modal.title')}</h1>
        <p>{t('invoice.share_modal.description')}</p>
      </>}
      centered
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={<>
        <Button icon={<Copy size={18} />} onClick={copyLink}>{t('invoice.share_modal.button_copy')}</Button>
        <Button type='primary' onClick={() => { shareModalOpen.set(false); }}>{t('invoice.share_modal.button_done')}</Button>
      </>}
      onCancel={() => { shareModalOpen.set(false); }}
      {...modalProps}
      forceRender
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout='vertical'
      >
        <div className='send-emails-container'>
          <Form.Item
            name="recipients"
            label={t('invoice.share_modal.email_section.title')}
            className='send-emails'
            rules={[
              {
                validator: (_, value) => {
                  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
                  if (value?.every((email: string) => emailPattern.test(email))) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Please enter valid email addresses!');
                }
              }
            ]}
          >
            <Select
              mode="tags"
              placeholder={t('invoice.share_modal.email_section.email_placeholder')}
              tokenSeparators={[',']}
              open={false}
            >
            </Select>
          </Form.Item>
          <div className='actions-container'>
            <Button title={t('invoice.share_modal.email_section.button_extend_title')} onClick={isAttachMessageShown.toggle} icon={<FormOutlined />}></Button>
            <Button htmlType='submit' className='send-btn'>{t('invoice.share_modal.email_section.button_send')}</Button>
          </div>
        </div>

        <Form.Item
          name="subject"
          className='email-content'
          hidden={!isAttachMessageShown.value}
        >
          <Input
            placeholder={t('invoice.share_modal.email_section.subject_placeholder')}
          />
        </Form.Item>

        <Form.Item
          name="body"
          className='email-content'
          hidden={!isAttachMessageShown.value}
        >
          <TextArea
            placeholder={t('invoice.share_modal.email_section.message_placeholder')}
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>
      </Form>

      <div className="social-media-container">
        <div className="buttons-container">
          <Button
            title={t('invoice.share_modal.social_section.twitter')}
            onClick={() => { handleShareButton(shareToTwitter); }}
            className='social-media-button'
          >
            <TwitterLogo className='twitter' size={26} />
          </Button>
          <Button
            title={t('invoice.share_modal.social_section.whatsapp')}
            onClick={() => { handleShareButton(shareToWhatsApp); }}
            className='social-media-button'
          >
            <WhatsappLogo className='whatsapp' size={26} />
          </Button>
          <Button
            title={t('invoice.share_modal.social_section.facebook')}
            onClick={() => { handleShareButton(shareToFacebook); }}
            className='social-media-button'
          >
            <FacebookLogo className='facebook' size={26} />
          </Button>
          <Button
            title={t('invoice.share_modal.social_section.telegram')}
            onClick={() => { handleShareButton(shareToTelegram); }}
            className='social-media-button'
          >
            <TelegramLogo className='telegram' size={26} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SendInvoiceModal;