import React, { useState, MouseEvent } from 'react';
import classNames from 'classnames';
import './tooltip.less';
import { useTranslation } from 'react-i18next';

import { ButtonProps, Tooltip as AntToolTip, TooltipProps } from 'antd';

type IProps = TooltipProps & {
  restrict?: boolean;
  defaultOpen?: boolean;
  open?: boolean;
  children: React.ReactElement<ButtonProps>;
};

const Tooltip: React.FC<IProps> = ({ restrict = false, defaultOpen, open, children, ...props }) => {
  const { i18n } = useTranslation();
  const [isTooltipOpen, setTooltipOpen] = useState(defaultOpen);

  const updateTooltipOpen = (value: boolean) => {
    if (!value) {
      setTimeout(() => setTooltipOpen(value), 200);
      return;
    }

    setTooltipOpen(value);
  };

  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const modifiedChild = React.cloneElement(children, {
    onClick: handleButtonClick,
  });

  return <AntToolTip
    title
    getPopupContainer={triggerNode => (triggerNode.parentElement ?? document.body)}
    className={classNames({ 'tooltip-open': isTooltipOpen })}
    onOpenChange={updateTooltipOpen}
    open={isTooltipOpen && open}
    placement={i18n.dir() === 'rtl' ? 'bottomLeft' : 'bottomRight'}
    {...props}
  >
    {restrict ? modifiedChild : children}
  </AntToolTip>;
};

export default Tooltip;