import { NSInvoiceList } from '../../../@types/invoice';
import './invoice-cards-view.less';
import InvoiceCardWrapper from '../invoice-cards-wrapper/invoice-cards-wrapper.component';

interface IProps {
  states: NSInvoiceList.States;
  functions: NSInvoiceList.Functions;
}

const InvoiceCardsView: React.FC<IProps> = ({ states, functions }) => {
  return (
    <InvoiceCardWrapper states={states} functions={functions} />
  );
};

export default InvoiceCardsView;
