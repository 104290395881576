import { useEffect, useState } from "react";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
  UploadProps
} from "antd/lib/upload";
import { message } from "antd";
import { settings } from "../settings";
import { APIResponse } from "../@types/responses";
import invoiceService from "../Services/invoice.service";
import { useTranslation } from 'react-i18next';
import { useAuthContext } from "../store/auth/auth.context";
import { toAbsoluteLogoURL } from "../util/get-logo-endpoint";

/**
 * Use this hook for managing image uploads
 * 
 * @param logoPath Optional path to an existing image on the server for immediate preview.
 * @param callback Optional function to run after upload or delete actions.
 */
export const useUpload = (
  logoPath?: string | null,
  callback?: (res?: APIResponse.IPostUploadedFile) => void,
  logoBase64?: UploadFile | null,
  base64Callback?: (res?: UploadFile) => void,
  saveBase64?: boolean,
) => {
  const { t } = useTranslation();
  const [uploadState, setUploadState] = useState({
    error: null as unknown,
    loading: false,
  });
  const [imageElement, setImageElement] = useState<HTMLImageElement | null>(null);
  const authContext = useAuthContext();
  const logoURL = logoPath ? toAbsoluteLogoURL(logoPath) : null;
  const isImageLoaded = (logoURL || logoBase64) && imageElement !== null;

  const loadImageSrcFromURL = (imageSrc: string) => {
    const image = new Image();
    image.src = imageSrc;

    image.onload = () => {
      image.onload = null;
      setImageElement(image);
    };

    image.onerror = ev => {
      image.onerror = null;
      message.error('Something went wrong while displaying the logo');
      console.error(ev);
      setImageElement(null);

      if (authContext.isGuest) {
        invoiceService.resetLocalImage();
      }
    };
  };

  useEffect(() => {
    if (logoBase64?.url) {
      loadImageSrcFromURL(logoBase64.url);
    } else if (logoURL) {
      loadImageSrcFromURL(logoURL);
    }
  }, [logoBase64, logoPath]);

  const beforeUploadHandler = (file: RcFile) => {
    if (!file.type.startsWith("image/")) {
      message.destroy();
      message.error(t('messages.image_formats_only'));
      return false;
    }

    if (file.size >= settings().INVOICE_LOGO_SIZE) {
      message.destroy();
      message.error(t('messages.size_smaller'));
      return false;
    }

    if (saveBase64) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        let result: string | null;

        if (reader.result instanceof ArrayBuffer) {
          const decoder = new TextDecoder();
          result = decoder.decode(reader.result);
        } else {
          result = reader.result;
        }
        base64Callback?.({ ...file, name: file.name, type: file.type, url: result ?? undefined });
        setUploadState(state => ({ ...state, error: null, URL: null, loading: false }));
        message.info(t('messages.image_upload_locally'));
      };

      reader.onerror = function () {
        setUploadState(state => ({ ...state, loading: false, error: true }));
        message.error(t('messages.image_upload_error'));
      };
      return false;
    }
  };

  type Res = APIResponse.IPostUploadedFile | undefined | null;

  const changeHandler: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile<Res>>
  ) => {
    const { response } = info.file;
    const error: unknown = info.file.error;
    const status: UploadFile['status'] = error ? 'error' : info.file.status;

    switch (status) {
      case 'uploading':
        if (uploadState.loading)
          return;
        setUploadState(currentState => ({ ...currentState, loading: true, error: null }));
        callback?.();
        message.destroy();
        message.loading(t('messages.uploading'));
        return;

      case 'success':
        return;

      case 'removed':
        // FIXME: This state is unreachable, it is not triggered on image removal.
        return;

      case 'error':
        setUploadState(state => ({ ...state, loading: false, error: error, URL: null }));
        message.destroy();
        message.error(t('messages.network_error'));
        return;

      case 'done': {
        if (!(typeof response === "object" && response && 'path' in response && '_id' in response)) {
          message.error(t('messages.network_error'));
          return;
        }
        const url = response.path.replaceAll('\\', '/');
        message.destroy();
        message.success(t('messages.upload_success'));
        setUploadState(state => ({ ...state, URL: url, error: null, loading: false, id: response._id }));
        callback?.({ ...response, path: url });
        return;
      }

      default:
        // TODO: add this statement when upgrading to TS 4.9+
        // status satisfies undefined;
        return;
    }
  };

  const deleteFile = async (url: string, headers?: HeadersInit) => {
    try {
      message.destroy();
      message.loading(t('messages.deleting'));
      const res = await fetch(url, {
        method: "DELETE",
        headers
      });

      if (!res.ok) {
        throw Error(t('messages.network_error'));
      }

      setUploadState(state => ({ ...state, error: null, URL: null, loading: false }));
      callback?.();
      base64Callback?.();
      message.destroy();
      message.info(t('messages.deleting_success'));
    } catch (error) {
      message.destroy();
      message.info(t('messages.deleting_failed'));
      setUploadState((state) => ({ ...state, error }));
      return false;
    }

    return true;
  };

  const classes = `invoice__logo image-upload ${uploadState.loading ? "loading" : ""} ${URL ? "uploaded" : ""}`;
  const hint = uploadState.error
    ? t('messages')
    : uploadState.loading
      ? t('invoice.logo.uploading')
      : t('invoice.logo.add');

  return {
    ...uploadState,
    hint,
    classes,
    deleteFile,
    changeHandler,
    beforeUploadHandler,
    imageElement,
    logoURL,
    isImageLoaded,
    t,
  };
};
