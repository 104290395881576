import { Form, FormInstance } from "antd";
import { ItemFormValues } from "../@types/ListItem";
import { useTranslation } from "react-i18next";

export const useItemsListRow = (name: number, form: FormInstance<ItemFormValues>, withDiscount?: boolean) => {
  const { i18n, t } = useTranslation();
  const quantityValue = Form.useWatch(["items", name, 'quantity'], form);
  const priceValue = Form.useWatch(["items", name, 'price'], form);
  const discountValue = Form.useWatch(["items", name, 'discount'], form);
  let total: number = +priceValue * +quantityValue;
  if (withDiscount)
    total *= (1 - +(discountValue ?? 0));
  return {
    total,
    quantityValue,
    priceValue,
    discountValue,
    i18n,
    t
  };
};
