import { Dropdown, MenuProps, Space } from "antd";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { ITranslation } from "../../../@types/translation";
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {

  const { i18n } = useTranslation();

  const items: MenuProps['items'] = Object.values(ITranslation.Language).map((language, i) => {
    const langaugeInfo = ITranslation.LanguageInfo[language as ITranslation.Language];
    return {
      key: `${i}`,
      label: <div onClick={() => { i18n.changeLanguage(langaugeInfo.shortcut); }}>{langaugeInfo.title}</div>,
      disabled: i18n.language === langaugeInfo.shortcut
    };
  });

  return <Dropdown
    getPopupContainer={triggerNode => { return triggerNode.parentElement ?? document.body; }}
    overlayStyle={{ minWidth: "250px" }}
    menu={{ items }}
    className="language-selector"
  >
    <Space>
      {(ITranslation.LanguageInfo[i18n.language as ITranslation.Language] ?? ITranslation.LanguageInfo['en']).title}
      <MdOutlineKeyboardArrowDown color="#2d313d" />
    </Space>
  </Dropdown>;
};

export default LanguageSelector;