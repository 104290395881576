import React from "react";
import { Actions, ActionTypes, InvoiceBilling } from "../../state/actions";
import { NSInvoice } from "../../@types/invoice";
import { IInvoiceState } from "../../state/state";
import useControlledForm from "../../Hooks/controlled-form.hook";
import { useTranslation } from "react-i18next";
import { useWatch } from "antd/lib/form/Form";

import { Form } from "antd";
import EditableInput from "../shared/inplace-controlled-field/editable-input.component";
import EditableTextArea from "../shared/inplace-controlled-field/editable-textarea.component";

interface IProps {
  state: { value: IInvoiceState, dispatch: React.Dispatch<Actions>; };
  states: NSInvoice.States;
}

const BillingInfoForm = (props: IProps) => {
  const { t } = useTranslation();
  const { form } = useControlledForm<InvoiceBilling>(
    props.state.value.InvoiceBilling ?? {}
  );
  const email1 = useWatch("email1", form);
  const email2 = useWatch("email2", form);

  const billFrom = useWatch("billFrom", form);
  const billTo = useWatch("billTo", form);

  return (
    <Form
      form={form}
      disabled={props.states.isLoading.value}
      name="invoice-billing-info"
      onValuesChange={(_: InvoiceBilling, values: InvoiceBilling) => {
        props.state.dispatch({
          type: ActionTypes.UPDATE_BILLING,
          payload: values
        });
      }
      }
    >
      <div className="invoice__billing-info">
        <div className="billing__left-col billing__col">
          <p>{t('invoice.billing_info.bill_from')}</p>
          <Form.Item
            name={"email1"}
            rules={[{ type: 'email', message: 'The input is not valid email' }, { required: true, message: '' }]}
          >
            <EditableInput placeholder={t('invoice.billing_info.email')} className="email-input" />
          </Form.Item>
          <>
            <Form.Item
              name={"billFrom"}
              rules={[{ required: true, message: '' }]}
            >
              <EditableTextArea
                placeholder={t('invoice.billing_info.invoice_from')}
                className="text-input"
                rows={4}
              />
            </Form.Item>
            <p className="print-helper">{billFrom}</p>
            {!email1 && !billFrom && <p className="print-helper no-data">{t('invoice.print.no_data_entered')}</p>}
          </>
        </div>
        <div className="billing__right-col billing__col">
          <p>{t('invoice.billing_info.bill_to')}</p>
          <Form.Item
            name={"email2"}
            rules={[{ type: 'email', message: 'The input is not valid email' }, { required: true, message: '' }]}
          >
            <EditableInput placeholder={t('invoice.billing_info.email')} className="email-input" />
          </Form.Item>
          <>
            <Form.Item
              name={"billTo"}
              rules={[{ required: true, message: '' }]}
            >
              <EditableTextArea
                placeholder={t('invoice.billing_info.invoice_to')}
                className="text-input"
                rows={4}
              />
            </Form.Item>
            <p className="print-helper">{billTo}</p>
            {!email2 && !billTo && <p className="print-helper no-data">{t('invoice.print.no_data_entered')}</p>}
          </>
        </div>
      </div>
    </Form>
  );
};

export default BillingInfoForm;
