/**
 * Returns the environment configurations for this app, it is a function and not a constant to prevent
 * accidental mutation, each property has a prefix designating the app it belongs to.
 */
export const settings = () => ({
  /**
   * Maximum number of projects allowed per user
  */
  PROJECT_MAX_COUNT: 100, // TODO: Implement in backend
  INVOICE_LOGO_SIZE: process.env.REACT_APP_LOGO_SIZE ? Number.parseInt(process.env.REACT_APP_LOGO_SIZE) : 1024 * 1024,
  SSO: process.env.REACT_APP_SSO_ORIGIN!,
  LOGIN_URL: `${process.env.REACT_APP_SSO_ORIGIN}/login?dest=${encodeURIComponent(process.env.REACT_APP_INVOICE_ORIGIN!)}`,
  REGISTER_URL: `${process.env.REACT_APP_SSO_ORIGIN}/register?dest=${encodeURIComponent(process.env.REACT_APP_INVOICE_ORIGIN!)}`,
  INVOICE_API: process.env.REACT_APP_INVOICE_API!,
  INVOICE_ORIGIN: process.env.REACT_APP_INVOICE_ORIGIN!,
  REACT_APP_SADA_DOMAIN: process.env.REACT_APP_SADA_DOMAIN!,
  /**
   * The prefix for uploaded files, not necessarily the same as the prefix for the API
   */
  PUBLIC_DIR: process.env.REACT_APP_PUBLIC_DIR!,
} as const);